import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  FormControl, FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
  setupUntilDestroy
} from "@portal-workspace/grow-ui-library";
import { MARK } from "@portal-workspace/grow-ui-library/mark";
import { Subscription } from "rxjs";
import { delay, distinctUntilChanged, tap } from "rxjs/operators";
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { AbstractControlValueAccessor } from "../abstract-control-value-accessor";
import { compareMatch } from "@portal-workspace/grow-shared-library";

export type SearchableSelectItem = {
  id: string;
  value: string;
  label: string;
  searchTerm?: string;
  priority?: boolean;
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatSelectModule,
    MatOptionModule,
  ],
  providers:[
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>SearchableSelectComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(()=>SearchableSelectComponent)},
  ],
})
export class SearchableSelectComponent extends AbstractControlValueAccessor<SearchableSelectItem[]> implements OnInit {

  subscriptions: Subscription[] = [];
  
  @Input({required: true}) formControl!: FormControl;
  @Input({required: true}) items!: SearchableSelectItem[];
  @Input({required: false}) multi: boolean = false;
  @Input({required: false}) placeholder: string = 'Select...';
  
  searchControl = new FormControl<string>('');
  filteredItems: SearchableSelectItem[] = [];

  ngOnInit(): void {
    this.filteredItems = [...(this.items ?? [])];
    this.filteredItems = this.sortItems(this.filteredItems);
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
        this.filteredItems = this.sortItems(this.filteredItems);
      })
    ).subscribe());
    this.subscriptions.push(this.searchControl.valueChanges.pipe(
      tap(v => {
        this.filteredItems = this.sortItems(this.filterItems(v));
      })
    ).subscribe());
  }

  sortItems(items: SearchableSelectItem[]): SearchableSelectItem[] {
    return items.sort((a, b) => {
      if (this.formControl.value.includes(a.id)) return -1;
      if (this.formControl.value.includes(b.id)) return 1;
      if (a.priority) return -1;
      if (b.priority) return 1;
      return a.label.localeCompare(b.label);
    });
  }

  onSearch(event: Event): void {
    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    this.items = this.filterItems(searchText);
  }

  private filterItems(searchText: string | null): SearchableSelectItem[] {
    if (!searchText) {
      return this.items;
    }
    return this.items.filter(item => 
      item.searchTerm?.toLowerCase()?.includes(searchText) || item.label.toLowerCase().includes(searchText)
    );
  }

  doWriteValue(v: SearchableSelectItem[]) {
    const ids = this.formControl.value.map((item: SearchableSelectItem) => item.id);
    for (const item of v) {
      if (!ids.includes(item.id)) {
        this.formControl.setValue(v);
      }
    }
  }
}
