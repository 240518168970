<div class="searchable-select-component">
  <mat-form-field class="select" subscriptSizing="dynamic">
    <mat-select [formControl]="formControl" multiple="{{multi}}" [placeholder]="placeholder">
      <mat-form-field subscriptSizing="dynamic" class="search" fxFlex="1 1" fxFlex.lt-md="1 1 50px" fxFlexAlign="start" style="position: sticky; top: 0; z-index: 1; padding-left: 8px; padding-top: 8px; background: var(--mat-select-panel-background-color);">
        <input [formControl]="searchControl" matInput #searchText class="ml-1" type="text" placeholder="Search">
        <span class="mdi mdi-magnify" matPrefix></span>
      </mat-form-field>
      @for (item of filteredItems; track item.id) {
        <mat-option
          [value]="item.value">
          {{item.label}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>