import {Component, EventEmitter, Input, OnInit, Output, inject} from '@angular/core';
import {
  ApplicationTypes,
  BusinessOverdraftApplication,
  CopyApplicationDialogResult,
  CopyApplicationFn,
  getAppInfoBrokerEntityName,
  GetGeoLocationFn,
  PpsrData,
  getOrganisationTypeForTitle,
  SaveApplicationResult,
  TermRateForBusinessOverdraft,
  User,
  getMargin,
  GetRateCardDetailsFn,
  UpdateApplicationFn,
  getBrokerContact,
  WithdrawApplicationFn,
  getOpportunityOwnerEmail,
  GetUserFn,
} from '@portal-workspace/grow-shared-library';
import {
  GetUsersFunc,
  UserSelectionComponentEvent,
  getUser,
  ApplicationDialogService,
  PortalHotToastService,
  AssetSettlementSearchGrantorsFn,
  AssetSettlementGetPpsrDetailsFn,
} from '@portal-workspace/grow-ui-library';
import {FacilityEstablishmentComponent} from '../../facility-establishment-component/facility-establishment.component';
import {MessageBoxComponent} from '../../message-box/message-box.component';
import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes,
  getAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetMake,
  getAssetType,
  getAssetYear,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName,
  getBrokerSalesforceId,
  getAppSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getBrokerFlowId,
  getInvoiceAmount,
  getLoanAmount, getLoanPurpose,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType, getPrimaryBusinessAddress,
  getApplicationPrimaryIndustry,
  getPrivateSales,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  getFacilityFee,
  getFacilityFeePercent,
  getDirectorScoreRate,
  getPreviousLoan,
  getPreviousBusiness,
  getPreviousBusinessName,
  getPreviousBusinessABN,
  getPreviousBusinessACN,
} from '@portal-workspace/grow-shared-library';
import { isInternalUser as utilIsInternalUser} from '@portal-workspace/grow-shared-library';
import {getApplicationStatus,} from '@portal-workspace/grow-shared-library';
import {Subscription, tap} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import { LooseCurrencyPipe } from '../../../pipes/loose-currency.pipe';
import { ReferencesDetailsComponent } from '../references-details.component';
import { PrimaryContactDetailsComponent } from '../primary-contact-details.component';
import { BusinessOverdraftQuoteComponent } from './business-overdraft-quote.component';
import { IndividualDetailsComponent } from '../individual-details.component';
import { RiskAnalysisComponent } from '../../risk-analysis-component/risk-analysis.component';
import { MatDividerModule } from '@angular/material/divider';
import { UserSelectionComponent } from '../../user-selection-component/user-selection.component';
import { CellComponent } from '../../application-summary-component/cell.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { StatusBoxComponent } from '../../message-box/status-box.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgTemplateOutlet, DecimalPipe, AsyncPipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { DisableControlDirective } from '../../../directives/disable-control.directive';
import { InputMaskModule } from '@ngneat/input-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { YesNoComponent } from '../../yes-no-component/yes-no.component';
import { MatInputModule } from '@angular/material/input';
import {loadingFor} from "@ngneat/loadoff";
import { ActivatedRoute } from '@angular/router';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-overdraft-app',
    templateUrl: './business-overdraft-app.component.html',
    styleUrls: ['./business-overdraft-app.component.scss'],
    standalone: true,
  imports: [MatCardModule, MatTabsModule, NgTemplateOutlet, FlexModule, StatusBoxComponent, MatButtonModule, MatFormFieldModule, CellComponent, UserSelectionComponent, MatDividerModule, RiskAnalysisComponent, IndividualDetailsComponent, BusinessOverdraftQuoteComponent, PrimaryContactDetailsComponent, ReferencesDetailsComponent, LooseCurrencyPipe, YesNoComponent, DisableControlDirective, InputMaskModule, DecimalPipe, ReactiveFormsModule, MatInputModule, AsyncPipe, MessageBoxComponent, FacilityEstablishmentComponent]
})
export class BusinessOverdraftAppComponent implements OnInit {

  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getAppSalesforceId = getAppSalesforceId;
  getBrokerName = getBrokerName;
  getAppInfoBrokerEntityName = getAppInfoBrokerEntityName;
  getOrganisationType = getOrganisationType;
  getOrganisationTypeForTitle = getOrganisationTypeForTitle;
  getInterestRate = getInterestRate;
  getBrokerFlowId = getBrokerFlowId;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getAssetCategory = getAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getPrivateSales = getPrivateSales;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getApplicationPrimaryIndustry = getApplicationPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  getLoanPurpose = getLoanPurpose;
  getFacilityFee = getFacilityFee;
  getFacilityFeePercent = getFacilityFeePercent;
  getDirectorScoreRate = getDirectorScoreRate;
  getPreviousLoan = getPreviousLoan;
  getPreviousBusiness = getPreviousBusiness;
  getPreviousBusinessName = getPreviousBusinessName;
  getPreviousBusinessABN = getPreviousBusinessABN;
  getPreviousBusinessACN = getPreviousBusinessACN;
  getMargin = getMargin;
  getBrokerContact = getBrokerContact;
  getOpportunityOwnerEmail= getOpportunityOwnerEmail;

  @Input({required: true}) application!: BusinessOverdraftApplication;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: true}) data!:TermRateForBusinessOverdraft;
  @Input({required: true}) copyApplicationFn!: CopyApplicationFn;
  @Input({required: true}) getGeoLocationFn!: GetGeoLocationFn;
  @Input({required: true}) ip: string = '';
  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();
  @Output() navigateToApplications = new EventEmitter();
  @Input({required: true}) searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input({required: true}) getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  @Input({required: true}) getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) updateApplicationFn!: UpdateApplicationFn;
  @Input({required: true}) withdrawApplicationFn!: WithdrawApplicationFn;
  @Input({required: true}) getUserFn!: GetUserFn;


  loader = loadingFor('saveRate');

  applicationStatus: string | null = null;
  subscriptions: Subscription[] = [];
  loggedInUser: User | null = getUser();
  creditOfficer = '';
  ppsrData:any;
  applicationUserId!:number;
  AdditionalBrokerCorrespondentUserId!:number;
  grantorDetails: any = { Account: 0, MotorVehicleCount: 0, OtherGoodsCount: 0, AllPapNoExceptCount: 0, Total: 0 }
  constructor(
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private activatedRoute: ActivatedRoute,
    // private applicationService: ApplicationService,
    ) {
  }

  async ngOnInit() {
    this.applicationStatus = getApplicationStatus(this.application);
    this.reload();
    if (this.application.CreditOfficerStatusUpdatedBy) {
      this.subscriptions.push(
        this.getUserFn(this.application.CreditOfficerStatusUpdatedBy, true).subscribe(r => {
          if (r) {
            this.creditOfficer = `${r.GivenName} ${r.FamilyName}`;
          }
        })
      )
    } else if (this.application.SystemCreditStatus) {
      this.creditOfficer = `System`;
    }

  }

  isInternalUser() {
    return utilIsInternalUser(getUser())
  }

  emitChangeSubmitterEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'submitter',
    });
  }

  emitChangeAdditionalCorrespondentEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'additional-correspondent',
    });
  }

  onClickApplication() {
    this.subscriptions.push(
      this.applicationDialogService.openCopyApplicationDialog({
        application: this.application
      }).afterClosed().subscribe(async (r: CopyApplicationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          const applicationType = r.applicationType as ApplicationTypes;
          let geoLocation = {};
          try {
            geoLocation = await this.getGeoLocationFn();
          } catch (error) {
            console.log(error)
          }
          this.copyApplicationFn({
            newApplicationType: applicationType,
            application: this.application,
            user: this.loggedInUser as User,
            ip: this.ip,
            geoLocation: geoLocation
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((r: SaveApplicationResult) => {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: `Application ${r.BrokerApplicationId} has been created for you.`
            }).afterClosed().subscribe(() => {
              this.navigateToApplications.emit();
            })
          })
        }
      })
    )
  }

  reload(){
    this.getPpsrDetailsFn(this.application.ApplicationId).pipe(
      tap(r => {
        const r1: PpsrData | null | any = r ?? null;
        //ppsr details
        this.ppsrData=r1;
        if (r1 && r1.companySummary) {
          const companySummary = r1?.companySummary
          this.grantorDetails.Account = companySummary?.Account;
          this.grantorDetails.MotorVehicleCount = companySummary?.MotorVehicleCount
          this.grantorDetails.OtherGoodsCount = companySummary?.OtherGoodsCount
          this.grantorDetails.AllPapNoExceptCount = companySummary?.AllPapNoExceptCount
          this.grantorDetails.Total = this.grantorDetails.Account + this.grantorDetails.MotorVehicleCount + this.grantorDetails.OtherGoodsCount + this.grantorDetails.AllPapNoExceptCount
        }
      })
    ).subscribe();
    this.applicationUserId= (this.activatedRoute.snapshot.data as any).application.UserId;
    this.AdditionalBrokerCorrespondentUserId = (this.activatedRoute.snapshot.data as any).application.AdditionalBrokerCorrespondent.UserId;
  }

  getSearchGrantors(element: 'ABN' | 'ACN') {
    let data: any = {}
    let organisationName: string = '';
    if (element === 'ACN') {
      data = {
        "SearchNumber": this.application.CommercialEntities[0].ACN,
        "NumberType": element,
        "SearchType": "G"
      }
      organisationName = this.application.CommercialEntities[0].LegalName
    } if (element === 'ABN') {
      data = {
        "SearchNumber": this.application.CommercialEntities[0].ABN,
        "NumberType": element,
        "SearchType": "G"
      }
      organisationName = this.application.CommercialEntities[0].LegalName
    }
    this.searchGrantorsFn(this.application.ApplicationId, {
      // this.searchGrantorsFn(this.application.ApplicationId, {
      SearchNumber: data.SearchNumber,
      NumberType: element,
      SearchType: "G"
    }).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable("Grantor search success"),
      tap(r => {
        const data: any = r
        if (data.Error) {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: 'Please try after sometime',
          });
        } else {
          if (r.payload && r.status) {
            const grantorDetails = r.payload['SearchResult'].GrantorOrganisationSearchCriteriaVerificationDetail;
            if (grantorDetails === 'NotFound') {
              this.applicationDialogService.openAlertDialog({ message: 'Alert', subMessage: 'Grantor details not found' })
            } else {
              const collateralClassSummary = r.payload.SearchResult.SearchResultSummary.CollateralClassSummary.CollateralClassSearchSummaryDetail
              if (collateralClassSummary.length > 0) {
                collateralClassSummary.forEach((element: { CollateralClass: string; CollateralClassResultCount: any; }) => {
                  if (element.CollateralClass === 'OtherGoods') {
                    this.grantorDetails.OtherGoodsCount = element.CollateralClassResultCount
                  }
                  if (element.CollateralClass === 'Account') {
                    this.grantorDetails.Account = element.CollateralClassResultCount
                  }
                  if (element.CollateralClass === 'MotorVehicle') {
                    this.grantorDetails.MotorVehicleCount = element.CollateralClassResultCount
                  }
                  if (element.CollateralClass === 'AllPapNoExcept') {
                    this.grantorDetails.AllPapNoExceptCount = element.CollateralClassResultCount
                  }
                });
              } else {
                this.applicationDialogService.openInformationDialog({ message: 'Info', subMessage: `${organisationName} has no PPSR registrations` })
                this.grantorDetails = { Account: 0, MotorVehicleCount: 0, OtherGoodsCount: 0, AllPapNoExceptCount: 0, Total: 0 }
              }
              this.grantorDetails.Total = this.grantorDetails.Account + this.grantorDetails.MotorVehicleCount + this.grantorDetails.OtherGoodsCount + this.grantorDetails.AllPapNoExceptCount
            }
            this.reload();
          } else {
            this.applicationDialogService.openAlertDialog({ message: 'Alert', subMessage: 'Please check details and try again.' })
          }
        }
      })
    ).subscribe();
  }

  onWithdrawApplication() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Withdraw Application",
        subMessage: "Are you sure you want to withdraw this application?"
      }).afterClosed().subscribe(r => {
        if (r && r.readyForSubmission) {
          this.applicationDialogService.openWithdrawApplicationDialog({})
            .afterClosed().subscribe(result => {
              if (result && result.readyForSubmission) {
                this.withdrawApplicationFn({
                  salesforceId: this.application.AppInfoSalesforceID ?? '',
                  applicationId: this.application.ApplicationId,
                  reason: result.reason,
                  comment: result.comment,
                }).pipe(
                  this.toastService.spinnerObservable()
                ).subscribe(() => {
                  this.applicationDialogService.successDialog({
                    message: 'Success',
                    subMessage: `This application has been withdrawn successfully.`
                  }).afterClosed().subscribe(() => {
                    this.navigateToApplications.emit();
                  })
                })
              }
            })
        }
      })
    )
  }

}

