<!--error-notification *ngIf="showError" [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification-->

@if (!((store.value$ | async)?.error)) {
  <div class="applications-page container-fluid">
    @if (this.promo?.showMessage==true) {
      <div class="promo">
        <message-box type="promo-style2" [url]="this.promo?.link||''" (close)="closePromo($event)">{{this.promo?.message}}</message-box>
      </div>
    }
    <div class="promo">
      <message-box type="info" (close)="closePromo($event)" [showRefresh]="true" (onRefresh)="refresh(true)">{{lastUpdatedMessage}}</message-box>
    </div>
    <div class="header-bar">
      <div class="row" style="margin: 0;" fxLayoutAlign="space-between center">
        <div class="col-md-4 mb-2">
          <mat-card class="status-card background-dark" (click)="onApplicationStageFilter(STAGE_NAMES_UNDER_REVIEW)">
            <mat-card-content>
              <div class="status-label">
                Under Review
              </div>
              <div class="status-value mt-2">
                {{totalApplicationsUnderReview}}
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-4 mb-2">
          <mat-card class="status-card background-blue" (click)="onApplicationStageFilter(STAGE_NAMES_IN_SETTLEMENT)">
            <mat-card-content>
              <div class="status-label">
                In Settlement
              </div>
              <div class="status-value mt-2">
                {{totalApplicationsInSettlement}}
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-4 mb-2">
          <mat-card class="status-card background-teal" (click)="onApplicationStageFilter(STAGE_NAMES_CLOSED_WON)">
            <mat-card-content>
              <div class="status-label">
                Closed Won
              </div>
              <div class="status-value mt-2">
                {{totalApplicationsClosedWon}}
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div class="row filters-row">
        <mat-form-field subscriptSizing="dynamic" class="search">
          <input [formControl]="searchControl" matInput #searchText class="ml-1" type="text" placeholder="Search by ID or name">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
        <div class="filter-buttons">
          <data-box title="Filters" type="green" icon="filter-outline" data="{{selectedFiltersCount}}" (click)="toggleFilters()"/>
          <data-box type="green" icon="close" title="Clear" (click)="clearFilters()"/>
        </div>
      </div>

      @if (showFilters) {
        @if(isInternalUser(loggedInUser)){
          <div class="row filters-row">
            <searchable-select 
              [formControl]="filterOpportunityOwnerControl"
              [items]="opportunityOwners"
              [multi]="true"
              [placeholder]="'Sales contact'"
            />
            <searchable-select 
              [formControl]="filterCreditOfficerControl"
              [items]="creditOfficers"
              [multi]="true"
              [placeholder]="'Credit officer'"
            />
            <searchable-select 
              [formControl]="filterSettlementOfficerControl"
              [items]="settlementOfficers"
              [multi]="true"
              [placeholder]="'Settlement officer'"
            />
          </div>
        }

        <div class="row filters-row">
          <searchable-select 
            [formControl]="applicationTypeControl"
            [items]="applicationTypes"
            [multi]="true"
            [placeholder]="'Application type'"
          />
          <searchable-select 
            [formControl]="applicationStageControl"
            [items]="applicationStages"
            [multi]="true"
            [placeholder]="'Application stage'"
          />
        </div>
      }
    </div>
    <div class="row separator-top">
      <div class="col body">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <mat-progress-bar class="custom-progress-bar" [ngClass]="{hidden: !isProgressBarVisible}"  mode="indeterminate"></mat-progress-bar>
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSort($event)">
            <ng-container matColumnDef="appId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>App ID</th>
              <td class="col-app-id" mat-cell *matCellDef="let application">
                <application-type-icon [applicationType]="application.ApplicationType"></application-type-icon> {{application.BrokerAppId}}<br/>
                <span class="date-text">{{getApplicationCreateTime(application)}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="appName">
              <th class="col-appName" mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>App Name</span>
              </th>
              <td mat-cell *matCellDef="let application">
                @if (application.Source == 'external') {
                  <span class="mr-1 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
                }
                @if (application.Source == 'direct') {
                  <span class="mr-1 mdi mdi-alpha-d-circle" matTooltip="Direct source application"></span>
                }
                {{getApplicantName(application)}}<br/>
                @if (showBrokerName()) {
                  <span class="date-text">{{application.BrokerName}}</span>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let application">
                <tag-box [state]="getApplicationStatus(application) ?? ''"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="stage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Stage</th>
              <td mat-cell *matCellDef="let application">
                <tag-box [state]="getApplicationStage(application)"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="column-width-0"></th>
              <td mat-cell *matCellDef="let application" class="cell">
                <!-- @if ((application.Status == 'Draft') || (application.Status == 'Draft' && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer') || (application.Status !== 'Draft' && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer')) -->
                
                <span class="mdi mdi-dots-vertical cursor-pointer" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu"></span>
                
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="onApplicationClick($event, application, true)" class="menu-items">
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 150px;">
                      <div style="flex-grow: 1; text-align: left;">Open in New Tab</div>
                        <span class="mdi mdi-open-in-new"></span>
                    </div>
                  </button>
                  @if (application.Status !== 'Draft' && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer'){
                    <button mat-menu-item (click)="onCopyApplication(application.ApplicationId)" class="menu-items">
                      <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 150px;">
                        <div style="flex-grow: 1; text-align: left;">Copy</div>
                          <span class="mdi mdi-content-copy"></span>
                      </div>
                    </button>
                  }
                  @if (application.Status == 'Draft') {
                    <button mat-menu-item (click)="onDelete(application.ApplicationId)"  class="menu-items">
                      <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 150px;">
                        <div style="flex-grow: 1; text-align: left;">Delete</div>
                        <div><span class="mdi mdi-trash-can-outline"></span></div>
                      </div>
                    </button>
                  }
                </mat-menu>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr class="cursor-pointer" (click)="onApplicationClick($event, application, false)" mat-row *matRowDef="let application; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total" [pageIndex]="offset" [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
          }
          <div class="footnote mat-caption mt-2"
            fxLayoutAlign.lt-sm="center center">
            Only applications in the last 12 months are listed
          </div>
          <div class="footnote mdi mdi-magnify mat-caption cursor-pointer mt-2"
            fxLayoutAlign.lt-sm="center center" (click)="onClickAllApplication($event)">
            <u>Search for older applications</u>
          </div>
        </div>
      </div>
    </div>
  </div>
}
