import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {AngularMaterialLibraryModule} from "../../angular-material/angular-material-library.module";
import {DirectiveLibraryModule} from "../../directives/directive-library.module";
import {SearchableSelectComponent} from "./searchable-select.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularMaterialLibraryModule,
    DirectiveLibraryModule,
    SearchableSelectComponent,
  ],
  exports: [
    SearchableSelectComponent,
  ]
})
export class SearchableSelectModule {

}
