import {Injectable, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {getUser, httpOptions } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {map, tap} from 'rxjs/operators';
import {
  ApiResponse,
  NotificationResponse, OriginatorBusiness, PayloadApiResponse, User, UserOmitPriviledges
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';


const URL_GET_ALL_NOTIFICATIONS  = (userId: number, originatorBusinessId: number) =>  `${environment.api2Host}/api2/notifications/all/${userId}/${originatorBusinessId}`;
const URL_MARK_NOTIFICATION_READ = () => `${environment.api2Host}/api2/notifications/markread`;
const URL_DELETE_NOTIFICATION = () => `${environment.api2Host}/api2/notifications/deleteNotification`;
const URL_MARK_ALL_NOTIFICATIONS_READ = (userId: number, originatorBusinessId: number) => `${environment.api2Host}/api2/notifications/markallread/${userId}/${originatorBusinessId}`; //get
const URL_DELETE_ALL_NOTIFICATIONS = (userId: number, originatorBusinessId: number) => `${environment.api2Host}/api2/notifications/deleteAllNotifications/${userId}/${originatorBusinessId}`; // get
const URL_GET_ORIGINATOR_RECIPIENT = () => `${environment.api2Host}/api2/originators`; // get
const URL_GET_USER_RECIPIENT = () => `${environment.api2Host}/api2/users/all`; //get
const URL_SUBMIT_NOTIFICATION = () => `${environment.api2Host}/api2/notification/submit`; // post



@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class NotificationService implements OnInit {

  subscriptions: Subscription[] = [];

  unreadNotificationsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  submitUserNotification(recipientUserId: number, title: string, message: string): Observable<PayloadApiResponse<number>> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_SUBMIT_NOTIFICATION(), {
      type: 'USER', message_title: title, description: message, user_id: recipientUserId,
    }, httpOptions()).pipe();
    // return this.httpClient.post<any>(URL_SUBMIT_NOTIFICATION(), {
    //   type: 'USER', message_title: title, description: message, user_id: recipientUserId,
    // }, httpOptions()).pipe(map(r => ({
    //   status: true, message: ''
    // })));
  }

  submitCompanyNotification(recipientOriginatorBusinessId: number, title: string, message: string): Observable<PayloadApiResponse<number>> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_SUBMIT_NOTIFICATION(), {
      type: 'COMPANY', message_title: title, description: message, company_id: recipientOriginatorBusinessId,
    }, httpOptions()).pipe();
    // return this.httpClient.post<any>(URL_SUBMIT_NOTIFICATION(), {
    //   type: 'COMPANY', message_title: title, description: message, company_id: recipientOriginatorBusinessId,
    // }, httpOptions()).pipe(map(r =>({
    //   status: true, message: '',
    // })));
  }

  submitBroadcastNotification(title: string, message: string): Observable<PayloadApiResponse<number>> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_SUBMIT_NOTIFICATION(), {
      type: 'BROADCAST', message_title: title, description: message,
    }, httpOptions());
    // return this.httpClient.post<any>(URL_SUBMIT_NOTIFICATION(), {
    //   type: 'BROADCAST', message_title: title, description: message,
    // }, httpOptions()).pipe(map(r =>({
    //   status: true, message: '',
    // })));
  }


  updateUnreadNotificationsCount(): Subscription | null {
    const user = getUser();
    if (user && user.OriginatorBusinessId) {
      const sub = this.getAllNotifications(user.UserId, user.OriginatorBusinessId).pipe(
        tap(r => {
          const unreadNotificationsCount = r.payload.totalUnread;
          this.unreadNotificationsCount.next(unreadNotificationsCount);
        })
      ).subscribe();
      this.subscriptions.push(sub);
      return sub;
    }
    return null;
  }

  getNotificationUserRecipients(): Observable<PayloadApiResponse<UserOmitPriviledges[]>> {
    return this.httpClient.get<PayloadApiResponse<UserOmitPriviledges[]>>(URL_GET_USER_RECIPIENT(), httpOptions()).pipe();
    // return this.httpClient.get<{status: boolean, message: string, payload: User[]}>(URL_GET_USER_RECIPIENT(), httpOptions()).pipe(
    //   map(r => {
    //     console.log('&&&&&&&&&&&&&&&&& USERS ', r);
    //     return {
    //       status: true, message: '',
    //       payload: r.payload
    //     }
    //   })
    // );
  }

  getNotificationOriginatorRecipients(): Observable<PayloadApiResponse<OriginatorBusiness[]>> {
    return this.httpClient.get<PayloadApiResponse<OriginatorBusiness[]>>(URL_GET_ORIGINATOR_RECIPIENT(), httpOptions()).pipe();
    // return this.httpClient.get<{status: boolean, message: string, payload: OriginatorBusiness[]}>(URL_GET_ORIGINATOR_RECIPIENT(), httpOptions()).pipe(
    //   map(r => {
    //     console.log('&&&&&&&&&&&&&&&&& ', r);
    //     return {
    //       status: true, message: '',
    //       payload: r.payload
    //     }
    //   })
    // )
  }


  deleteAllNotifications(userId: number, originatorBusinessId: number): Observable<ApiResponse> {
    return this.httpClient.get<{status: boolean}>(URL_DELETE_ALL_NOTIFICATIONS(userId, originatorBusinessId), httpOptions()).pipe(
      map(r => {
        return {
          status: r.status, message: '',
        }
      })
    );
  }

  markAllNotificationsRead(userId: number, originatorBusinessId: number): Observable<ApiResponse> {
    return this.httpClient.get<{status: boolean}>(URL_MARK_ALL_NOTIFICATIONS_READ(userId, originatorBusinessId), httpOptions()).pipe(
      map(r => {
        return {
          status: r.status, message: '',
        }
      })
    );
  }


  deleteNotification(notificationId: number, userId: number): Observable<ApiResponse> {
    return this.httpClient.post<any>(URL_DELETE_NOTIFICATION(), {
      notification_id: notificationId,
      user_id: userId,
    }, httpOptions()).pipe(
      map(r => {
        return {
          status: true,
          message: ''
        }
      })
    );
  }


  markNotificationRead(userId: number, notificationId: number): Observable<ApiResponse> {
    return this.httpClient.post<any>(URL_MARK_NOTIFICATION_READ(), {
      user_id: userId,
      notification_id: notificationId
    }, httpOptions()).pipe(
      map(r => {
        return {
          status: true, message: '',
        }
      })
    )
  }


  getAllNotifications(userId: number, originatorBusinessId: number): Observable<PayloadApiResponse<NotificationResponse>> {
    return this.httpClient.get<PayloadApiResponse<NotificationResponse>>(URL_GET_ALL_NOTIFICATIONS(userId, originatorBusinessId), httpOptions()).pipe(
      tap(r => {
        this.unreadNotificationsCount.next(r.payload.totalUnread);
      })
    );
  }

}
