import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  BusinessNumberSearchFn,
  AnnualRevenueValue,
  applicationToAdditionalBrokerCorrespondent,
  applicationToHasAdditionalBrokerCorrespondent,
  BusinessSearchFn,
  toInteflowAddress,
  trusteeValidator,
  partnerValidator,
  applicationToPreviousBusiness,
  UserSameCompanySelectionComponent,
  SalesforceContactSameAccountSelectionComponent,
  applicationToApplicantType,
  formControlErrorKeys,
  formControlErrorMessage, applicationToPrivacyConfirmation,
  applicationToBrokerContact,
  duplicateFileNameValidator,
  maxFileUploadValidator,
  applicationToLoanTermsValue,

} from '@portal-workspace/grow-ui-library';
import {
  DocumentTag,
  AssetFinanceApplication,
  AbnComponentValue,
  AcnComponentValue,
  BalloonPaymentValueOptions,
  BusinessSearchValue,
  CurrencyInputValue,
  isApplicationApplicantIndividual,
  isApplicationApplicantsSoleTrader,
  MobileValue,
  NotNullable,
  SelectContactValue,
  UserSelectionValue,
  YesNoValue,
  UploadAzureFilesValue,
  UploadAzureFiles,
  AzureStorageDocument,
  isAssetTrucksOrTrailers,
  isAssetTypeTruck,
  isAssetTypeLightOrHeavyTruck,
  TransactionValue,
  PrivateSaleType,
  GetUserSameCompanyFunc,
  UserSameCompanySelectionValue,
  CommercialEntity,
  PaymentFrequencyValueOptions,
  yesNoToBoolean,
  AssetPurposeComponentValue,
  getAbn,
  GetSalesforceContactSameAccountFunc,
  SalesforceContactSameAccountSelectionValue,
  applicantsPrivacyConsent,
  individualApplicants,
  isExternalUser,
  maxLoanForAssetFinance,
  LoanTermsSelectionWithInputValue,
  skipApplicationPrivacyConsentDialog,
  AssetFinanceProductType,
  toDraftSimplifiedApplication,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {AggregatorSearchFn} from '@portal-workspace/grow-ui-library';
import {AggregatorSearchComponentValue, } from '@portal-workspace/grow-shared-library';
import { AssetSelectionComponentSearchFn, } from '@portal-workspace/grow-ui-library';
import { AssetSelectionComponentValue, } from '@portal-workspace/grow-shared-library';
import {RedbookService} from '../../service/redbook.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {filter, map, tap} from 'rxjs/operators';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import {
  applicantsThatAreGuarantor,
  applicationToAbn,
  applicationToAcn,
  applicationToAdverseOnFile,
  applicationToApplicants,
  applicationToApplicationNotes,
  applicationToAsset,
  applicationToAssetCondition,
  applicationToBalloonPaymentPrecentage,
  applicationToBrokerage,
  applicationToBrokerFlowDocumentId,
  applicationToBrokerOriginationFee,
  applicationToBrokerSearchValue,
  applicationToBusinessLandline,
  applicationToBusinessSearchValue,
  applicationToDepositAmount,
  applicationToDocFeeFinanced,
  applicationToEquifaxScoreAboveThreshold,
  applicationToFinanceType,
  applicationToGuarantors,
  applicationToIndustrySector,
  applicationToInvoiceAmount,
  applicationToLegalName,
  applicationToLoanAmount,
  applicationToOperatesInCommercialPremise,
  applicationToOrganisationType,
  applicationToPaymentFrequency,
  applicationToPrimaryAddress,
  applicationToPrimaryContact,
  applicationToPrimaryIndustry,
  applicationToPropertyOwner,
  applicationToReferences,
  applicationToRevenue,
  fromApplicantToInteflowCommercialEntities,
  fromApplicantToInteflowIndividualsData,
  fromContactToInteflowContacts,
  fromGuarantorToInteflowCommercialEnttities,
  fromGuarantorToInteflowIndividuals,
  fromReferenceToInteflowReferences,
  getUser,
  individualGarantors,
  toInteflowEntityTypes,
  toInteflowLegalName,
  applicantsToPrimaryContact,
  applicationToTransactionValue,
  applicationToPreviousLoan,
  setStepper2StepConfig,
} from '@portal-workspace/grow-ui-library';
import {
  booleanToYesNo,
  filesToBase64Files,
  calculateAssetFinanceEstimation,
  patchAcn,
  minDepositForAssetFinance,
  isAddressEquals,
  filterOutUniquePrimaryContacts,
  SaveApplicationPricingDetailsData,
} from '@portal-workspace/grow-shared-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ReferenceValue} from '@portal-workspace/grow-shared-library';
import {EntityTypeValue, EntityTypes, EntityTypeValueOptions as EntityTypeOptions, EntityTypeCompanyOption, EntityTypeTrustOption,
  EntityTypePartnershipOption, EntityTypeSoleTraderOption, EntityTypeOtherOption}
  from '@portal-workspace/grow-shared-library';
import _, { lastIndexOf } from 'lodash';
import {
  TrusteeValue,
  DirectorValue,
  SoleTraderValue,
  PartnerValue,
  MemberValue,
  ContactValue,
  FinanceTypeValue,
  AssetConditionValue,
} from '@portal-workspace/grow-shared-library';
import {
  GuarantorComponentEvent,
} from '@portal-workspace/grow-ui-library';
import {
  GuarantorValue,
  IndividualGuarantor
} from '@portal-workspace/grow-shared-library';
import {
  CustomerApplicantTypeValue,
} from '@portal-workspace/grow-shared-library';
// import {setCurrentStepFn} from '@portal-workspace/grow-ui-library';
import {guarantorValidator, requiredAllowEmptyValidator} from '@portal-workspace/grow-ui-library';
import {Moment} from 'moment';
import moment from 'moment';
import { ASSET_FINANCE_OPTIONS_FILTER, } from '@portal-workspace/grow-ui-library';
import {LoanTermValue, LoanTermValueOptions as LoanTermsOptions} from '@portal-workspace/grow-shared-library';
import {BrokerageSelectionValue, BrokerageSelectionValueOptions as BrokerageOptions} from '@portal-workspace/grow-shared-library';
import { PaymentFrequencyValue } from '@portal-workspace/grow-shared-library';
import {AssetFinanceSummary} from '@portal-workspace/grow-shared-library';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { ApplicationService, } from '../../service/application.service';
import {
  Application,
  BrokerOfUser,
  GeoLocation,
  RatecardDetails,
  UpdateApplicationData,
  BalloonPaymentValue,
  TotalPaymentBreakupDialogData,
  Address2ComponentValue,
  BusinessNumberSearchValue
} from '@portal-workspace/grow-shared-library';
import {AppCalculator, TermRate} from '@portal-workspace/grow-shared-library';
import { BalloonPaymentComponent, } from '@portal-workspace/grow-ui-library';
import numeral from 'numeral';
import {ApplicationApplicant, Base64File, OriginatorBusiness, User} from '@portal-workspace/grow-shared-library';
import {constants, getSecondaryIndustry, isAssetNewCars, isAssetVehicles} from '@portal-workspace/grow-shared-library';
import {PrimaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {SecondaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {UploadFileValue} from '@portal-workspace/grow-shared-library';
import {UploadedFilesListComponentEvent} from '@portal-workspace/grow-ui-library';
import {UploadedFiles, defaultDocuments} from '@portal-workspace/grow-shared-library';
import {
  navigationUrlForApplicationsWithQueryParams, navigationUrlForAssetProductSelectorWithQueryParams,
} from '../../service/navigation-urls';
import {environment} from '../../../environments/environment';
import { AdminService } from '../../service/admin.service';
import { AuthService } from '../../service/auth.service';
import { AssetFinanceSummaryComponent } from '@portal-workspace/grow-ui-library';
import { UploadFilesMultiTagsComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { UploadedFilesListComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { ReferenceComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectContactComponent } from '@portal-workspace/grow-ui-library';
import { GuarantorComponent } from '@portal-workspace/grow-ui-library';
import { MemberComponent } from '@portal-workspace/grow-ui-library';
import { DirectorComponent } from '@portal-workspace/grow-ui-library';
import { PartnerComponent } from '@portal-workspace/grow-ui-library';
import { SoleTraderComponent } from '@portal-workspace/grow-ui-library';
import { TrusteeComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { SecondaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { PrimaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { AnnualRevenueComponent } from '@portal-workspace/grow-ui-library';
import { AcnComponent } from '@portal-workspace/grow-ui-library';
import { AbnComponent } from '@portal-workspace/grow-ui-library';
import { EntityTypeComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationHeaderSegmentComponent } from '@portal-workspace/grow-ui-library';
import { BrokerageSelectionComponent } from '@portal-workspace/grow-ui-library';
import { FinanceTypeSelectionComponent } from '@portal-workspace/grow-ui-library';
import { DisableControlDirective } from '@portal-workspace/grow-ui-library';
import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { LoanTermsSelectionComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { AssetConditionComponent } from '@portal-workspace/grow-ui-library';
import { AssetSelectionComponent } from '@portal-workspace/grow-ui-library';
import { AssetPurposeSelectionComponent } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponent } from '@portal-workspace/grow-ui-library';
import { UserSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { BusinessSearchComponent } from '@portal-workspace/grow-ui-library';
import {NgTemplateOutlet, JsonPipe, AsyncPipe} from '@angular/common';
import { ApplicationStepperComponent } from '@portal-workspace/grow-ui-library';
import { BusinessNumberSearchComponent } from '@portal-workspace/grow-ui-library';
import { TransactionTypeSelectionComponent } from '@portal-workspace/grow-ui-library';
import { CustomerApplicantComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationStepper2Component } from '@portal-workspace/grow-ui-library';
import { LoanTermsSelectionWithInputComponent } from '@portal-workspace/grow-ui-library';
import { DigitalIdService } from '../../service/digital-id.service';
import {LocalApplicationsDbService} from "../../service/local-applications-db.service";

export type DriverLicenceUpload = {id: string, name: string, formControl: FormControl<UploadFileValue>};
export type DriverLicenceUpload2 = {id: string, name: string, description: string, formControl: FormControl<UploadFileValue>};

// NOTE: Page to land when you are in DRAFT (new unsubmitted) asset application
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './asset-finance.page.html',
    styleUrls: ['./asset-finance.page.scss'],
    standalone: true,
    imports: [
      ApplicationStepperComponent,
      CdkStepperModule,
      NgTemplateOutlet,
      FormsModule,
      ReactiveFormsModule,
      BusinessSearchComponent,
      MarkDirective,
      MatCheckboxModule,
      MatTooltipModule,
      UserSelectionComponent,
      AggregatorSearchComponent,
      CurrencyInputComponent,
      AssetSelectionComponent,
      AssetConditionComponent,
      YesNoComponent,
      LoanTermsSelectionComponent,
      PaymentFrequencySelectionComponent,
      DisableControlDirective,
      FinanceTypeSelectionComponent,
      BalloonPaymentComponent,
      BrokerageSelectionComponent,
      ApplicationHeaderSegmentComponent,
      EntityTypeComponent,
      AbnComponent,
      AcnComponent,
      AnnualRevenueComponent,
      PrimaryIndustrySelectionComponent,
      SecondaryIndustrySelectionComponent,
      CustomAddressComponent,
      MobileComponent,
      TrusteeComponent,
      SoleTraderComponent,
      PartnerComponent,
      DirectorComponent,
      MemberComponent,
      GuarantorComponent,
      SelectContactComponent,
      MatFormFieldModule,
      MatInputModule,
      ReferenceComponent,
      MessageBoxComponent,
      UploadedFilesListComponent,
      UploadFileComponent,
      UploadFilesMultiTagsComponent,
      AssetFinanceSummaryComponent,
      BusinessNumberSearchComponent,
      TransactionTypeSelectionComponent,
      UserSameCompanySelectionComponent,
      SalesforceContactSameAccountSelectionComponent,
      JsonPipe,
      CustomerApplicantComponent,
      AssetPurposeSelectionComponent,
      AsyncPipe,
      ApplicationStepper2Component,
      LoanTermsSelectionWithInputComponent,
    ],
})
export class AssetFinancePage implements OnInit, AfterViewInit {

  ASSET_FINANCE_OPTIONS_FILTER = ASSET_FINANCE_OPTIONS_FILTER;

  businessSearchPlaceholder:string="Enter name or ABN…"

  MIN_LOAN_AMOUNT = 0.01;

  step2MaxLimit = 150000
  step2MinLimit = 2000

  MIN_ORIGINATION_FEE = 0;
  MAX_ORIGINATION_FEE = 900;

  existingApplication: AssetFinanceApplication | null = null; // editing an existing application
  existingApplicationBureauReport: AssetFinanceApplication['CompanyDetails'] | null = null;
  rateCard: RatecardDetails | null = null;

  errorKeys = formControlErrorKeys;
  errorMessages = formControlErrorMessage;
  @ViewChild('balloonPaymentComponent') balloonPaymentComponent!: BalloonPaymentComponent;
  @ViewChild('uploadFilesMultiTagsComponent') uploadFilesMultiTagsComponent!: UploadFilesMultiTagsComponent;

  // global:
  ip: string = '';
  geoLocation: GeoLocation = {};
  // user!: User;
  user: User = getUser()!;
  otherUsersObservable?: Observable<NotNullable<UserSelectionValue>[]>;
  brokerOfUser: BrokerOfUser | null = null;
  acceptedQuotation = false;
  brokerApplicationId: string | null = null;
  applicationId: number | null = null;
  isExternalUser : boolean = isExternalUser(this.user);
  AmountFinance!: number;
  // global: step 3 onwards
  organisationName: string = 'Not available';
  terms: TermRate | null = null;          // available after chart popups
  calculator: AppCalculator | null = null;   // available after chart popups

  // after popup confirmation
  businessUseOnly = false;
  selfServiceability = false;
  // privacyConfirmation = false;
  subscriptions: Subscription[] = [];

  defaultDocuments = defaultDocuments;

  // step 1:
  step1SearchFn!: BusinessSearchFn;
  // step1GetUsersFn!: GetUsersFunc;
  step1GetUserSameCompanyFn!: GetUserSameCompanyFunc;
  step1GetSalesforceContactSameAccountFn!: GetSalesforceContactSameAccountFunc;
  step1BrokerSearchFn!: AggregatorSearchFn;
  step1OrgSalesforceId: string | null = null;
  step1NoOtherUsersInCompany = true;
  formControlStep1Broker!: FormControl<AggregatorSearchComponentValue>;
  formGroupStep1!: FormGroup<{
    broker: FormControl<AggregatorSearchComponentValue>,
    business: FormControl<BusinessSearchValue>,
    showCorrespondent: FormControl<boolean | null>,
    correspondent: FormControl<UserSelectionValue>,
    previousBusiness: FormControl<BusinessNumberSearchValue>,
    brokerContact:FormControl<SalesforceContactSameAccountSelectionValue>
  }>;
  formControlStep1Business!: FormControl<BusinessSearchValue>;
  formControlStep1ShowCorrespondent!: FormControl<boolean | null>;
  formControlStep1Correspondent!: FormControl<UserSameCompanySelectionValue>;
  formControlStep1BrokerContact!: FormControl<SalesforceContactSameAccountSelectionValue>;
  formControlStep1PreviousBusiness!: FormControl<BusinessNumberSearchValue>;

  // step 1_b:
  formGroupStep1_b!: FormGroup<{
    customerApplicantType: FormControl<CustomerApplicantTypeValue>;
    privacyStatementCheck: FormControl<boolean | null>;
  }>;
  formControlStep1_bCustomerApplicantType!: FormControl<CustomerApplicantTypeValue>;
  formControlStep1_bPrivacyStatementCheck!: FormControl<boolean | null>;

  // step 2:
  step2AssetCategoryIndexFilter = ['130', '137', '132', '133', '134', '135', '136', '106', '140', '142'];
  step2MinBrokerOriginationFee = this.MIN_ORIGINATION_FEE ;
  step2MaxBrokerOriginationFee = this.MAX_ORIGINATION_FEE ;
  step2DepositMin: number | null = null;
  step2DepositMax: number | null = null;
  formGroupStep2!: FormGroup<{
    invoiceAmount: FormControl<CurrencyInputValue>,
    depositAmount: FormControl<CurrencyInputValue>,
    loanAmount: FormControl<CurrencyInputValue>,
    asset: FormControl<AssetSelectionComponentValue>,
    assetCondition: FormControl<AssetConditionValue>,
    loanTerms: FormControl<LoanTermsSelectionWithInputValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    financeType: FormControl<FinanceTypeValue>,
    docFeeFinanced: FormControl<YesNoValue>,
    balloonPayment: FormControl<BalloonPaymentValue>,
    brokerOriginationFee: FormControl<CurrencyInputValue>,
    brokerage: FormControl<BrokerageSelectionValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    equifaxScoreAboveThreshold: FormControl<YesNoValue>,
    transactionType: FormControl<TransactionValue>,
    previousLoan: FormControl<YesNoValue>,
    assetPurpose: FormControl<AssetPurposeComponentValue>,


  }>;
  formControlStep2InvoiceAmount!: FormControl<CurrencyInputValue>;
  formControlStep2DepositAmount!: FormControl<CurrencyInputValue>;
  formControlStep2LoanAmount!: FormControl<CurrencyInputValue>;
  formControlStep2Asset!: FormControl<AssetSelectionComponentValue>;
  step2AssetSearchFn!: AssetSelectionComponentSearchFn;
  formControlStep2AssetCondition!: FormControl<AssetConditionValue>;
  formControlStep2LoanTerms!: FormControl<LoanTermsSelectionWithInputValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2FinanceType!: FormControl<FinanceTypeValue>;
  formControlStep2DocFeeFinanced!: FormControl<YesNoValue>;
  formControlStep2BalloonPayment!: FormControl<BalloonPaymentValue>;
  formControlStep2BrokerOriginationFee!: FormControl<CurrencyInputValue>;
  formControlStep2Brokerage!: FormControl<BrokerageSelectionValue>;
  formControlStep2TransactionType!: FormControl<TransactionValue>;
  formControlStep2AssetPurpose!: FormControl<AssetPurposeComponentValue>;

  // NOTE: not used formControlStep2AssetexistingFinanceCommitment!: FormControl<boolean|null>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formControlStep2AdverseOnFile!: FormControl<YesNoValue>;
  formControlStep2PreviousLoan!: FormControl<YesNoValue>;
  formControlStep2EquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  step2Repayment = 0;
  step2EstimatedDrawdownDate: Moment = moment();
  step2Subscription1?: Subscription;
  _showAssetPurpose = false;


  // step 3:
  step3OnwardsOrganisationType: EntityTypes | null = null;
  formGroupStep3!: FormGroup<{
    organisationType: FormControl<EntityTypeValue>,
    abn: FormControl<AbnComponentValue>,
    acn: FormControl<AcnComponentValue>,
    annualRevenue: FormControl<AnnualRevenueValue>,
    operatesInCommercialPremise: FormControl<YesNoValue>,
    primaryIndustry: FormControl<PrimaryIndustrySelectionValue>,
    industrySector: FormControl<SecondaryIndustrySelectionValue>,
    address: FormControl<Address2ComponentValue>,
    businessLandline: FormControl<MobileValue>,
  }>;
  formControlStep3OrganisationType!: FormControl<EntityTypeValue>;
  formControlStep3Abn!: FormControl<AbnComponentValue>;
  formControlStep3Acn!: FormControl<AcnComponentValue>;
  formControlStep3AnnualRevenue!: FormControl<AnnualRevenueValue>;
  formControlStep3OperatesInCommercialPremise!: FormControl<YesNoValue>;
  formControlStep3PrimaryIndustry!: FormControl<PrimaryIndustrySelectionValue>;
  formControlStep3IndustrySector!: FormControl<SecondaryIndustrySelectionValue>;
  formControlStep3Address!: FormControl<Address2ComponentValue>;
  formControlStep3BusinessLandline!: FormControl<MobileValue>;
  step3DialogSubscription?: Subscription;

  // step 4:
  step4BusinessSearchFn!: BusinessSearchFn;
  step4BusinessNumberSearchFn!: BusinessNumberSearchFn;
  step4StoredApplicant: ApplicationApplicant | null = null;
  formControlStep4Applicant!: FormControl<ApplicationApplicant>;
  formGroupStep4!: FormGroup<{
    applicant: FormControl<ApplicationApplicant>,
  }>;

  // step 5:
  step5CompanySearchFn!: BusinessSearchFn;
  step5BusinessNumberSearchFn!: BusinessNumberSearchFn;
  step5MandatoryGuarantors: IndividualGuarantor[] = [];
  formControlStep5Guarantors!: FormControl<GuarantorValue>;
  formGroupStep5!: FormGroup<{
    guarantors: FormControl<GuarantorValue>
  }>;

  // step 6:
  step6PredefinedContactSelection!: ContactValue[];
  formControlStep6PrimaryContact!: FormControl<SelectContactValue>;
  step6PossiblePrimaryContacts: ContactValue[] = [];
  formGroupStep6!: FormGroup<{
    primaryContact: FormControl<SelectContactValue>
  }>;
  // step 7:
  formControlStep7BrokerflowDocumentId!: FormControl<string | null>;
  formControlStep7References!: FormControl<ReferenceValue>;
  formGroupStep7!: FormGroup<{
    brokerflowDocumentId: FormControl<string | null>,
    references: FormControl<ReferenceValue>,
  }>;

  // step 8:
  step8DriverLicenses: DriverLicenceUpload2[] = [];
  step8UploadedDocs: AzureStorageDocument[] = [];
  step9UploadedDocs: AzureStorageDocument[] = [];
  step8UploadedDrDoc: AzureStorageDocument[] = [];
  step8DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];
  step9DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];

  formGroupStep8!: FormGroup<{}>;
  step8And9FilesUploadedToAzure: Base64File[] = [];

  // step 9:
  formControlStep9OtherSupportingDocuments!: FormControl<UploadAzureFilesValue>;
  formGroupStep9!: FormGroup<{
    otherSupportingDocuments: FormControl<UploadAzureFilesValue>,
  }>;

  // step 10:
  formControlStep10ApplicationNotes!: FormControl<string | null>;
  formGroupStep10!: FormGroup<{
    applicationNotes: FormControl<string | null>,
  }>;

  // step 11:
  formGroupStep11!: FormGroup<{}>;
  step11Summary!: AssetFinanceSummary;

  showAddressFormFlag = true;
  documentTypes: DocumentTag[] = [];
  productType!: AssetFinanceProductType;
  isSwitchToEasyDoc!: boolean;

  queryParams: Params = {};

  constructor(private formBuilder: FormBuilder,
              private redbookService: RedbookService,
              private toastService: PortalHotToastService,
              private applicationService: ApplicationService,
              private applicationDialogService: ApplicationDialogService,
              private localApplicationDbService: LocalApplicationsDbService,
              private adminService: AdminService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private digitalIdService: DigitalIdService) 
  {
  }
  async ngOnInit() {
    setupUntilDestroy(this);
    this.queryParams = this.route.snapshot.queryParams ?? {};
    this.existingApplication = (this.route.snapshot.data as any).application;
    this.rateCard = (this.route.snapshot.data as any).ratecard;
    this.productType = this.queryParams.productType ?? 'EASY DOC';
    this.isSwitchToEasyDoc = this.queryParams.isSwitchToEasyDoc ?? true;
    console.log(`Resolved [AssetFinance] application, productType=${this.productType}, isSwitchToEasyDoc=${this.isSwitchToEasyDoc}`, this.existingApplication);
    // this.route.queryParams.subscribe(params => {
    //   this.productType = params['selectedCard'];
    //   this.isSwitchToEasyDoc = params['isSwitchToEasyDoc'];
    // });
    if (this.existingApplication) {
      if (this.existingApplication.ApplicationType != 'AssetFinance') {
        this.toastService.error(`This application ${this.existingApplication.ApplicationId} is not an Asset Finance application`, `Invalid application type`);
      }
    }


    // get user
    // this.user = getUser()!;

    this.initStep1();
    this.initStep1_b();
    this.initStep2();
    this.initStep3();
    this.initStep4();
    this.initStep5();
    this.initStep6();
    this.initStep7();
    this.initStep8();
    this.initStep9();
    this.initStep10();
    this.initStep11();
    this.populate();


    // get ip
    const sub1 = this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub1);

    // get geolocation
    try {
      const geoLocation = await this.applicationService.getGeoLocation();
      if (geoLocation) {
        this.geoLocation = geoLocation;
      }
    } catch(err) {
      console.log(err);
    }


    // get broker of user
    const sub2 = this.applicationService.getBrokerOfUser(this.user.UserId).pipe(
      tap(r => {
        if (r.status) {
          this.brokerOfUser = r.payload;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub2);

    // pop up rental excl gst notification
    const sub3 = this.formControlStep2FinanceType.valueChanges.pipe(
      tap((financeType: FinanceTypeValue) => {
        if (financeType?.type === 'rental') {
          this.formControlStep2PaymentFrequency.disable();
          this.formControlStep2PaymentFrequency.setValue(PaymentFrequencyValueOptions[2]/*monthly*/);
        } else if (financeType?.type === 'chattel-mortgage') {
          this.formControlStep2PaymentFrequency.enable();
        }
      })
    ).subscribe();

    if (this.formControlStep2FinanceType.value?.type === 'chattel-mortgage'){
      this.formControlStep2PaymentFrequency.enable();
    } else{
      this.formControlStep2PaymentFrequency.disable();
      this.formControlStep2PaymentFrequency.setValue(PaymentFrequencyValueOptions[2]/*monthly*/);
    }
  }

  ngAfterViewInit(): void {
    const sub2= combineLatest([
      this.formControlStep2Asset.valueChanges,
      this.formControlStep2LoanTerms.valueChanges
    ]).pipe(
      tap((r: [AssetSelectionComponentValue, LoanTermsSelectionWithInputValue]) => {
        const assetSelectionValue = r[0];
        const loanTermValue = r[1];
        this.step2UpdateBalloonPaymentValidation(assetSelectionValue, loanTermValue);
      })
    ).subscribe();
    this.subscriptions.push(sub2);
    if (this.existingApplication) {
      setTimeout(()=> {
        this.step2UpdateBalloonPaymentValidation(this.formControlStep2Asset.value, this.formControlStep2LoanTerms.value);
      }, 2000);
    }
  }

  step2UpdateBalloonPaymentValidation(assetSelectionValue: AssetSelectionComponentValue, loanTermValue: LoanTermsSelectionWithInputValue) {
    if (assetSelectionValue && loanTermValue) {
      const assetCatgory = assetSelectionValue.category.index;
      const loanTerm = loanTermValue;
      const assetYear = parseInt(assetSelectionValue.year);
      const maxRv = new AppCalculator().getMaxRV(assetCatgory, loanTerm, assetYear);
      this.balloonPaymentComponent.setMaxRv(maxRv);
    }
  }

  step2UpdateDepositValidation() {
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    if (asset) {
      const invoiceAmount = this.formControlStep2InvoiceAmount.value ?? 0;
      const assetCategoryIndex = asset.category.index;
      const isPropetyOwner = this.formControlStep2PropertyOwner.value ?? false;
      const docCriteria = this.productType
      const minDeposit = minDepositForAssetFinance(invoiceAmount, assetCategoryIndex, isPropetyOwner, this.productType);
      this.step2DepositMin = minDeposit;
      this.step2DepositMax = _.round(invoiceAmount * 0.8, 2); // 80% of invoiceAmount;

      //this.step2MaxLimit
      this.step2MaxLimit = maxLoanForAssetFinance (assetCategoryIndex, isPropetyOwner,docCriteria)
      console.log("this.step2MaxLimit",this.step2MaxLimit)
      const depositAmount = this.formControlStep2DepositAmount.value ?? 0;
      const financeType = this.formControlStep2FinanceType.value;
      const depositAmountExGST = Math.round(depositAmount * 1000 / 11) / 100;
      const deposit = financeType?.type === 'rental' ? depositAmountExGST : depositAmount;
      const loanAmount = parseFloat((invoiceAmount - deposit > 0) ? (invoiceAmount - deposit).toFixed(2) : '0');
      this.formControlStep2LoanAmount.setValue(loanAmount);
    }
  }

  step2UpdatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlStep2PropertyOwner.value;
    if (propertyOwner) {
      this.formControlStep2PreviousLoan.clearValidators();
    } else {
      this.formControlStep2PreviousLoan.setValidators([Validators.required]);
    }
    this.formControlStep2PreviousLoan.updateValueAndValidity();
  }

  populate() {
    if (this.existingApplication) {
      // 1
      this.formControlStep1Business.setValue(applicationToBusinessSearchValue(this.existingApplication));
      this.formControlStep1PreviousBusiness.setValue(applicationToPreviousBusiness(this.existingApplication));
      this.formControlStep1ShowCorrespondent.setValue(applicationToHasAdditionalBrokerCorrespondent(this.existingApplication));
      this.formControlStep1Correspondent.setValue(applicationToAdditionalBrokerCorrespondent(this.existingApplication));
      this.formControlStep1BrokerContact.setValue(applicationToBrokerContact(this.existingApplication));

      this.brokerApplicationId = this.existingApplication.BrokerAppId;
      this.applicationId = this.existingApplication.ApplicationId;
      this.acceptedQuotation = this.existingApplication.AcceptQuotation;
      this.organisationName = applicationToLegalName(this.existingApplication) ?? '';
      const brokerSearchValue = applicationToBrokerSearchValue(this.existingApplication);
      this.formControlStep1Broker.setValue(brokerSearchValue);
      this.step1OrgSalesforceId = brokerSearchValue?.salesforceId ?? null;

      // 2
      this.acceptedQuotation = this.existingApplication.AcceptQuotation;
      this.existingApplicationBureauReport = this.existingApplication.CompanyDetails;
      this.formControlStep2InvoiceAmount.setValue(applicationToInvoiceAmount(this.existingApplication));
      this.formControlStep2DepositAmount.setValue(applicationToDepositAmount(this.existingApplication));
      this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.existingApplication));
      this.formControlStep2AssetCondition.setValue(applicationToAssetCondition(this.existingApplication));
      this.formControlStep2TransactionType.setValue(applicationToTransactionValue(this.existingApplication));
      this.formControlStep2LoanTerms.setValue(applicationToLoanTermsValue(this.existingApplication));
      this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.existingApplication) ??
      PaymentFrequencyValueOptions.find(o => o.type === 'Monthly') ?? null);
      this.formControlStep2FinanceType.setValue(applicationToFinanceType(this.existingApplication));
      this.formControlStep2DocFeeFinanced.setValue(applicationToDocFeeFinanced(this.existingApplication));
      this.formControlStep2BalloonPayment.setValue(applicationToBalloonPaymentPrecentage(this.existingApplication));
      this.formControlStep2BrokerOriginationFee.setValue(applicationToBrokerOriginationFee(this.existingApplication));
      this.formControlStep2Brokerage.setValue(applicationToBrokerage(this.existingApplication));
      this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.existingApplication));
      this.formControlStep2AdverseOnFile.setValue(applicationToAdverseOnFile(this.existingApplication));
      this.formControlStep2EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.existingApplication));
      this.formControlStep2PreviousLoan.setValue(applicationToPreviousLoan(this.existingApplication));
      this.formControlStep2Asset.setValue(applicationToAsset(this.existingApplication));
      this.formControlStep2AssetPurpose.setValue(applicationToAsset(this.existingApplication))
      // this.step2UpdateDepositValidation();


      // 3
      this.formControlStep3OrganisationType.setValue(applicationToOrganisationType(this.existingApplication));
      this.formControlStep3Abn.setValue(applicationToAbn(this.existingApplication))
      this.formControlStep3Acn.setValue(applicationToAcn(this.existingApplication));
      this.formControlStep3AnnualRevenue.setValue(applicationToRevenue(this.existingApplication));
      this.formControlStep3OperatesInCommercialPremise.setValue(applicationToOperatesInCommercialPremise(this.existingApplication));
      this.formControlStep3PrimaryIndustry.setValue(applicationToPrimaryIndustry(this.existingApplication));
      this.formControlStep3IndustrySector.setValue(applicationToIndustrySector(this.existingApplication));
      this.formControlStep3Address.setValue(applicationToPrimaryAddress(this.existingApplication));
      this.formControlStep3BusinessLandline.setValue(applicationToBusinessLandline(this.existingApplication));
      // 4
      this.step4StoredApplicant = applicationToApplicants(this.existingApplication);
      this.formControlStep4Applicant.setValue(this.step4StoredApplicant);
      if (this.formControlStep4Applicant.value !== null) {
        let propertyOwner: any
        let residentialAddress: any
        // if (this.formControlStep4Applicant.value.kind === 'SoleTrader') {
        if (isApplicationApplicantsSoleTrader(this.formControlStep4Applicant.value)) {
          propertyOwner = this.formControlStep4Applicant.value.propertyOwner;
          residentialAddress = this.formControlStep4Applicant.value.residentialAddress;
        }
        else {
          const applicant = this.formControlStep4Applicant.value;
          for (let i = 0; i <= applicant.length - 1; i++) {
            const currentApplicant = applicant[i];
            if (isApplicationApplicantIndividual(currentApplicant)) {
              propertyOwner = currentApplicant.propertyOwner;
              residentialAddress = currentApplicant.residentialAddress;
            }
          }
        }
        if (propertyOwner) {
          if (propertyOwner.propertyOwner) {
            if (isAddressEquals(residentialAddress, propertyOwner.address)) {
              this.showAddressFormFlag = false;
            }
          }
        }
      }

      // 5
      this.formControlStep5Guarantors.setValue(applicationToGuarantors(this.existingApplication));

      // 6
      this.formControlStep6PrimaryContact.setValue(applicationToPrimaryContact(this.existingApplication));

      // 7
      this.formControlStep7BrokerflowDocumentId.setValue(applicationToBrokerFlowDocumentId(this.existingApplication));
      this.formControlStep7References.setValue(applicationToReferences(this.existingApplication));

      // 8-10
      this.formControlStep10ApplicationNotes.setValue(applicationToApplicationNotes(this.existingApplication));
      this.listUploadedDocuments();

      this.documentTypes = this.existingApplication.DocumentTypes ?? [];

    } else {  // not prepopulating ie. creating new application

      // auto populate originator business if we have one
      const orignatorBusinessId = this.user?.OriginatorBusinessId;
      // const aggregatorId = this.user?.AggregatorId;
      if (orignatorBusinessId) {
        this.subscriptions.push(this.applicationService.getOriginatorBusinessById(orignatorBusinessId).pipe(
          tap(r => {
            if (r.payload) {
              const originatorBusiness: OriginatorBusiness = r.payload;
              const v: AggregatorSearchComponentValue = {
                abn: originatorBusiness.ABN,
                entityName: originatorBusiness.EntityName,
                salesforceId: originatorBusiness.SalesforceId,
              }
              this.formControlStep1Broker.setValue(v);
            }
          })
        ).subscribe());
      }
      // this.documentTypes = this.applicationDefaultDocuments(false);
    }
  }

  initStep1() {
    // step 1:
    this.formControlStep1Business = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1PreviousBusiness =  this.formBuilder.control(null);
    this.formControlStep1ShowCorrespondent = this.formBuilder.control(false)
    this.formControlStep1Correspondent = this.formBuilder.control(null);
    this.formControlStep1BrokerContact = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      broker: this.formControlStep1Broker,
      business: this.formControlStep1Business,
      showCorrespondent: this.formControlStep1ShowCorrespondent,
      correspondent: this.formControlStep1Correspondent,
      brokerContact:this.formControlStep1BrokerContact,
      previousBusiness: this.formControlStep1PreviousBusiness,
    });
    this.step1BrokerSearchFn = (term)=> {
      return this.applicationService.searchBroker2(term).pipe(map(r => r.payload));
    }
    setStepper2StepConfig(this.formGroupStep1, {
      nextStepClickedFn: (stepper) => {
        stepper.next();
      },
      previousStepClickedFn: async (stepper) => {
        if(this.applicationId){
          await this.router.navigateByUrl(navigationUrlForApplicationsWithQueryParams(this.router, this.queryParams));
        }else {
          await this.router.navigateByUrl(navigationUrlForAssetProductSelectorWithQueryParams(this.router, undefined, this.queryParams));
        }
      }
    });

    this.step1SearchFn = this.applicationService.businessSearchFn
    this.step1GetUserSameCompanyFn = (orgSalesforceId: string) => {
      return this.authService.getOtherUsersInCompanyBySalesforceIdFn(orgSalesforceId).pipe(
        map(r => {
          // exclude this user
          const filteredCorrespndentUserList =  r.filter(u => u.UserId != this.user.UserId);
          this.step1NoOtherUsersInCompany = filteredCorrespndentUserList.length == 0;
          return filteredCorrespndentUserList;
        })
      );
    }
    this.step1GetSalesforceContactSameAccountFn = this.authService.getSalesforceContactSameAccountFn;



    this.subscriptions.push(this.formControlStep1Broker.valueChanges.pipe(
      tap(broker => {
        if (broker) {
          this.step1OrgSalesforceId = broker.salesforceId;
          this.step1GetUserSameCompanyFn(this.step1OrgSalesforceId).pipe().subscribe();
          // this.step1GetSalesforceContactSameAccountFn(this.step1OrgSalesforceId).pipe().subscribe();
        }
      })
    ).subscribe());
    this.subscriptions.push(this.formControlStep1ShowCorrespondent.valueChanges.pipe(
      tap((r)=> {
        if(!r){
          this.formControlStep1Correspondent.setValue(null)
        }
      })
    ).subscribe())
    this.subscriptions.push(this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r && r.type === 'search-result') { // NOTE: we can deduce only when business is a ASIC/ACN search
          if (r.result?.entityType) {
            const description = r.result.entityType.entityDescription;
            const code = r.result.entityType.entityTypeCode;

            if ('IND' == code) {
              this.formControlStep3OrganisationType.setValue(EntityTypeSoleTraderOption);
            } else if (description && description.toLowerCase().indexOf('trust') >= 0) {
              this.formControlStep3OrganisationType.setValue(EntityTypeTrustOption);
            } else if (description && description.toLowerCase().indexOf('company') >= 0) {
              this.formControlStep3OrganisationType.setValue(EntityTypeCompanyOption);
            } else if (description && description.toLowerCase().indexOf('partnership') >= 0) {
              this.formControlStep3OrganisationType.setValue(EntityTypePartnershipOption);
            } else {
              this.formControlStep3OrganisationType.setValue(EntityTypeOtherOption);
            }
          }
        }
      })
    ).subscribe());

    const sub = this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r) {
          if (r) {
            if (r.abn && this.formControlStep3Abn) {
              this.formControlStep3Abn.setValue(r.abn);
            }
            if (r.acn && this.formControlStep3Acn) {
              const acn = patchAcn(r.acn);
              this.formControlStep3Acn.setValue(acn ?? null);
            }
            if(r.abn && this.existingApplication){
              if(r.abn !== getAbn(this.existingApplication)){
                this.formControlStep1_bCustomerApplicantType.setValue(null);
                this.formControlStep1_bPrivacyStatementCheck.setValue(null);
              }
              else{
                const userApplicantDetails = this.existingApplication?.AppInfo;
                if (userApplicantDetails) {
                  if (userApplicantDetails.MetApplicant !== undefined) {
                    this.formControlStep1_bCustomerApplicantType.setValue(applicationToApplicantType(this.existingApplication));
                  }
                  if (userApplicantDetails.PrivacyConfirmation !== undefined) {
                    this.formControlStep1_bPrivacyStatementCheck.setValue(applicationToPrivacyConfirmation(this.existingApplication));
                  }
                }
              }
            }
            this.organisationName = toInteflowLegalName(r) ?? '';
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

    const sub2 = this.formControlStep1Business.valueChanges.subscribe(v => {
      if (v && v.type === 'search-result') { // NOTE: only when business is done through ASIC/ABN search
          const ABNAge = v?.result?.ABNAgeMonths ?? 0;
          if (v && ABNAge < 12) {
              this.formControlStep1PreviousBusiness.setValidators([Validators.required]);
              this.formControlStep1PreviousBusiness.updateValueAndValidity();
          } else {
              this.formControlStep1PreviousBusiness.setValidators(null);
              this.formControlStep1PreviousBusiness.updateValueAndValidity();
          }
      } else { // else we assume no previous business is required
          this.formControlStep1PreviousBusiness.setValidators(null);
          this.formControlStep1PreviousBusiness.updateValueAndValidity();
      }
    })
    this.subscriptions.push(sub2);
  }

  initStep2() {
    // step 2:
    this.formControlStep2InvoiceAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DepositAmount = this.formBuilder.control(0, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required ]);
    this.formControlStep2Asset = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2AssetCondition = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control( { type: 'Monthly', name: 'Monthly'}, [Validators.required]);
    this.formControlStep2FinanceType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DocFeeFinanced = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2BalloonPayment = this.formBuilder.control(BalloonPaymentValueOptions[0], [Validators.required]);
    this.formControlStep2BrokerOriginationFee = this.formBuilder.control(0, [Validators.required]);
    this.formControlStep2Brokerage = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2EquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2TransactionType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PreviousLoan = this.formBuilder.control(null);
    this.formControlStep2AssetPurpose = this.formBuilder.control(null);

    this.step2AssetSearchFn = this.redbookService.assetSearchFn;
    this.formGroupStep2 = this.formBuilder.group({
      invoiceAmount: this.formControlStep2InvoiceAmount,
      depositAmount: this.formControlStep2DepositAmount,
      loanAmount: this.formControlStep2LoanAmount,
      asset: this.formControlStep2Asset,
      assetCondition: this.formControlStep2AssetCondition,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      financeType: this.formControlStep2FinanceType,
      docFeeFinanced: this.formControlStep2DocFeeFinanced,
      balloonPayment: this.formControlStep2BalloonPayment,
      brokerOriginationFee: this.formControlStep2BrokerOriginationFee,
      brokerage: this.formControlStep2Brokerage,
      propertyOwner: this.formControlStep2PropertyOwner,
      adverseOnFile: this.formControlStep2AdverseOnFile,
      equifaxScoreAboveThreshold: this.formControlStep2EquifaxScoreAboveThreshold,
      transactionType: this.formControlStep2TransactionType,
      previousLoan: this.formControlStep2PreviousLoan,
      assetPurpose: this.formControlStep2AssetPurpose,
    });


    this.formControlStep2TransactionType.valueChanges.pipe(
      tap((r)=> {
         console.log("formControlStep2TransactionType",r)
         if(r && r.type && (r.type === 'Dealer Sale' || r.type === 'Private Sale')){
          this._showAssetPurpose = true
          this.formControlStep2AssetPurpose.setValidators([Validators.required]);
         }else {
            this._showAssetPurpose = false
            this.formControlStep2AssetPurpose.clearValidators();
            this.formControlStep2AssetPurpose.setValue(null);
         }
         this.formControlStep2AssetPurpose.updateValueAndValidity();
      })
    ).subscribe();

    this.formControlStep2LoanTerms.valueChanges.pipe(
    tap((r)=> {

      if ((r && (r > 60) ) || this.formControlStep2FinanceType.value?.type === 'rental') { // for loan terms > 5 years
        // no balloon payment, auto to zero (disable it)
        this.formControlStep2BalloonPayment.setValue(BalloonPaymentValueOptions[0]);
        this.formControlStep2BalloonPayment.disable();
      } else {
        // ballon payment allowed, enable it
        this.formControlStep2BalloonPayment.enable();
      }
    })
  ).subscribe();

    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        await this.step2ShowQuotation(stepper);
        this.documentTypes = this.applicationDefaultDocuments(false);
      },
      nextStepButtonText: `Calculate Repayments`,
    });
    const subscription = combineLatest([
      this.formControlStep2InvoiceAmount.valueChanges,
      this.formControlStep2DepositAmount.valueChanges,
      this.formControlStep2FinanceType.valueChanges,
    ]).pipe(
      tap(r => {
        const invoiceAmount = r[0] ?? 0;
        const depositAmount = r[1] ?? 0;
        const financeType = r[2];
        const depositAmountExGST = Math.round(depositAmount * 1000 / 11) / 100;
        const deposit = financeType?.type === 'rental' ? depositAmountExGST : depositAmount;
        const loanAmount = parseFloat((invoiceAmount - deposit > 0) ? (invoiceAmount - deposit).toFixed(2) : '0');
        this.formControlStep2LoanAmount.setValue(loanAmount);
      })
    ).subscribe();
    this.subscriptions.push(subscription);

    // deposit calculation trigger
    this.subscriptions.push(this.formControlStep2InvoiceAmount.valueChanges.pipe(
      tap(r => {
        this.step2UpdateDepositValidation();
      })
    ).subscribe());
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdateDepositValidation();
        this.step2UpdatePreviousLoanValidation();
      })
    ).subscribe());
    this.subscriptions.push(this.formControlStep2Asset.valueChanges.pipe(
      tap(r => {
        this.step2UpdateDepositValidation();
      })
    ).subscribe());

    // balloon payment changes for rental application
    this.subscriptions.push(this.formControlStep2FinanceType.valueChanges.pipe(
      tap(r => {
        if(r && r.type === 'rental') {
          this.formControlStep2BalloonPayment.disable();
          this.formControlStep2BalloonPayment.setValue(BalloonPaymentValueOptions[0]);
        } else {
          this.formControlStep2BalloonPayment.enable();
        }
      })
    ).subscribe())
  }

  initStep1_b(){
    this.formControlStep1_bCustomerApplicantType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1_bPrivacyStatementCheck = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1_b = this.formBuilder.group({
      customerApplicantType: this.formControlStep1_bCustomerApplicantType,
      privacyStatementCheck: this.formControlStep1_bPrivacyStatementCheck,
    })

    const sub = this.formControlStep1_bPrivacyStatementCheck.valueChanges.pipe(
      tap((r) =>{
          if (r === false) {
            this.formControlStep1_bPrivacyStatementCheck.setValue(null);
          }
      })
    ).subscribe()
    this.subscriptions.push(sub);

    setStepper2StepConfig(this.formGroupStep1_b, {
      nextStepButtonText: 'Next',
      nextStepClickedFn: stepper => {
        stepper.next();
      }
    });
  }

  initStep3() {
    // step 3: Company details
    this.formControlStep3OrganisationType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Abn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Acn = this.formBuilder.control(null);
    this.formControlStep3AnnualRevenue = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3OperatesInCommercialPremise = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep3PrimaryIndustry = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3IndustrySector = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Address = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3BusinessLandline = this.formBuilder.control('');
    this.formGroupStep3 = this.formBuilder.group({
      organisationType: this.formControlStep3OrganisationType,
      abn: this.formControlStep3Abn,
      acn: this.formControlStep3Acn,
      annualRevenue: this.formControlStep3AnnualRevenue,
      operatesInCommercialPremise: this.formControlStep3OperatesInCommercialPremise,
      primaryIndustry: this.formControlStep3PrimaryIndustry,
      industrySector: this.formControlStep3IndustrySector,
      address: this.formControlStep3Address,
      businessLandline: this.formControlStep3BusinessLandline,
    });

    const sub = this.formControlStep3OrganisationType.valueChanges.pipe(
      tap((r: EntityTypeValue) => { // change org type, clear applicant field.
        if (r) {
          // this.step4StoredApplicant = null;
          if(this.step3OnwardsOrganisationType && this.step3OnwardsOrganisationType !== r.type) {
            // the org type changes and is different from prev value
            this.formControlStep4Applicant.setValue(null)
          }
          this.step3OnwardsOrganisationType = r.type;
          if (r.type === 'company') {
            this.formControlStep3Acn.setValidators([Validators.required]);
          } else {
            this.formControlStep3Acn.setValidators(null);
            this.formControlStep3Acn.updateValueAndValidity();
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
    setStepper2StepConfig(this.formGroupStep3, {
      nextStepClickedFn: stepper => {
        if(this.formGroupStep3.value.businessLandline === null){
          this.applicationDialogService.openAlertDialog({
            message: `Error`,
            subMessage: 'Invalid bussiness landline'
          });
        }
        else{
          this.step3DialogSubscription = this.applicationDialogService.openCreditInfoExchangeAlertDialog()
            .afterClosed()
            .pipe(
              tap(async (r) => {
                if (r && r.proceed) {
                  await this.saveApplication(stepper, 3);
                  // await this.updateDraftInBg(3);
                  // stepper.next();
                }
              })
            ).subscribe();
        }
      }
    });
  }

  initStep4() {
    // step 4: Applicant
    this.step4BusinessSearchFn = this.applicationService.businessSearchFn;
    this.step4BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep4Applicant = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep4 = this.formBuilder.group({
      applicant: this.formControlStep4Applicant,
    });
    this.formControlStep4Applicant.valueChanges.pipe(
      tap(r => {
        if (Array.isArray(r) && r[0] && r[0].kind == 'Trustee') {
          this.formGroupStep4.setValidators([
            trusteeValidator(() => ({
              applicant: r
            }))
          ])
        } else if (Array.isArray(r) && r[0] && r[0].kind == 'Partner') {
          this.formGroupStep4.setValidators([
            partnerValidator(() => ({
              applicant: r
            }))
          ])
        } else {
          this.formGroupStep4.clearValidators()
        }
      })
    ).subscribe()
    setStepper2StepConfig(this.formGroupStep4, {
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 4);
      }
    });
  }

  initStep5() {
    // step 5:
    this.step5CompanySearchFn = this.applicationService.businessSearchFn;
    this.step5BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep5Guarantors = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.formGroupStep5 = this.formBuilder.group({
      guarantors: this.formControlStep5Guarantors,
    });
    this.formGroupStep5.setValidators([
      guarantorValidator(()=>({
        mandatoryGuarantors: this.step5MandatoryGuarantors,
        guarantors: this.formControlStep5Guarantors.value
      }))
    ]);
    setStepper2StepConfig(this.formGroupStep5, {
      stepSelectionEventFn: e => {
        const guarantors = this.step5OnwardsApplicantsThatAreGuarantor();
        this.step5MandatoryGuarantors = guarantors;
      },
      nextStepClickedFn: async stepper => {
        if (this.formGroupStep2.value.propertyOwner == true) {
          const applicants: ApplicationApplicant = this.formControlStep4Applicant.value;
          const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;

          const applicantIndividuals = fromApplicantToInteflowIndividualsData(applicants);
          const guarantorIndividuals = fromGuarantorToInteflowIndividuals(guarantors);

          let otherIndividuals = [
            ...applicantIndividuals,
            ...guarantorIndividuals,
          ];
          otherIndividuals = otherIndividuals.filter(individual => individual.PropertyOwnerFlag == true)
          if (otherIndividuals.length > 0) {
            await this.updateDraftInBg(5);
            stepper.next();
          }
          else {
            this.applicationDialogService.openAlertDialog({
              message: `Invalid Details`,
              subMessage: "Please enter property details for at least one individual as you select property owner = yes in the risk profile."
            });
          }
        }
        else {
          await this.saveApplication(stepper, 5);
        }
      }
    });
  }

  initStep6() {
    // step 6:
    this.step6PredefinedContactSelection = [];
    this.formControlStep6PrimaryContact = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep6 = this.formBuilder.group({
      primaryContact: this.formControlStep6PrimaryContact,
    });
  
    setStepper2StepConfig(this.formGroupStep6, {
      stepSelectionEventFn: e => {
        const mandatoryGuarantors: IndividualGuarantor[] = this.step5MandatoryGuarantors;
        const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
        const possiblePrimaryContacts: ContactValue[] = [];
        const applicants: ApplicationApplicant = this.formControlStep4Applicant.value;
        // individual can be primary contact
        if (applicants) {
          const c: ContactValue[] = applicantsToPrimaryContact(applicants);
          if(c) {
            possiblePrimaryContacts.push(...c);
          }
        }
        // all guarantors can be primary contact
        for (const g of mandatoryGuarantors) {
          const c: ContactValue = {
            isManual: false,
            email: g.email,
            mobileNumber: g.mobileNumber,
            areaCode: '',
            lastName: g.lastName,
            firstName: g.firstName,
            title: g.title,
            telephone: g.mobileNumber
          };
          possiblePrimaryContacts.push(c);
        }
        if (guarantors) {
          for (const g of guarantors) {
            if (g.type === 'Individual') {
              const c: ContactValue = {
                isManual: false,
                email: g.email,
                mobileNumber: g.mobileNumber,
                areaCode: '',
                lastName: g.lastName,
                firstName: g.firstName,
                title: g.title,
                telephone: g.mobileNumber
              };
              possiblePrimaryContacts.push(c);
            }
          }
        }
        // this.step6PossiblePrimaryContacts.push(...possiblePrimaryContacts);
        this.step6PossiblePrimaryContacts = filterOutUniquePrimaryContacts(possiblePrimaryContacts);
        // Find matching primary contact
        const filledPrimaryContact = this.formControlStep6PrimaryContact.value;
        const contactObj = this.step6PossiblePrimaryContacts.find(a =>
          a.email === filledPrimaryContact?.email && a.mobileNumber === filledPrimaryContact?.mobileNumber &&
          a.lastName === filledPrimaryContact?.lastName && a.firstName === filledPrimaryContact?.firstName &&
          a.title === filledPrimaryContact?.title);
  
        if (contactObj) {
          this.formControlStep6PrimaryContact.setValue(contactObj);
        } else {
          this.formControlStep6PrimaryContact.setValue(null);
        }
      },
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 6);
      }
    });
  }
  
  initStep7() {  // reference
    // step 7
    this.formControlStep7BrokerflowDocumentId = this.formBuilder.control(null);
    this.formControlStep7References = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.formGroupStep7 = this.formBuilder.group({
      brokerflowDocumentId: this.formControlStep7BrokerflowDocumentId,
      references: this.formControlStep7References,
    });
    setStepper2StepConfig(this.formGroupStep7, {
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 7);
      }
    });
  }

  initStep8() { // document upload - driver licence
    // step 8:
    this.formGroupStep8 = this.formBuilder.group({
    });
    setStepper2StepConfig(this.formGroupStep8, {
      nextStepButtonText: 'Next',
      stepSelectionEventFn: e => {
        const allThatRequireDriverLicences = this.getAllThatRequireDriverLicences();

        for (const prop in this.formGroupStep8.controls) {
          if (prop.endsWith(' Driver Licence')) {
            (this.formGroupStep8).removeControl(prop);
          }
        }

        const driverLicences: DriverLicenceUpload2[]  = [];
        for (const requireDriverLicence of allThatRequireDriverLicences) {
          const id = `${_.lowerCase(requireDriverLicence.firstName)}${_.lowerCase(requireDriverLicence.middleName)}${_.lowerCase(requireDriverLicence.lastName)}`;
          const existingEntry = this.step8DriverLicenses.find(e => e.id === id);
          if (existingEntry) {
            driverLicences.push(existingEntry);
            (this.formGroupStep8).addControl(existingEntry.description, existingEntry.formControl);
          } else {
            const name = `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`;
            const description = `${name} Driver Licence`;
            const skipValidation = this.skipFileUploadValidationForName(name); // this.step8UploadedDocs && this.step8UploadedDocs.length);
            const formControl = this.formBuilder.control(null,
              skipValidation ? [] : [Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)]);
            driverLicences.push({id, name, description, formControl});
            (this.formGroupStep8).addControl(description, formControl);
          }
        }
        this.step8DriverLicenses = driverLicences;
      },
      nextStepClickedFn: async stepper => {
        this.documentTypes = this.applicationDefaultDocuments(true);
          const checkUploadFileMoreThenTwo = this.step8DriverLicenses.filter(formArg=> {
                  if(formArg.formControl.value && formArg.formControl.value.length > 0){
                    const existingEntry  = this.step8UploadedDrDoc.filter(arg =>  arg.metadata && arg.metadata.driverslicencename && arg.metadata.driverslicencename === formArg.name)
                    if (existingEntry &&  (existingEntry.length + formArg.formControl.value.length) > 2) {
                      return true
                    }else {
                      return false;
                    }
                  }else {
                    return false
                  }
              })

          if(checkUploadFileMoreThenTwo.length > 0){
            const fileNamesArray = checkUploadFileMoreThenTwo.map(item => item.name).join(", ");
            const validationMessage = `You have uploaded too many Driver Licence files (${fileNamesArray}). Please upload only up to two files.`;

            this.applicationDialogService.openAlertDialog({
              message: `Validation Error`,
              subMessage: validationMessage
            });

          }else {
            await this.saveApplication(stepper, 8);
          }
      }
    });
  }

  // other supporting doc
  initStep9() {
    this.formControlStep9OtherSupportingDocuments = this.formBuilder.control(null,[duplicateFileNameValidator()]);
    this.formGroupStep9 = this.formBuilder.group({
      otherSupportingDocuments: this.formControlStep9OtherSupportingDocuments,
    });
    setStepper2StepConfig(this.formGroupStep9, {
      nextStepButtonText: 'Next',
      nextStepClickedFn: async stepper => {

        const isMissingTagsCheck = this.formGroupStep9.value.otherSupportingDocuments?.filter(file => file.tags && file.tags.length === 0) ;
        if(isMissingTagsCheck?.length){
          const fileNames = isMissingTagsCheck.map(file => file.name).join(', ');
          this.applicationDialogService.openAlertDialog({
             message: `Validation Error`,
             subMessage: `The following files are missing tags: ${fileNames}`
           });
        }else {

          await this.saveApplication(stepper, 9);
        }

      },

    });
  }

  // application note
  initStep10() {
    this.formControlStep10ApplicationNotes = this.formBuilder.control(null);
    this.formGroupStep10 = this.formBuilder.group({
      applicationNotes: this.formControlStep10ApplicationNotes,
    });
    setStepper2StepConfig(this.formGroupStep10, {
      nextStepButtonText: 'Next to Review',
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 10);
      }
    });
  }

  initStep11() {
    // step 9:
    
    this.formGroupStep11 = this.formBuilder.group({ });
    setStepper2StepConfig(this.formGroupStep11, {
      nextStepButtonText: 'Submit',
      nextStepClickedFn: async stepper => {
        await this.finalSubmission(stepper);
      },
      
      stepSelectionEventFn: e => {
        const industryDivision = this.formControlStep3PrimaryIndustry.value?.division;
        const industryCode = this.formControlStep3IndustrySector.value?.code;
        const industrySector = (industryDivision && industryCode) ? getSecondaryIndustry(industryDivision, industryCode) : null;
        const assetData = this.formControlStep2Asset.value;
        if (assetData) {
          assetData.assetPurpose = this.formControlStep2AssetPurpose.value?.assetPurpose;
          assetData.assetPurposeDescription = this.formControlStep2AssetPurpose.value?.assetPurposeDescription;
          assetData.assetExistingFinanceCommitment = this.formControlStep2AssetPurpose.value?.assetExistingFinanceCommitment;
        }

        this.step11Summary = {
          broker: this.formControlStep1Broker.value,
          balloonPayment: this.formControlStep2BalloonPayment.value,
          balloonPaymentValue: this.terms?.RV??0,
          depositAmount: this.formControlStep2DepositAmount.value!,
          loanAmount: this.formControlStep2LoanAmount.value!,
          references: this.formControlStep7References.value,
          brokerFlowId: this.formControlStep7BrokerflowDocumentId.value!,
          isSwitchToEasyDoc : this.isSwitchToEasyDoc,
          productType: this.productType,
          repayment: String(this.step2Repayment),
          estimatedDrawDownDate: this.step2EstimatedDrawdownDate,
          brokerApplicationId: this.brokerApplicationId ?? '',
          organisation: this.formControlStep1Business.value,
          addtionalBrokerCorrespondent: this.formControlStep1Correspondent.value,
          organisationType: this.formControlStep3OrganisationType.value,
          abn: this.formControlStep3Abn.value!,
          acn: this.formControlStep3Acn.value!,
          financeType: this.formControlStep2FinanceType.value,
          annualRevenue: this.formControlStep3AnnualRevenue.value!,
          operatesInCommercialPremises: this.formControlStep3OperatesInCommercialPremise.value,
          primaryIndustry: this.formControlStep3PrimaryIndustry.value,
          industrySector: industrySector ?? this.formControlStep3IndustrySector.value,
          address: this.formControlStep3Address.value,
          businessLandline: this.formControlStep3BusinessLandline.value!,

          asset: assetData,
          assetCondition: this.formControlStep2AssetCondition.value,
          invoiceAmount: this.formControlStep2InvoiceAmount.value!,
          loanTerms: this.formControlStep2LoanTerms.value,
          transactionType: this.formControlStep2TransactionType.value,
          equifaxScoreAboveThreshold: this.formControlStep2EquifaxScoreAboveThreshold.value,
          adverseOnFile: this.formControlStep2AdverseOnFile.value,
          propertyOwner: this.formControlStep2PropertyOwner.value,
          brokerage: this.formControlStep2Brokerage.value,
          paymentFrequency: this.formControlStep2PaymentFrequency.value,
          docFee: this.formControlStep2DocFeeFinanced.value,
          brokerOriginationFee: this.formControlStep2BrokerOriginationFee.value!,
          applicants: this.formControlStep4Applicant.value ?? [],

          guarantors: this.formControlStep5Guarantors.value,

          primaryContact: this.formControlStep6PrimaryContact.value!,
          
          applicationNotes: this.formControlStep10ApplicationNotes.value!,
          brokerContact:this.formControlStep1BrokerContact.value!

        };
      }
    });
  }

  async finalSubmission(stepper: CdkStepper) {
    const _individualApplicants = individualApplicants(this.formControlStep4Applicant.value);
    const _skipApplicationPrivacyConsentDialog = skipApplicationPrivacyConsentDialog(this.formControlStep2TransactionType.value);
    const {
      atLeastOneWithPrivacyConsent,
      allHasPrivacyConsent,
      withoutPrivacyConsent
    } = applicantsPrivacyConsent(_individualApplicants);
  if (!_skipApplicationPrivacyConsentDialog && !allHasPrivacyConsent && !atLeastOneWithPrivacyConsent) {
      this.subscriptions.push(this.applicationDialogService.openApplicantsPrivacyConsentDialog({
        atLeastOneWithPrivacyConsent,
        applicants: withoutPrivacyConsent,
      }).afterClosed().pipe(
        tap(async (r) => {
          // console.log(await this.mapToInteflowData());
          if(r && r.type === 'save') {
            await this.updateDraftInBg(11);
            await this.agreeToTermsOfSubmission(stepper);
          }
        })
      ).subscribe());
    } else {
      await this.agreeToTermsOfSubmission(stepper);
    }
  }

  async agreeToTermsOfSubmission(stepper: CdkStepper) {
    this.subscriptions.push(this.applicationDialogService.openAssetFinanceApplicationConfirmationDialog()
      .afterClosed().pipe(
        tap(async (r) => {
          if (r && r.readyForSubmission) {
            this.businessUseOnly = true;
            this.selfServiceability = true;
            // this.privacyConfirmation = true;
            await this.sendFinalSubmission(stepper);
          }
      })
    ).subscribe());
  }

  async sendFinalSubmission(stepper: CdkStepper) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData();
      console.log('asset finance final submission data', data);

      // update driver licence doc metadata - as the individualId will be changed
      if (this.applicationId) {
        this.subscriptions.push(
          this.applicationService.listApplicationDocumentFn(this.applicationId).subscribe(
            (r: AzureStorageDocument[]) => {
              const driverLicenceDocs = r.filter(obj => Object.values(obj?.tags ?? {}).includes('driverslicence'));
              for (const doc of driverLicenceDocs) {
                const individual = data.Individuals.find(obj => (doc.metadata?.driverslicencename ?? '') == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
                if (individual && individual.id) {
                  this.applicationService.updateDocumentMetadataFn(doc.name, "", {
                    ...doc.metadata,
                    individualid: individual.id ?? ''
                  }).subscribe()
                }
              }
            }
          )
        )
      }

      // double check legal names for commercial entities
      if (data?.CommercialEntities?.length) {
        for (const entity of data.CommercialEntities) {
          if (!entity?.LegalName)  {
            this.applicationDialogService.openAlertDialog({
              message: `Alert`,
              subMessage: `Missing legal name for some entities.`
            });
            return;
          }
        }
      }

      this.subscriptions.push(this.applicationService.submitApplication(this.applicationId, data).pipe(
        // this.toastService.toastObserver('submit application'),
        this.toastService.spinnerObservable(),
        tap(r => {
        if (r && r.status) {
          this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
            tap(async r => {
              // stepper.reset();
              // stepper.next();
              // await this.router.navigate(navigationUrlForApplications({ reload: true }), {queryParams: {reload: true}});
              await this.router.navigateByUrl(navigationUrlForApplicationsWithQueryParams(this.router, {reload: true}));
            })
          ).subscribe();
        } else {
          this.applicationDialogService.openAlertDialog({
            message: `Error`,
            subMessage: r.message
          });
        }
      })).subscribe());
    }
  }

  async uploadFileToAzure(step: number, data: UpdateApplicationData) {
    let base64Files: Base64File[] = [];
    let allToBeDeleted: string[] = [];
    let allFiles: File[] = [];
    if (step === 8) { // driver's licence
      const driverLicencesUpload: DriverLicenceUpload2[] = this.step8DriverLicenses;
      allToBeDeleted = [...this.step8DeletedUploadedDocs].map(file => file.name);
      if (driverLicencesUpload && driverLicencesUpload.length) {
        const fileArr = driverLicencesUpload.flatMap(upload =>{
            if(upload.formControl.value){
              return upload.formControl.value!.map((f) => {
                const individual = data.Individuals.find(obj => upload.name == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
                (f as any).tags = ['driverslicence'];
                (f as any).metadata = individual?.id ? {
                  ...this.step9OtherDocMetadata,
                  individualid: individual.id,
                  driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
                } : this.step9OtherDocMetadata;
                return f;
              })
            }else {
              return []
            }
        });
        allFiles = fileArr.filter((f: File | null) => !!f) as File[];
      }

    } else if (step === 9) { // other supporting doc
      const otherSupportingDocs: UploadFileValue = this.formControlStep9OtherSupportingDocuments.value;
      allToBeDeleted = [...this.step9DeletedUploadedDocs].map(file => file.name);
      if (otherSupportingDocs && otherSupportingDocs.length) {
        allFiles = [...otherSupportingDocs];
      }
    } else {
      return;
    }

    base64Files = [...await filesToBase64Files(allFiles)];
    // double check if any changes with existing files
    // remove file that has been uploaded
    base64Files = base64Files.map(file => {
      file.metadata = file.tags?.includes('driverslicence') ? file.metadata : this.step9OtherDocMetadata
      return file;
    }).filter(f => {
      const fileIdentifiers = this.step8And9FilesUploadedToAzure.map(m => `${m.name}${m.tags?.join(' ')}`);
      return !fileIdentifiers.includes(`${f.name}${f.tags?.join(' ')}`);
    })

    this.step8And9FilesUploadedToAzure = [...this.step8And9FilesUploadedToAzure, ...base64Files];

    if (this.applicationId) {
      this.subscriptions.push(
        (await this.applicationService.uploadApplicationDocumentToAzureStorage(
            this.applicationId,
            [...base64Files],
            allToBeDeleted)
        ).subscribe(arg =>{
          if(step === 8){
            this.listUploadedDocuments()
            this.step8DriverLicenses.map(arg=>{
              arg.formControl.setValue(null)
              arg.formControl.removeValidators([Validators.required])
              arg.formControl.updateValueAndValidity();
            })
          }else if(step === 9){
            this.uploadFilesMultiTagsComponent.files = []
            this.uploadFilesMultiTagsComponent.filesWithTags = []
            this.uploadFilesMultiTagsComponent.formControl.setValue(null)
            this.listUploadedDocuments()
            this.formControlStep9OtherSupportingDocuments.setValue(null)
          }
        })
      );
    }
  }

  async mapToInteflowData() {

    const user = getUser();
    const org: EntityTypeValue = this.formControlStep3OrganisationType.value;
    const financeType: FinanceTypeValue = this.formControlStep2FinanceType.value;
    const applicationNotes = this.formControlStep10ApplicationNotes.value;
    const brokerflowDocumentId = this.formControlStep7BrokerflowDocumentId.value;
    const broker: AggregatorSearchComponentValue = this.formControlStep1Broker.value;
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    const contact: SelectContactValue = this.formControlStep6PrimaryContact.value;
    const applicants: ApplicationApplicant = this.formControlStep4Applicant.value;
    const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
    const refValue: ReferenceValue = this.formControlStep7References.value;
    const userApplicant: CustomerApplicantTypeValue = this.formControlStep1_bCustomerApplicantType.value;

    console.log("applicantsapplicants",applicants)
    // workout broker salesforce id
    let brokerSalesforceId = broker!.salesforceId;
    let brokerAbn = broker!.abn;
    let brokerEntityName = broker!.entityName;
    if (user!.BrokerSalesforceId === environment.GrowFinanceGroupSalesforceId) {
      if (broker && broker.salesforceId) {
        brokerSalesforceId = broker.salesforceId;
        brokerAbn = broker.abn;
        brokerEntityName = broker.entityName;
      } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId) {
        brokerSalesforceId = this.brokerOfUser.SalesforceId;
        brokerAbn = this.brokerOfUser.ABN;
        brokerEntityName = this.brokerOfUser.EntityName;
      }
    }
    else {
      if (broker) { // broker dropdown selection is being populated
        // use the declared brokerSalesforceId, brokerAbn and brokerEntityName
      } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId !== undefined) {
        // broker drop down selection is not being populated, we populate it with the current user's company details
        brokerSalesforceId = this.brokerOfUser.SalesforceId;
        brokerAbn = this.brokerOfUser.ABN;
        brokerEntityName = this.brokerOfUser.EntityName;
      }
    }

    // workout disclosure
    let disclosed = true;
    let introducerGroup = 'ECLIPXCOMMERCIAL';
    if (this.brokerOfUser) {
      if (this.brokerOfUser.Relationship !== 'disclosed') {
        disclosed = false;
        introducerGroup = 'UNDISCLOSED_ECLIPXCOMMERCIAL';
      }
    }

    // ====================
    // work out commercial entities
    // =============
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const c: UserSelectionValue = this.formControlStep1Correspondent.value;
    const o: EntityTypeValue = this.formControlStep3OrganisationType.value;
    const abn = this.formControlStep3Abn.value;
    const acn = this.formControlStep3Acn.value;
    let previousBusiness: BusinessNumberSearchValue = this.formControlStep1PreviousBusiness.value;
    if (b) {
        if (b.type === 'search-result') { // NOTE: when business is done through ASIC/ABN search
            if ((b?.result?.ABNAgeMonths ?? 0) > 12) {
                previousBusiness = null;
            }
        } else {
            previousBusiness = null;
        }
    }

    // work out legalName & entityTYpe
    let legalName = toInteflowLegalName(b) ?? undefined;
    let entityType = o ? toInteflowEntityTypes(o.type) : undefined;

    // work out industries
    const primaryIndustry: PrimaryIndustrySelectionValue = this.formControlStep3PrimaryIndustry.value;
    const secondaryIndustry: SecondaryIndustrySelectionValue = this.formControlStep3IndustrySector.value;
    const revenue = this.formControlStep3AnnualRevenue.value;
    const operateOnCommercialPremises: boolean = this.formControlStep3OperatesInCommercialPremise.value ?? false;
    const landline = this.formControlStep3BusinessLandline.value;
    const phoneNumber = landline ? landline.substring(2).trim() : undefined;
    const areaCode = landline ? landline.substring(0, 2).trim() : undefined;
    const address: Address2ComponentValue = this.formControlStep3Address.value;
    // const parsedAddress = address ? parseRawAddress(address.address) : null;
    console.log("Parsed Address::", address);

    // entities
    const primaryCommercialEntity: Partial<CommercialEntity> = {
      LegalName: legalName ?? undefined,
      EntityType: entityType,
      ABN: abn ?? undefined,
      ACN: _.isEmpty(acn) ? '0' : acn ?? undefined,
      TradingName: '',
      Type: 'Primary',
      PrimaryIndustry: primaryIndustry?.division ?? undefined,
      IndustrySector: secondaryIndustry?.code ?? undefined,
      Revenue: revenue ? Number(revenue) : undefined,
      OperateatCommercialAddressFlag: operateOnCommercialPremises,
      PhoneNumber: phoneNumber,
      AreaCode: areaCode,
      manualEntry: false,
      BusinessCategory: 'SME ($1m+ Revenue | $0.5m to $5m Capital)',
      PrinciplePlaceofBusiness: toInteflowAddress(address),
      ABNAgeMonths: (b && b.type === 'search-result' && b?.result) ?
      b.result.ABNAgeMonths :
      this.existingApplication?.CompanyDetails ? this.existingApplication.CompanyDetails.ABNAgeMonths : undefined
    };
    if(entityType!=='PTY'){
      delete primaryCommercialEntity.ACN
    }
    // commercial entities
    const otherCommercialEntities = fromApplicantToInteflowCommercialEntities(applicants);
    const guarantorCommercialEnttities = fromGuarantorToInteflowCommercialEnttities(guarantors);
    const otherCommercialEntity = [
      ...otherCommercialEntities,
      ...guarantorCommercialEnttities,
    ];

    // individuals
    const applicantIndividuals = fromApplicantToInteflowIndividualsData(applicants);
    let guarantorIndividuals = fromGuarantorToInteflowIndividuals(guarantors);

    // for trust application with only corporate trustee, the guarantors are guarantor/signer
    if (entityType === 'TRST' && !applicantIndividuals.length) { // no individual trustee
      guarantorIndividuals =  guarantorIndividuals.map(i => {
        return {
          ...i,
          SignerRole: 'GuarantorSigner'
        }
      })
    }

    const otherIndividuals = [
      ...applicantIndividuals,
      ...guarantorIndividuals,
    ];

    // reference
    const references =  fromReferenceToInteflowReferences(refValue);
    // pricing details
    // NOTE: changes done here needs to be done in asset-pricing.component.ts as well
    const loanTerm: LoanTermsSelectionWithInputValue = this.formControlStep2LoanTerms.value;
    const invoiceAmount = this.formControlStep2InvoiceAmount.value;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    const deposit = this.formControlStep2DepositAmount.value;
    const brokerage: BrokerageSelectionValue = this.formControlStep2Brokerage.value;
    const paymentPeriod: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const docFeeFinanced = this.formControlStep2DocFeeFinanced.value;
    const docFee = this.terms?.docFee ?? undefined;
    const brokerOriginationFee = this.formControlStep2BrokerOriginationFee.value;
    const propertyOwner = this.formControlStep2PropertyOwner.value;
    const previousLoan = this.formControlStep2PreviousLoan.value;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value;
    const equifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value;
    const rate = this.terms?.totalInterest ?? undefined;
    const balloonPercentage: BalloonPaymentValue = this.formControlStep2BalloonPayment.value;
    const balloonPayment = this.terms?.RV ?? undefined;
    const assetCondition: AssetConditionValue = this.formControlStep2AssetCondition.value;
    const repayment = this.calculator?.emiAmt ?? undefined;
    const transactionType: TransactionValue = this.formControlStep2TransactionType.value;
    const privateSales: PrivateSaleType = transactionType ? (
      transactionType.type === 'Dealer Sale' ? 'No' :
      (transactionType.type === 'Private Sale' ? 'Yes' : transactionType.type)
      ) : 'No';
    const assetPurpose: AssetPurposeComponentValue = this.formControlStep2AssetPurpose.value;

    // file tags
    const otherSupportingDocs: UploadFileValue = this.formControlStep9OtherSupportingDocuments.value;
    const driverLicencesUpload: DriverLicenceUpload2[] = this.step8DriverLicenses;
    const allToBeDeleted = [...this.step8DeletedUploadedDocs,...this.step9DeletedUploadedDocs].map(file => file.name);

    let allFiles: File[] = [];
    if (driverLicencesUpload && driverLicencesUpload.length) {
      const fileArr = driverLicencesUpload.map(upload => {
         if(upload.formControl.value) {
          return upload.formControl.value.map((f) => {
            const individual = otherIndividuals.find(obj => upload.name == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
            (f as any).tags = ['driverslicence'];
            (f as any).metadata = individual?.id ? {
              individualid: individual?.id,
              driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
              ...this.step9OtherDocMetadata
            } : this.step9OtherDocMetadata;
            return f;
          })
         }else {
          return []
         }
      });

      allFiles = fileArr.map((f: File[]) => f && f.length ? f[0] : null).filter((f: File | null) => !!f) as File[];
    }
    if (otherSupportingDocs && otherSupportingDocs.length) {
      allFiles = [...allFiles, ...otherSupportingDocs];
    }

    allFiles = [...allFiles].filter(file => !allToBeDeleted.includes(file?.name));

    // get doc types
    let uploadedTags = allFiles.reduce((tags: string[], file) => [...tags, ...((file as any).tags ?? [])], []);
    uploadedTags = [...uploadedTags, ...this.step8UploadedDocs.reduce((tags: string[], file: AzureStorageDocument) => [...tags, ...Object.values(file?.tags ?? {})], [])];
    uploadedTags = [...new Set(uploadedTags)];
    console.log('=====uploadedTags: ', uploadedTags);
    for (const tag of uploadedTags) {
      if (!this.documentTypes.map(t => t.value).includes(tag)) {
        const tagObj = (Object.values(constants.documentTypes) as DocumentTag[]).find(obj => obj.value === tag);
        if (tagObj) {
          this.documentTypes.push(tagObj);
        }
      }
    }
    console.log('====updated defaultTags: ', this.documentTypes);

    const newCarAssetType = constants.NewCarsAssetTypeIndex;


    const companyDetails = (b && b.type === 'search-result' && b?.result) ?
      b.result :
      this.existingApplication?.CompanyDetails ? this.existingApplication.CompanyDetails : undefined;

    const correspondentDetails = c


    let apiBodyPricingDetails: SaveApplicationPricingDetailsData = {
      LoanTerm: loanTerm ?? undefined,
      InvoiceAmount: (invoiceAmount != null ? (invoiceAmount) : undefined),
      Deposit: (deposit != null ? (deposit) : undefined),
      Brokerage: brokerage ? numeral(brokerage.type).value() ?? undefined : undefined,
      BrokerageAmount: this.calculator?.brokerageAmount ?? 0,
      LoanAmount: loanAmount,
      PaymentPeriod: paymentPeriod ? paymentPeriod.name : undefined,
      DocFee: (docFee != null ? Number(docFee) : undefined) ,
      DocFeeFinanced: booleanToYesNo(docFeeFinanced),
      BrokerOriginationFee: brokerOriginationFee ?? undefined,
      PropertyOwner: booleanToYesNo(propertyOwner),
      AdverseOnFile: booleanToYesNo(adverseOnFile),
      EquifaxScoreAbove600: booleanToYesNo(equifaxScore),
      Rate: rate,
      BalloonPaymentPercentage: balloonPercentage?.type ? Number(balloonPercentage.type) : undefined,
      BalloonPayment: balloonPayment,
      Repayment: repayment,
      BankStatementSubmitted: 'No' as const,
      PrivateSale: privateSales,
      AssetCondition: assetCondition?.type ?? undefined,
      AmountFinance: this.AmountFinance,
      TransactionType: transactionType ? transactionType.type : undefined,
      CreditRateAdjustment: 0, // when creating an application, creditRateAdjustment is 0
      LastUpdatedByUser: null, // when creating an application, lastUpdatedByUser is null
      RepaymentType: 'Advance'
    };
    if (!propertyOwner) {
      apiBodyPricingDetails = {
        ...apiBodyPricingDetails,
        PreviousLoan: booleanToYesNo(previousLoan)
      }
    }

    const apiBodyReferences = [
      ...references
    ]
    const otherIndividual  = [
      ...otherIndividuals
    ];
    const apiBodyCommercialEntities = [
      primaryCommercialEntity,
      ...otherCommercialEntity,
    ];
  const contacts = contact;
  let apiBodyIndividuals =  otherIndividual.map(individual => {
      if (
        individual.GivenName === contacts?.firstName &&
        individual.SurName === contacts?.lastName &&
        individual.Email === contacts?.email
      ) {
        return { ...individual, Contact: 'primaryContact' };
      }
      return { ...individual, Contact: 'contact' };
    });

    const apiBodyContact = fromContactToInteflowContacts(contact);

    const apiBodyAppInfo = {
      BrokerAppID: this.brokerApplicationId ?? undefined,
      IntroducerGroup: introducerGroup,
      // Disclosed: disclosed,
      FinanceType: financeType?.name ?? undefined,
      // UserId: user!.UserId,
      // CompanyId: user!.OriginatorBusinessId,
      UserEmail: user!.Email,
      UserFirstName: user!.GivenName,
      UserLastName: user!.FamilyName,
      Notes: applicationNotes ?? undefined,
      BusinessUse: booleanToYesNo(this.businessUseOnly),
      SelfServiceability:  booleanToYesNo(this.selfServiceability),
      PrivacyConfirmation: booleanToYesNo(this.formControlStep1_bPrivacyStatementCheck.value ?? null),
      BrokerflowDocumentID: brokerflowDocumentId ?? undefined,
      BrokerSalesforceID: brokerSalesforceId,
      BrokerAbn: brokerAbn,
      BrokerEntityName: brokerEntityName,
      AssetCategory: asset?.category.index ?? undefined,
      AssetType: asset?.type.index ?? undefined,
      ContactId: undefined,
      CustomerId: undefined,
      SalesforceId: undefined,
      // StageName: 'Draft',
      MetApplicant: userApplicant ? userApplicant.type : undefined,
      isSwitchToEasyDoc : this.isSwitchToEasyDoc,
      productType: this.productType
    };
    let apiBodyAssetSpec: UpdateApplicationData['AssetSpec'] = {
      make: asset?.make ?? undefined,
      family: asset?.family ?? undefined,
      year: asset?.year ?? undefined,
      vehicle: asset?.model ?? undefined,
      description: asset?.description ?? undefined,
      truckGrossVehicleWeight: asset?.truckGrossVehicleWeight ?? undefined,
      OtherCar: asset?.OtherCar ?? false,
      assetPurpose:assetPurpose?.assetPurpose,
      assetPurposeDescription:assetPurpose?.assetPurposeDescription,
      assetExistingFinanceCommitment:assetPurpose?.assetExistingFinanceCommitment
    };

    // work out vehicle asset spec.
    let assetLvr = undefined;
    let vehSpec = {};
    if (asset) {
      if ((isAssetVehicles(asset.category.index) && isAssetNewCars(asset.category.index, asset.type.index)) ||
        (isAssetTrucksOrTrailers(asset.category.index) && isAssetTypeLightOrHeavyTruck(asset.category.index, asset.type.index))) {
        if(this.terms) {
          assetLvr = this.terms.LVR;
          vehSpec = asset.vehicle ?? {};
        }
        apiBodyAssetSpec = {
          ...apiBodyAssetSpec,
          ...vehSpec,
          LVR: assetLvr ?? undefined,
        };
      }
    }

    // broker contact
    const brokerContact: SalesforceContactSameAccountSelectionValue = this.formControlStep1BrokerContact.value;

    const apiBody: UpdateApplicationData = {
      ApplicationType: 'AssetFinance',
      IP: this.ip,
      ApplicantLocation: this.geoLocation,
      UserId: this.applicationService.getApplicationUserId(this.existingApplication,this.user),
      CompanyId: this.applicationService.getApplicationCompanyId(this.existingApplication,this.user),
      AppInfo: apiBodyAppInfo,
      AssetSpec: apiBodyAssetSpec,
      Contacts: apiBodyContact,
      CommercialEntities: apiBodyCommercialEntities,
      Individuals: apiBodyIndividuals,
      Reference: apiBodyReferences,
      PricingDetails: apiBodyPricingDetails,
      AcceptQuotation: this.acceptedQuotation,
      ApplicationNotes: applicationNotes ?? undefined,
      CompanyDetails: companyDetails ?? undefined,
      AdditionalBrokerCorrespondent: correspondentDetails ?? null,
      BrokerContact: brokerContact,
      //Documents: apiBodyDocuments,
      DocumentTypes: this.documentTypes,
      PreviousBusiness: previousBusiness,
    }
    console.log('====apibody: ', apiBody);

    // console.log("Commercial Entities::",apiBodyCommercialEntities);
    // console.log(" Address::",this.formControlStep3Address.value)
    return apiBody;
  }


  step5GuarantorEvent($event: GuarantorComponentEvent) {
    /// $event.entries
  }

  private step5OnwardsApplicantsThatAreGuarantor(): IndividualGuarantor[] {
    const applicant: TrusteeValue | MemberValue | PartnerValue | SoleTraderValue | DirectorValue = this.formControlStep4Applicant.value;
    const guarantors = applicantsThatAreGuarantor(applicant);
    return guarantors;
  }

  private step5OnwardsIndividualGuarantors(): IndividualGuarantor[] {
    const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
    return individualGarantors(guarantors);
  }

  calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {
    const paymentFrequencyValue: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const assetConditionValue: AssetConditionValue = this.formControlStep2AssetCondition.value;
    const assetSelectionValue: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    const loanTermValue: LoanTermsSelectionWithInputValue = this.formControlStep2LoanTerms.value;
    const financeTypeValue: FinanceTypeValue = this.formControlStep2FinanceType.value;
    const balloonPaymentValue: BalloonPaymentValue = this.formControlStep2BalloonPayment.value;
    const brokerageValue: BrokerageSelectionValue = this.formControlStep2Brokerage.value;
    const businessSearchValue = this.formControlStep1Business.value;
    const businessSearchValueWithSearchResult = businessSearchValue?.type == 'search-result' ? businessSearchValue : null;
    const rst = calculateAssetFinanceEstimation(date, {
      type: 'AssetFinance',
      paymentFrequencyType: paymentFrequencyValue?.type ?? null,
      assetConditionType: assetConditionValue?.type ?? null,
      assetSelectionValue: assetSelectionValue!,
      assetYear: parseFloat(assetSelectionValue?.year ?? '0'),
      loanTerm : numeral(loanTermValue).value() ?? 0,
      financeType: financeTypeValue?.type ?? null,
      loanAmount: numeral(this.formControlStep2LoanAmount.value).value() ?? 0,
      businessSearchValue: businessSearchValueWithSearchResult,
      existingApplicationBureauReport: this.existingApplicationBureauReport,
      propertyOwnership: this.formControlStep2PropertyOwner.value ?? false,
      docFeeFinanced: this.formControlStep2DocFeeFinanced.value ?? false,
      brokerOriginationFee: this.formControlStep2BrokerOriginationFee.value ?? 0,
      brokerage: Number(brokerageValue?.type ?? 0),
      adverseOnFile: this.formControlStep2AdverseOnFile.value ?? false,
      equifaxScoreAbove600: this.formControlStep2EquifaxScoreAboveThreshold.value ?? false,
      privateSaleOrLeaseback: this.formControlStep2TransactionType.value?.type !== 'Dealer Sale',
      balloonPayment: numeral(balloonPaymentValue?.type).value() ?? 0,
      rateCard: this.rateCard,
      invoiceAmount: this.formControlStep2InvoiceAmount.value ?? 0,
      deposit: this.formControlStep2DepositAmount.value ?? 0,
      transactionType: this.formControlStep2TransactionType.value?.type,
    })
    console.log("calculateAssetFinanceEstimation rst", rst);
    this.calculator = rst.calculator;
    this.terms = rst.terms;
    this.step2Repayment = rst.repayment;

    this.AmountFinance = (rst.calculator.principalAmt + rst.calculator.brokerageAmount);

    return rst.totalPaymentBreakupDialogData;
  }

  private async step2ShowQuotation(stepper: CdkStepper) {
    this.step2EstimatedDrawdownDate = moment().add(1, 'day');

      this.step2Subscription1 = this.applicationDialogService.openAssetQuotationDialog({
        loadFn: (date) => {
          return this.calculateEstimation(date);
        },
        isQuote: false,
        paymentChartHideBalloonPayment: true,
        paymentChartHideBrokerOriginationFee: false,
        paymentChartHideBrokerage: false,
        showPaymentChart: true,
        showArmotizationChart: true,
        showRepaymentEstimation: true,
        showArmotisationSchedule: true,
      }).afterClosed()
        .pipe(
          tap(r => {
            if (r && r.acceptedQuotation) {
              this.acceptedQuotation = true;
              this.saveApplication(stepper, 2);
              // stepper.next();
              // const businessSearchResult = this.formControlStep1Business.value;
              // if (businessSearchResult && businessSearchResult.type === 'search-result' && this.applicationId) { // NOTE: we can deduce only when business is a ASIC/ACN search
              //   if (businessSearchResult.result?.entityType) {
              //     const description = businessSearchResult.result.entityType.entityDescription;
              //     const code = businessSearchResult.result.entityType.entityTypeCode;
              //     if ('IND' !== code && description && description.toLowerCase().indexOf('company') >= 0) {
              //       //company
              //       const abn = businessSearchResult.abn ?? null;
              //       const acn = patchAcn(businessSearchResult?.acn) ?? null;
              //       this.applicationService.runCreditorWatchFn({
              //         applicationId: this.applicationId,
              //         abn: this.formControlStep3Abn.value,
              //         acn: this.formControlStep3Acn.value,
              //       }).pipe(
              //         this.toastService.spinnerObservable()
              //       ).subscribe(async response => {
              //         const directors = response.directors;
              //         const companyAddress = response.companyAddress;
              //         this.formControlStep3Address.setValue(companyAddress);
              //         if (directors?.length) {
              //           // prefill
              //           this.applicationDialogService.openPrefillDirectorsDialog({
              //             directors: directors
              //           }).afterClosed().subscribe(async result => {
              //             await this.saveApplication(stepper, 2);
              //             if (result && result.readyForSubmission && result.selectedDirectors.length) {
              //               this.formControlStep4Applicant.setValue(result.selectedDirectors);
              //             }
              //           })
              //         } else {
              //           await this.saveApplication(stepper, 2);
              //         }
              //       })
              //     } else {
              //       this.saveApplication(stepper, 2);
              //     }
              //   } else {
              //     this.saveApplication(stepper, 2);
              //   }
              // } else {
              //   this.saveApplication(stepper, 2);
              // }


              // const organisationType = this.formControlStep3OrganisationType.value;
              // if (organisationType?.type === 'company' && !this.formControlStep4Applicant.value && this.applicationId) {
              //   this.applicationService.runCreditorWatchFn({
              //     applicationId: this.applicationId,
              //     abn: this.formControlStep3Abn.value,
              //     acn: this.formControlStep3Acn.value,
              //   }).pipe(
              //     this.toastService.spinnerObservable()
              //   ).subscribe(async directors => {
              //     if (directors?.length) {
              //       // prefill
              //       console.log('=directors: ', directors);
              //       this.applicationDialogService.openPrefillDirectorsDialog({
              //         directors: directors
              //       }).afterClosed().subscribe(async result => {
              //         await this.saveApplication(stepper, 3);
              //         if (result && result.readyForSubmission && result.selectedDirectors.length) {
              //           this.formControlStep4Applicant.setValue(result.selectedDirectors);
              //         }
              //       })
              //     } else {
              //       await this.saveApplication(stepper, 3);
              //     }
              //   })
              // } else {
              //   await this.saveApplication(stepper, 3);
              // }
            }
          })
        ).subscribe();
  }

  private async saveApplication(stepper: CdkStepper, step: number) {
    if (!this.applicationId) { // application was not saved before (new draft)
      const inteflowData = await this.mapToInteflowData();
      console.log('save application (asset) inteflowData', inteflowData);
      this.applicationService.saveApplication(inteflowData).pipe(
        // this.toastService.toastObserver('save draft'),
        tap(r => {
          console.log('save application (asset) log',r.payload.ApplicationId);
          const applicationId = r.payload.ApplicationId;
          const brokerApplicationId = r.payload.BrokerApplicationId;
          this.brokerApplicationId = brokerApplicationId;
          this.applicationId = applicationId;
          const business: BusinessSearchValue = this.formControlStep1Business.value
          if (business) {
            this.organisationName = business.organisationName;
          }
          this.localApplicationDbService.addOrUpdateApplicationLocally(toDraftSimplifiedApplication(this.applicationId, inteflowData));
          stepper.next();
          // prefill in step 2
          if (step === 2) {
            const businessSearchResult = this.formControlStep1Business.value;
            if (businessSearchResult && businessSearchResult.type === 'search-result' && this.applicationId && !this.formControlStep3Address.value) { // NOTE: we can deduce only when business is a ASIC/ACN search
              if (businessSearchResult.result?.entityType) {
                const description = businessSearchResult.result.entityType.entityDescription;
                const code = businessSearchResult.result.entityType.entityTypeCode;
                if ('IND' !== code && description && description.toLowerCase().indexOf('company') >= 0) {
                  //company
                  const abn = businessSearchResult.abn ?? null;
                  const acn = patchAcn(businessSearchResult?.acn) ?? null;
                  this.applicationService.runCreditorWatchFn({
                    applicationId: this.applicationId,
                    abn: abn,
                    acn: acn,
                  }).pipe(
                    this.toastService.spinnerObservable()
                  ).subscribe(async response => {
                    const directors = response.directors;
                    const companyAddress = response.companyAddress;
                    this.formControlStep3Address.setValue(companyAddress);
                    if (directors?.length) {
                      // prefill
                      this.applicationDialogService.openPrefillDirectorsDialog({
                        directors: directors
                      }).afterClosed().subscribe(async result => {
                        if (result && result.readyForSubmission && result.selectedDirectors.length) {
                          this.formControlStep4Applicant.setValue(result.selectedDirectors);
                        }
                      })
                    }
                  })
                }
              }
            }
          }
        })
      ).subscribe();
    } else {  // existing draft
      await this.updateDraftInBg(step);
      stepper.next();
    }
  }

  // NOTE: not used anymore
  // private step2CalculateTotalBrokerageAmount(brokerOrigniationFee: number, brokerage: BrokerageSelectionValue): number {
  //   // todo: calculate total brokerage amount
  //   return 0; // not used currently
  // }

  private async updateDraftInBg(step: number, fn?: ()=>void) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData();

      this.localApplicationDbService.addOrUpdateApplicationLocally(toDraftSimplifiedApplication(this.applicationId, data));
      await this.uploadFileToAzure(step, data);
      const sub = this.applicationService.updateApplication(this.applicationId, data).pipe(
        fn ? this.toastService.loadingWithMessage(`Saving, please be patient`): tap(r => {}),
        tap(r => {
          if (fn) {
            fn();
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }


  onStep8DeleteUploadedDoc($event: UploadedFilesListComponentEvent) {
    this.step8And9FilesUploadedToAzure = this.step8And9FilesUploadedToAzure.filter(file => {
      return !(file.metadata?.driverslicencename === $event.deletedFile?.metadata?.driverslicencename);
    });
    this.step8UploadedDocs = this.step8UploadedDocs.filter(file => {
      return !(
        (file.metadata?.driverslicencename === $event.deletedFile?.metadata?.driverslicencename) &&
        (file == $event.deletedFile)
      );
    });
    this.step8UploadedDrDoc = this.step8UploadedDrDoc.filter(file => {
      // Check if the file should be kept in the array
      return !(
        (file.metadata &&
         file.metadata.driverslicencename &&
         $event.deletedFile.metadata &&
         $event.deletedFile.metadata.driverslicencename &&
         $event.deletedFile.metadata.driverslicencename === file.metadata.driverslicencename) &&
        (file == $event.deletedFile)
      );
    });


    this.step8DeletedUploadedDocs = [...$event.deleted];
    const allThatRequireDriverLicences = this.getAllThatRequireDriverLicences();

    for (const requireDriverLicence of allThatRequireDriverLicences) {
      const id = `${_.lowerCase(requireDriverLicence.firstName)}${_.lowerCase(requireDriverLicence.middleName)}${_.lowerCase(requireDriverLicence.lastName)}`;
      const existingEntry = this.step8DriverLicenses.find(e => e.id === id);
      if (existingEntry && $event.deletedFile && $event.deletedFile.metadata && $event.deletedFile.metadata.driverslicencename && $event.deletedFile.metadata.driverslicencename === existingEntry.name) {
        const isDriverslicenceExist  = $event.existing.filter(arg =>  arg.metadata && arg.metadata.driverslicencename && arg.metadata.driverslicencename === existingEntry.name)
        if(isDriverslicenceExist.length === 0){
          existingEntry.formControl.addValidators([Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)])
          existingEntry.formControl.updateValueAndValidity();
        }
      }
    }
  }

  onStep9DeleteUploadedDoc($event: UploadedFilesListComponentEvent) {
    this.step8And9FilesUploadedToAzure = this.step8And9FilesUploadedToAzure
      .filter(file => file.metadata && !file.metadata.driverslicencename)
      .filter(file => {
        return !(file.name === $event.deletedFile.metadata?.name);
      });
    this.step9DeletedUploadedDocs = [...$event.deleted];
  }

  get step9OtherDocMetadata() {
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const abn = this.formControlStep3Abn.value ?? '';
    const legalName = toInteflowLegalName(b) ?? '';

    return {
      abn: abn,
      legalname: legalName
    };
  }

  getAllThatRequireDriverLicences() :IndividualGuarantor[] {
    const applicantsThatAreGuarantor: IndividualGuarantor[] = this.step5OnwardsApplicantsThatAreGuarantor();
    const guarantors: IndividualGuarantor[] = this.step5OnwardsIndividualGuarantors();
    return [...applicantsThatAreGuarantor, ...guarantors];
  }

  onStep9DocEvent(files: UploadAzureFiles) {
    if(files && files.length > 0) {
      this.formControlStep9OtherSupportingDocuments.setValue(files)
    } else {
      this.formControlStep9OtherSupportingDocuments.setValue(null)
    }
  }

  applicationDefaultDocuments(includeOptions = false): DocumentTag[] {
    const applicantsThatAreGuarantor: IndividualGuarantor[] = this.step5OnwardsApplicantsThatAreGuarantor();
    const guarantors: IndividualGuarantor[] = this.step5OnwardsIndividualGuarantors();
    const names = [...applicantsThatAreGuarantor, ...guarantors]
      .map(requireDriverLicence => `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`);
    return this.defaultDocuments(
      'AssetFinance',
      names,
      toInteflowLegalName(this.formControlStep1Business.value) ?? "",
      String((this.formControlStep2Asset.value as AssetSelectionComponentValue)?.category?.index),
      String((this.formControlStep2Asset.value as AssetSelectionComponentValue)?.type?.index),
      this.formControlStep2InvoiceAmount.value ?? 0,
      this.formControlStep2DepositAmount.value ?? 0,
      this.formControlStep2TransactionType.value?.type !== 'Dealer Sale',
      this.formControlStep3OrganisationType.value === EntityTypeTrustOption,
      this.formControlStep3OrganisationType.value === EntityTypePartnershipOption,
      includeOptions
    );
  }

  skipFileUploadValidation() {
    return (this.step8UploadedDocs && this.step8UploadedDocs.length > 0);
  }

  skipFileUploadValidationForName(name: string) {
    return (this.step8UploadedDocs && this.step8UploadedDocs.length > 0) &&
           (this.step8UploadedDocs.filter(file => {
              return (file.metadata?.driverslicencename === name);
            }).length > 0)
      ;
  }

  listUploadedDocuments() {
    if (this.applicationId) {
      this.subscriptions.push(
        this.applicationService.listApplicationDocumentFn(this.applicationId).subscribe(
          (r: AzureStorageDocument[]) => {
            const driverslicenceDoc  = r.filter(arg =>  arg.metadata && arg.metadata.driverslicencename)
            const supportDoc  = r.filter(arg =>  arg.metadata && !arg.metadata.driverslicencename)
            this.step8UploadedDocs = driverslicenceDoc;
            this.step9UploadedDocs = supportDoc;
            this.step8UploadedDrDoc  = driverslicenceDoc
            console.log("=====uploaded driverslicenceDoc docs: ", driverslicenceDoc);
            console.log("=====uploaded supportDoc docs: ", supportDoc);
          }
        )
      )
    }
  }
}
