import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {
  AccessLevel, SideMenuComponentFlatTreeNode, SideMenuComponentMenuType, SideMenuComponentTreeNode,
  SideMenuComponentTreeNodeType, isInternalUser,
  User, PortalLoginUser,
  DASHBOARD_ACCESS_LEVEL,
  OVERDRAFT_PRODUCT_ACCESS_LEVEL,
  APPLICATIONS_ACCESS_LEVEL,
  NEW_APPLICATION_ACCESS_LEVEL,
  CALCULATORS_ACCESS_LEVEL,
  ASSET_FINANCE_CALCULATOR_ACCESS_LEVEL,
  BUSINESS_TERM_LOAN_CALCULATOR_ACCESS_LEVEL,
  BUSINESS_OVERDRAFT_CALCULATOR_ACCESS_LEVEL,
  BUSINESS_AND_OVERDRAFT_BORROWING_ESTIMATOR_CALCULATOR_ACCESS_LEVEL,
  PISMO_ACCOUNTS_ACCESS_LEVEL,
  ADMIN_TAB_ACCESS_LEVELS,
  USERS_ACCESS_LEVEL,
  PENDING_APPROVALS_ACCESS_LEVEL,
  UNVERIFIED_USERS_ACCESS_LEVEL,
  CUSTOMERS_ACCESS_LEVEL,
  RATE_CARDS_ACCESS_LEVEL,
  WHITELABEL_SETTINGS_ACCESS_LEVEL,
  BANK_STATEMENT_CONFIGURATION_ACCESS_LEVEL,
  PROMOTION_ACCESS_LEVEL,
  OAUTH_ACCESS_LEVEL,
  SET_MAINTENANCE_MANAGEMENT_ACCESS_LEVEL,
  NOTIFICATIONS_MANAGEMENT_ACCESS_LEVEL,
  AUDIT_LOGS_ACCESS_LEVEL,
  PENDING_APPROVAL_TRANSACTIONS_ACCESS_LEVEL,
  TRANSACTIONS_ACCESS_LEVEL,
  COMPANIES_ACCESS_LEVEL,
  REPORTING_ACCESS_LEVEL,
  BANK_STATEMENTS_ACCESS_LEVEL,
  BANK_STATEMENT_ANALYSIS_ACCESS_LEVEL,
  ACCREDITATION_ACCESS_LEVEL,
  REDBOOK_ACCESS_LEVEL,
  DOCUMENT_WORKLIST_ACCESS_LEVEL,
  DOCUMENTS_ACCESS_LEVEL,
  CREDIT_MANAGEMENT_ACCESS_LEVEL,
  BANK_STATEMENT_STATUS_ACCESS_LEVEL, ChangePortalThemeFn, PortalThemeServiceEvent,
  HeartbeatEvent,

} from '@portal-workspace/grow-shared-library';
import {getUser, pxToRem} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Observable, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';
import {Priviledges} from '@portal-workspace/grow-shared-library';
import {ActivatedRoute, Data, Router} from '@angular/router';
import { environment } from 'apps/portal2/src/environments/environment';
import { MatBadgeModule } from '@angular/material/badge';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { PortalThemeComponent } from '../portal-theme-component/portal-theme.component';
import { get } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';

const TREE_NODES: SideMenuComponentTreeNode[] = [
  { name: 'Dashboard', icon: 'mdi-chart-box-outline', type: 'dashboard', accessLevels: DASHBOARD_ACCESS_LEVEL, children: []},
  { name: 'Overdraft Product', icon: 'mdi-calculator', type: 'overdraft-product', accessLevels: OVERDRAFT_PRODUCT_ACCESS_LEVEL, children: []},
  { name: 'Applications', icon: 'mdi-view-grid-outline', type: 'applications', accessLevels: APPLICATIONS_ACCESS_LEVEL, children: []},
  { name: 'New Application', type: 'new-application', icon: 'mdi-view-grid-plus-outline', accessLevels: NEW_APPLICATION_ACCESS_LEVEL, children: []},
  { name: 'Calculators', type: 'calculator', icon: 'mdi-calculator-variant-outline', accessLevels: CALCULATORS_ACCESS_LEVEL, children: [
      { name: 'Asset Finance', icon: '', type: 'asset-finance-calculator', accessLevels: ASSET_FINANCE_CALCULATOR_ACCESS_LEVEL, children: []},
      { name: 'Business Term Loan', icon: '', type: 'business-loans-calculator', accessLevels: BUSINESS_TERM_LOAN_CALCULATOR_ACCESS_LEVEL, children: []},
      { name: 'Business Overdraft', icon: '', type: 'business-overdraft-calculator', accessLevels:  BUSINESS_OVERDRAFT_CALCULATOR_ACCESS_LEVEL, children: []},
      { name: 'Borrowing Estimator', icon: '', type: 'business-and-overdraft-borrowing-estimator', accessLevels:  BUSINESS_AND_OVERDRAFT_BORROWING_ESTIMATOR_CALCULATOR_ACCESS_LEVEL, children: []},
    ]},
  { name: 'Pismo Accounts', type: 'pismo-accounts', icon: 'mdi-car-pickup', accessLevels: PISMO_ACCOUNTS_ACCESS_LEVEL, children: [] },
  { name: 'Bank Statements', type: 'bank-statements', icon: 'mdi-bank-outline', accessLevels: BANK_STATEMENTS_ACCESS_LEVEL, children: [
    { name: 'Analysis', icon: '', type: 'bank-statements-analysis', accessLevels:  BANK_STATEMENT_ANALYSIS_ACCESS_LEVEL, children: []},
    { name: 'Status', icon: '', type: 'bank-statements-status', accessLevels:  BANK_STATEMENT_STATUS_ACCESS_LEVEL, children: []},
  ]},
  { name: 'Documents', type: 'documents', icon: 'mdi-file-document-multiple-outline', accessLevels: DOCUMENTS_ACCESS_LEVEL, children: [] },
  { name: 'Document Worklist', type: 'document-worklist', icon: 'mdi-format-list-bulleted', accessLevels: DOCUMENT_WORKLIST_ACCESS_LEVEL, children: []},
  { name: 'Redbook', type: 'redbook', icon: 'mdi-car-back', accessLevels: REDBOOK_ACCESS_LEVEL, children: []},
  { name: 'Accreditation', type: 'accreditation', icon: 'mdi-file-settings-outline', accessLevels: ACCREDITATION_ACCESS_LEVEL, children: [] },

];

const ADMIN_TREE_NODES: SideMenuComponentTreeNode[] = [
  { name: 'Admin', type: 'admin', icon: 'mdi-security', accessLevels: ADMIN_TAB_ACCESS_LEVELS, children: [
    { name: 'Users', type: 'users', accessLevels: USERS_ACCESS_LEVEL, children: []},
    { name: 'Pending Approvals', type: 'pending-approvals', accessLevels: PENDING_APPROVALS_ACCESS_LEVEL, children: []},
    { name: 'Unverified Users', type: 'unverified-users', accessLevels: UNVERIFIED_USERS_ACCESS_LEVEL, children: []},
    { name: 'Rate Card', type: 'rate-cards', accessLevels: RATE_CARDS_ACCESS_LEVEL, children: []},
    { name: 'Customers', type: 'customers', accessLevels: CUSTOMERS_ACCESS_LEVEL, children: []},
    { name: 'Companies', type: 'companies', accessLevels: COMPANIES_ACCESS_LEVEL, children: []},
    { name: 'Reporting', type: 'reporting', accessLevels: REPORTING_ACCESS_LEVEL, children: []},
    { name: 'Transactions', type: 'transactions', accessLevels: TRANSACTIONS_ACCESS_LEVEL, children: []},
    { name: 'Approval Transactions', type: 'pending-approval-transactions', accessLevels: PENDING_APPROVAL_TRANSACTIONS_ACCESS_LEVEL, children: []},
    { name: 'Audit Logs', type: 'audit-logs', accessLevels: AUDIT_LOGS_ACCESS_LEVEL, children: [] },
    { name: 'Notifications', type: 'notifications-management', accessLevels: NOTIFICATIONS_MANAGEMENT_ACCESS_LEVEL, children: []},
    { name: 'Set Maintenance', type: 'set-maintenance', accessLevels: SET_MAINTENANCE_MANAGEMENT_ACCESS_LEVEL, children: []},
    { name: 'Oauth', type: 'oauth', accessLevels: OAUTH_ACCESS_LEVEL, children: []},
    { name: 'Promotion', type: 'promotion', accessLevels: PROMOTION_ACCESS_LEVEL, children: []},
    { name: 'Bank Statement Configuration', type: 'bsa-settings', accessLevels: BANK_STATEMENT_CONFIGURATION_ACCESS_LEVEL, children: []},
    { name: 'Credit', type: 'credit-management', accessLevels: CREDIT_MANAGEMENT_ACCESS_LEVEL, children: []},
    { name: 'Whitelabel', type: 'whitelabel-settings', accessLevels: WHITELABEL_SETTINGS_ACCESS_LEVEL, children: []},
  ]},
];


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'sidemenu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    standalone: true,
    imports: [
      FlexModule,
      NgClass,
      ExtendedModule,
      MatBadgeModule,
      MatTreeModule,
      PortalThemeComponent,
    ]
})
export class SideMenuComponent implements OnInit {
  ADMIN_TAB_ACCESS_LEVELS = ADMIN_TAB_ACCESS_LEVELS;

  @Input({required: true}) unreadNotificationsCountObservable?: Observable<number>;
  @Input({required: false}) user: PortalLoginUser | null = null;
  @Output() events: EventEmitter<SideMenuComponentFlatTreeNode>;
  @Output() menuTypeEvent: EventEmitter<SideMenuComponentMenuType>;
  @Input({required: true}) changePortalThemeFn!: ChangePortalThemeFn;
  @Input({required: true}) portalThemeObservable!: Observable<PortalThemeServiceEvent>;
  @Input({required: true}) heartbeatObservable!: Observable<HeartbeatEvent>;


  subscriptions: Subscription[] = [];

  unreadNotificationsCount = 0;

  menuItemId: SideMenuComponentTreeNodeType | SideMenuComponentMenuType | '' = '';

  pxToRem = pxToRem;
  isInternalUser = isInternalUser;

  // product tree
  treeDataSource: MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;
  treeControl: FlatTreeControl<SideMenuComponentFlatTreeNode>;
  treeFlattener: MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;

  // admin tree
  treeDataSource2: MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;
  treeControl2: FlatTreeControl<SideMenuComponentFlatTreeNode>;
  treeFlattener2: MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.events = new EventEmitter();
    this.menuTypeEvent = new EventEmitter<SideMenuComponentMenuType>();

    // product tree
    this.treeFlattener = new MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(
      (treeNode: SideMenuComponentTreeNode, level: number) => ({
        expandable: !!treeNode.children && treeNode.children.length > 0,
        level,
        treeNode
      }),
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.level,
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.expandable,
      (treeNode: SideMenuComponentTreeNode) => treeNode.children);
    this.treeControl = new FlatTreeControl(node => node?.level ?? 0, node => node?.expandable ?? true);
    this.treeDataSource = new MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(this.treeControl, this.treeFlattener, TREE_NODES);

    // admin tree
    this.treeFlattener2 = new MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(
      (treeNode: SideMenuComponentTreeNode, level: number) => ({
        expandable: !!treeNode.children && treeNode.children.length > 0,
        level,
        treeNode
      }),
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.level,
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.expandable,
      (treeNode: SideMenuComponentTreeNode) => treeNode.children);
    this.treeControl2 = new FlatTreeControl(node => node.level, node => node.expandable);
    this.treeDataSource2 = new MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(this.treeControl2, this.treeFlattener2, ADMIN_TREE_NODES);
  }

  hasAccessLevels(requiredAccessLevels: Priviledges): boolean {
    if (this.user && this.user.priviledges) {
      if (this.user.priviledges.includes('lg')) {
        // lg user
        return requiredAccessLevels.some((r ) => r.includes('lg'));
      } else {
        // broker
        return requiredAccessLevels.some((r ) => this.user?.priviledges.includes(r))
      }
    }
    return false;
  }

  readonly flatTreeNodeHasNoChild = (level: number, flatTreeNode: SideMenuComponentFlatTreeNode) => {
    return (!flatTreeNode.expandable);
  }

  readonly flatTreeNodeHasChild = (level: number, flatTreeNode: SideMenuComponentFlatTreeNode) => {
    return (flatTreeNode.expandable);
  }

  ngOnInit(): void {
    console.log('environment', environment.production)
    setupUntilDestroy(this);

    // get current menuItems
    const routeSplits = this.router.url.split('/');
    if (routeSplits && routeSplits.length >= 3) {
      this.menuItemId = routeSplits[2] == 'application' ? 'applications' : routeSplits[2] as any;
      const index1 = this.getExpandableTreeNodeIndexFromTree1();
      if (index1 !== -1) {
        this.treeControl.expand(this.treeControl.dataNodes[index1]);
      }
      const index2 = this.getExpandableTreeNodeIndexFromTree2();
      if (index2 !== -1) {
        this.treeControl2.expand(this.treeControl2.dataNodes[index2]);
      }
    }

    if (this.unreadNotificationsCountObservable) {
      const sub = this.unreadNotificationsCountObservable.pipe(
        tap(r => {
          if (r) {
            this.unreadNotificationsCount = r;
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }

    if (this.heartbeatObservable) {
      this.subscriptions.push(this.heartbeatObservable.pipe(
        tap(r => {
          if (r) {
            switch(r.type) {
              case 'HeartbeatAccessLevelChangedEvent':
                this.user = getUser();
                this.changeDetectorRef.detectChanges();
                break;
              case 'HeartbeatUserDisabledEvent':
                break;
            }
          }
        })
      ).subscribe());
    }
  }

  onTreeMenuClick($event: MouseEvent, flatTreeNode: SideMenuComponentFlatTreeNode) {
    if (flatTreeNode.treeNode.type) {
      this.menuItemId = flatTreeNode.treeNode.type;
      this.events.emit(flatTreeNode);
    }
  }

  onMenuClick($event: Event, type: SideMenuComponentMenuType) {
    this.menuItemId = type;
    this.menuTypeEvent.emit(type)
  }

  isExpandableNodeActivated(type: SideMenuComponentTreeNode['type']) {
    switch(type) {
      case 'calculator':
        return [
          'asset-finance-calculator',
          'business-loans-calculator',
          'business-overdraft-calculator',
          'business-and-overdraft-borrowing-estimator',
        ].includes(this.menuItemId);
      case 'bank-statements':
        return [
          'bank-statements-analysis',
          'bank-statements-status',
        ].includes(this.menuItemId);
      case 'document-worklist':
        return [
          'document-worklist',
          'credit-worklist',
          'client-services-worklist',
          'settlements-worklist',
        ].includes(this.menuItemId);
      case 'admin':
        return [
          'users',
          'pending-approvals',
          'unverified-users',
          'rate-cards',
          'customers',
          'companies',
          'reporting',
          'transactions',
          'pending-approval-transactions',
          'audit-logs',
          'notifications-management',
          'set-maintenance',
          'oauth',
          'promotion',
          'bsa-settings',
          'credit-management',
          'whitelabel-settings',
        ].includes(this.menuItemId);
    }
    return false;
  }

  getExpandableTreeNodeIndexFromTree1() {
    if ([
        'asset-finance-calculator',
        'business-loans-calculator',
        'business-overdraft-calculator',
        'business-and-overdraft-borrowing-estimator',
      ].includes(this.menuItemId)) {
      return 3;
    } else if ([
        'bank-statement-analyst',
        'bank-statement-status',
      ].includes(this.menuItemId)) {
      return 5;
    }
    return -1;
  }

  getExpandableTreeNodeIndexFromTree2() {
    if ([
      'users',
      'pending-approvals',
      'unverified-users',
      'rate-cards',
      'customers',
      'companies',
      'reporting',
      'transations',
      'audit-logs',
      'notifications-management',
      'set-maintenance',
      'oauth',
      'promotion',  
      'credit-management',    
      'bsa-settings',
      'whitelabel-settings',
    ].includes(this.menuItemId)) {
      return 0;
    }
    return -1;
  }

}
