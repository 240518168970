<div class="accreditatioin-credit-component">
  <div class="section">
    <div class="row">
      <div class="col-12"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-between start"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <div class="mat-h3">Result</div>
        @if (!creditFlowResult.length) {
          <button mat-flat-button class="active w-lt-md-100" (click)="runCreditFlow()">Run Credit Flow</button>
        }
        @else {
          <button mat-flat-button class="active w-lt-md-100" (click)="runCreditFlow()">Rerun Credit Flow</button>
        }
      </div>
    </div>
    @if (!creditFlowResult.length) {
      <div class="row mb-4">
        <div class="col-lg-6 col-md-12">
          <message-box fxFlex="grow" type="warn">Credit flow has not started</message-box>
        </div>
      </div>
    }
    @if (creditFlowResult.length && !(creditFlowResult[creditFlowResult.length - 1].completeFlow)) {
      <div class="row mb-4">
        <div class="col-lg-6 col-md-12">
          <message-box fxFlex="grow" type="info">Credit flow was not completed</message-box>
        </div>
      </div>
    }
    @if (creditFlowResult.length && creditFlowResult[creditFlowResult.length - 1].completeFlow) {
      <div class="row mb-4">
        <div class="col-lg-6 col-md-12">
          <message-box fxFlex="grow" type="success">Credit flow has been completed</message-box>
        </div>
      </div>
    }
    <div class="row mb-2 result-item">
      <div class="col-lg-3 col-md-12">
        <div class="title">System Decision: </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start center">
          <tag-box [state]="accreditation.SystemCreditStatus"></tag-box>
          <div>{{creditFlowResult.length ? ((creditFlowResult[creditFlowResult.length - 1].lastUpdatedTime) | date:'dd/MM/yyyy h:mm a') : ''}}</div>
        </div>
      </div>
    </div>
    @if (creditFlowResult.length && !(creditFlowResult[creditFlowResult.length - 1].completeFlow)) {
      <div class="row mb-2 result-item">
        <div class="col-lg-3 col-md-12">
          <div class="title">Resume Credit Flow</div>
        </div>
        <div class="col-lg-6 col-md-12">
          <span class="mdi mdi-refresh cursor-pointer" (click)="resumeCreditFlow()"></span>
        </div>
      </div>
    }
    <div class="row mb-2 result-item">
      <div class="col-lg-3 col-md-12">
        <div class="title">Credit Officer Decision: </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <tag-box [state]="accreditation.CreditOfficerStatus"></tag-box>
      </div>
    </div>
    <div class="row mb-2 result-item">
      <div class="col-lg-3 col-md-12">
        <div class="title">Credit Officer: </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div>{{creditOfficer}}</div>
      </div>
    </div>

    <mat-divider [inset]="true" class="mb-4 mt-4"></mat-divider>

    <div class="row mb-2">
      <div class="col-12">
        <div class="mat-h3">Alerts</div>
      </div>
    </div>

    <accreditation-alerts
      [creditFlowResult]="creditFlowResult"
      [accreditation]="accreditation"
    ></accreditation-alerts>


    <mat-divider [inset]="true" class="mb-4"></mat-divider>

    <div class="row">
      <div class="col">
        <div class="mat-h3">Services</div>
      </div>
    </div>

    <accreditation-web-services
      [apiUrl]="apiUrl"
      [data]="webServices"
      [accreditation]="accreditation"
      [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
    ></accreditation-web-services>


    <mat-divider [inset]="true" class="mb-4"></mat-divider>

    <div class="row">
      <div class="col">
        <div class="mat-h3">Actions</div>
      </div>
    </div>
    <div class="row mb-4">
        <div class="col-12"
          fxLayout="row wrap"
          fxLayoutGap="10px"
          fxLayoutAlign="start start"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="onApprove()">Approve</button>
          <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="onDecline()">Decline</button>
        </div>
      </div>

      <mat-divider [inset]="true" class="mb-4 mt-4"></mat-divider>

      <div class="row mb-2 mt-4">
        <div class="col-12">
          <div class="mat-h3">Other Information</div>
        </div>
      </div>
    
      <div class="row mb-2 mt-4">
        <div class="col-12">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Credit Flow Details
                </mat-panel-title>
              </mat-expansion-panel-header>
              <credit-flow-details
                [creditFlowResult]="creditFlowResult"
              ></credit-flow-details>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
  </div>
</div>
