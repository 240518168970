<div class="data-box-component" [ngClass]="{'cursor-pointer': clickable, 'selected': selected }">
  @if (icon) {
    <span class="mdi mdi-{{icon}}"></span>
  }
  @if (title) {
    <span class="title">{{title}}</span>
  }
  @if (data) {
    <span class="content {{type}}">{{data}}</span>
  }
</div>
