import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GetUserFn, InsurancePremiumApplication, InsurancePremiumCalculatorData, WithdrawApplicationFn, getAppInfoBrokerEntityName, getBrokerContact, getOpportunityOwnerEmail, getOrganisationTypeForTitle} from '@portal-workspace/grow-shared-library';
import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes,
  getAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetMake,
  getAssetType,
  getAssetYear,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName,
  getBrokerSalesforceId,
  getAppSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLimitRequest,
  getLoanAmount,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType,
  getPrimaryBusinessAddress,
  getApplicationPrimaryIndustry,
  getPrivateSales,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  getTotalAmount,
  getDocFee,
  getRepayment,
  getPaymentPeriod,
} from '@portal-workspace/grow-shared-library';
import {getApplicationStatus, } from '@portal-workspace/grow-shared-library';
import {isInternalUser as utilIsInternalUser,} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService, GetUsersFunc, PortalHotToastService, UserSelectionComponentEvent, getUser} from '@portal-workspace/grow-ui-library';
import { LooseCurrencyPipe } from '../../../pipes/loose-currency.pipe';
import { PoliciesDetailsComponent } from '../policies-details.component';
import { PrimaryContactDetailsComponent } from '../primary-contact-details.component';
import { AuthorisedSignatoriesDetailsComponent } from '../authorised-signatories-details.component';
import { IndividualDetailsComponent } from '../individual-details.component';
import { RiskAnalysisComponent } from '../../risk-analysis-component/risk-analysis.component';
import { MatDividerModule } from '@angular/material/divider';
import { UserSelectionComponent } from '../../user-selection-component/user-selection.component';
import { CellComponent } from '../../application-summary-component/cell.component';
import { StatusBoxComponent } from '../../message-box/status-box.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout/flex';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'insurance-app',
    templateUrl: './insurance-app.component.html',
    styleUrls: ['./insurance-app.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, FlexModule, MatButtonModule, NgTemplateOutlet, StatusBoxComponent, CellComponent, UserSelectionComponent, MatDividerModule, RiskAnalysisComponent, IndividualDetailsComponent, AuthorisedSignatoriesDetailsComponent, PrimaryContactDetailsComponent, PoliciesDetailsComponent, LooseCurrencyPipe]
})
export class InsuranceAppComponent implements OnInit {

  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getAppSalesforceId = getAppSalesforceId;
  getBrokerName = getBrokerName;
  getAppInfoBrokerEntityName = getAppInfoBrokerEntityName;
  getOrganisationType = getOrganisationType;
  getOrganisationTypeForTitle = getOrganisationTypeForTitle;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getAssetCategory = getAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getPrivateSales = getPrivateSales;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getApplicationPrimaryIndustry = getApplicationPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  getLimitRequest = getLimitRequest;
  getTotalAmount = getTotalAmount;
  getDocFee = getDocFee;
  getRepayment = getRepayment;
  getPaymentPeriod = getPaymentPeriod;
  getBrokerContact = getBrokerContact;
  getOpportunityOwnerEmail = getOpportunityOwnerEmail

  @Input({required: true}) application!: InsurancePremiumApplication;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: true}) data!: InsurancePremiumCalculatorData;
  @Input({required: true}) withdrawApplicationFn!: WithdrawApplicationFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();
  @Output() navigateToApplications = new EventEmitter();

  applicationStatus: string | null = null;
  subscriptions: Subscription[] = [];
  applicationUserId!:number;
  AdditionalBrokerCorrespondentUserId!:number;
  creditOfficer = '';
  constructor(private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.applicationStatus = getApplicationStatus(this.application);
    this.applicationUserId= (this.activatedRoute.snapshot.data as any).application.UserId;
    this.AdditionalBrokerCorrespondentUserId = (this.activatedRoute.snapshot.data as any).application.AdditionalBrokerCorrespondent.UserId;
    if (this.application.CreditOfficerStatusUpdatedBy) {
      this.subscriptions.push(
        this.getUserFn(this.application.CreditOfficerStatusUpdatedBy, true).subscribe(r => {
          if (r) {
            this.creditOfficer = `${r.GivenName} ${r.FamilyName}`;
          }
        })
      )
    } else if (this.application.SystemCreditStatus) {
      this.creditOfficer = `System`;
    }
  }

  isInternalUser() {
    return utilIsInternalUser(getUser())
  }

  emitChangeSubmitterEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'submitter',
    });
  }

  emitChangeAdditionalCorrespondentEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'additional-correspondent',
    });
  }

  onWithdrawApplication() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Withdraw Application",
        subMessage: "Are you sure you want to withdraw this application?"
      }).afterClosed().subscribe(r => {
        if (r && r.readyForSubmission) {
          this.applicationDialogService.openWithdrawApplicationDialog({})
            .afterClosed().subscribe(result => {
              if (result && result.readyForSubmission) {
                this.withdrawApplicationFn({
                  salesforceId: this.application.AppInfoSalesforceID ?? '',
                  applicationId: this.application.ApplicationId,
                  reason: result.reason,
                  comment: result.comment,
                }).pipe(
                  this.toastService.spinnerObservable()
                ).subscribe(() => {
                  this.applicationDialogService.successDialog({
                    message: 'Success',
                    subMessage: `This application has been withdrawn successfully.`
                  }).afterClosed().subscribe(() => {
                    this.navigateToApplications.emit();
                  })
                })
              }
            })
        }
      })
    )
  }
}
