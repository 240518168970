
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { AsyncPipe, JsonPipe, NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { createAsyncStore, loadingFor } from '@ngneat/loadoff';
import {
  Application,
  ApplicationSort, ApplicationTypes,
  ConfirmationDialogResult,
  CopyApplicationDialogResult, CopyApplicationFn, DEFAULT_LIMIT, DEFAULT_OFFSET, displayApplicationDateTime, GetGeoLocationFn, isAssociatedWithAggregator, isInternalUser, PortalLoginUser,
  PromotionResponse, SaveApplicationResult, SimplifiedApplication, StageNameType, User
} from '@portal-workspace/grow-shared-library';
import { ApplicationDialogService, ApplicationTypeIconComponent, CustomContentLoaderComponent, CustomPaginatorComponent, DataBoxComponent, getUser, MessageBoxComponent, MessageBoxComponentEvent, PortalHotToastService, SearchableSelectModule, setupUntilDestroy, TagBoxComponent, TopMenuService } from '@portal-workspace/grow-ui-library';
import { SearchableSelectItem } from 'libs/grow-ui-library/src/lib/components/searchable-select-component/searchable-select.component';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AdminService } from '../../service/admin.service';
import { ApplicationService, } from '../../service/application.service';
import { HeartbeatService } from '../../service/heartbeat.service';
import { CreditOfficer, LocalApplicationsDbService, OpportunityOwner, SettlementOfficer } from '../../service/local-applications-db.service';
import { navigationUrlForAllApplication,
  navigationUrlForApplicationWithQueryParams,
  navigationUrlForAssetProductSelectorWithQueryParams,
  navigationUrlForBusinessFinanceWithQueryParams,
  navigationUrlForBusinessOverdraftWithQueryParams,
  navigationUrlForCommercialFinanceWithQueryParams,
  navigationUrlForConsumerAssetFinanceWithQueryParams,
  navigationUrlForConsumerFinance,
  navigationUrlForConsumerFinanceWithQueryParams,
  navigationUrlForCorporateLoanWithQueryParams,
  navigationUrlForInsurancePremiumWithQueryParams
} from '../../service/navigation-urls';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy } from '@ngneat/until-destroy';


export class InternalDataSource extends DataSource<SimplifiedApplication> {

  subject: BehaviorSubject<SimplifiedApplication[]> = new BehaviorSubject<SimplifiedApplication[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<SimplifiedApplication[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(applications: SimplifiedApplication[]) {
    this.subject.next(applications);
  }
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './applications.page.html',
    styleUrls: ['./applications.page.scss'],
    standalone: true,
    imports: [
      MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MessageBoxComponent, 
      MatIconModule, MatMenuModule, FlexModule, NgClass, CustomContentLoaderComponent, DataBoxComponent, 
      MatFormFieldModule, JsonPipe, MatInputModule, MatSelectModule, MatOptionModule, ApplicationTypeIconComponent, 
      MatTooltipModule, MatTableModule, MatSortModule, TagBoxComponent, CustomPaginatorComponent, AsyncPipe, 
      SearchableSelectModule, MatProgressBarModule, MatCardModule
    ]
})
export class ApplicationsPage implements OnInit, AfterViewInit, OnDestroy {
  STAGE_NAMES_UNDER_REVIEW: StageNameType[] = ['Submitted to Credit', 'Waiting for Bank Statements', 'More Information'];
  STAGE_NAMES_IN_SETTLEMENT: StageNameType[] = ['Docs Out', 'Settlement Pending', 'QA', 'Settlement'];
  STAGE_NAMES_CLOSED_WON: StageNameType[] = ['Closed Won'];
  STAGE_NAMES_ALL: StageNameType[] = [
    'Application Received', 'Closed Lost', 'Closed Won', 'Credit Approved', 'Docs Out', 'Documentation', 'Draft', 'Lead', 
    'More Information', 'Proposal', 'Prospect', 'QA', 'Settlement', 'Settlement Pending', 'Submitted to Credit', 'Waiting for Bank Statements'
  ];

  subscriptions: Subscription[] = [];
  loader = loadingFor('tableLoading');
  displayColumns = ['appId', 'appName', 'status', 'stage', 'actions'];

  totalApplicationsUnderReview = 0;
  totalApplicationsInSettlement = 0;
  totalApplicationsClosedWon = 0;
  isProgressBarVisible: boolean = false;
  lastUpdatedMessage = '';
  limit: number = DEFAULT_LIMIT;
  offset: number = DEFAULT_OFFSET;
  total: number = 0;
  sorts?: ApplicationSort = undefined;

  application!: Application;
  getGeoLocationFn!: GetGeoLocationFn;
  copyApplicationFn!: CopyApplicationFn;
  isInternalUser = isInternalUser;

  loggedInUser: User | null = getUser();
  ip: string = '';

  user: PortalLoginUser | null = null;
  store = createAsyncStore();
  errorTitle = 'Error Occurred!'
  errorMessage = 'Please try again.'

  promo ?: PromotionResponse
  showFilters = false;
  selectedFiltersCount = 0;
  
  opportunityOwners: SearchableSelectItem[] = [];
  creditOfficers: SearchableSelectItem[] = [];
  settlementOfficers: SearchableSelectItem[] = [];
  applicationStages: SearchableSelectItem[] = this.STAGE_NAMES_ALL.map(stage => ({ id: stage, label: stage, value: stage }));
  applicationTypes: SearchableSelectItem[] = [
    { id: 'AssetFinance', label: 'Asset Finance', value: 'AssetFinance' },
    { id: 'BusinessLoans', label: 'Business Term Loan', value: 'BusinessLoans' },
    { id: 'BusinessOverdraft', label: 'Business Overdraft', value: 'BusinessOverdraft' },
    { id: 'InsurancePremium', label: 'Insurance Premium', value: 'InsurancePremium' },
    { id: 'Consumer', label: 'Consumer Asset Finance', value: 'Consumer' },
    { id: 'CorporateLoans', label: 'Corporate Loan', value: 'CorporateLoans' }
  ];
  
  searchControl = new FormControl<string>('');
  applicationTypeControl = new FormControl<ApplicationTypes[]>([]);
  applicationStageControl = new FormControl<StageNameType[]>([]);
  filterOpportunityOwnerControl = new FormControl<string[]>([]);
  filterCreditOfficerControl = new FormControl<string[]>([]);
  filterSettlementOfficerControl = new FormControl<string[]>([]);
  
  formGroup = new FormGroup({
    searchControl: this.searchControl,
    applicationTypeControl: this.applicationTypeControl,
    applicationStageControl: this.applicationStageControl,
    filterOpportunityOwnerControl: this.filterOpportunityOwnerControl,
    filterCreditOfficerControl: this.filterCreditOfficerControl,
    filterSettlementOfficerControl: this.filterSettlementOfficerControl
  });

  retry() {
    this.reload();
  }

  dataSource = new InternalDataSource();

  constructor(private applicationService: ApplicationService,
    private portalHotToastService: PortalHotToastService,
    private localApplicationDbService: LocalApplicationsDbService,
    private adminService :AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private topMenuService: TopMenuService,
    private heartbeatService: HeartbeatService,
    private changeDetectorRef: ChangeDetectorRef) {
      this.getGeoLocationFn = this.applicationService.getGeoLocationFn;
      this.copyApplicationFn = this.applicationService.copyApplicationFn;
  }

  private resetPagination() {
    this.offset = DEFAULT_OFFSET;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.getLastUpdatedMessage();

    this.reloadPromos();
    this.user = getUser();

    this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
        }
      })
    ).subscribe();

    this.subscriptions.push(this.heartbeatService.subject.pipe(
      tap(r => {
        console.log('heartbeat event (applications page)', r);
        if (r.type === 'HeartbeatAccessLevelChangedEvent') {
          this.user = getUser();
          this.refresh();
          this.changeDetectorRef.detectChanges();
        }
      })
    ).subscribe());

    this.subscriptions.push(this._applicationService().dataUpdates$.subscribe(r => {
      this.totalApplicationsUnderReview = r.totalApplicationsUnderReview;
      this.totalApplicationsInSettlement = r.totalApplicationsInSettlement;
      this.totalApplicationsClosedWon = r.totalApplicationsClosedWon;
      this.limit = r.limit;
      this.offset = r.offset;
      this.total = r.total;
      this.dataSource.update(r.applications);
      this.isProgressBarVisible = false;
    }));

    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      distinctUntilChanged(),
      tap(r => {
        this.selectedFiltersCount = Object.values(r).filter(v => (v?.length ?? 0) > 0).length;
        this.refresh(false);
      })
    ).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const forceReload = queryParams.reload === 'true';
    const limit = isNaN(_.toNumber(queryParams.limit)) ? DEFAULT_LIMIT : _.toNumber(queryParams.limit);
    const offset = isNaN(_.toNumber(queryParams.offset)) ? DEFAULT_OFFSET : _.toNumber(queryParams.offset);
    const search = queryParams.search ?? '';
    const filterOpportunityOwner = queryParams.filterOpportunityOwner ?? [];
    const filterCreditOfficer = queryParams.filterCreditOfficer ?? [];
    const filterSettlementOfficer = queryParams.filterSettlementOfficer ?? [];
    const applicationType: ApplicationTypes[] = queryParams.applicationType ?? [];
    const applicationStage: StageNameType[] = queryParams.applicationStage ?? [];
    setTimeout(()=>{
      this.offset = offset;
      this.limit = limit;
      this.sorts = queryParams.sorts ?? undefined;
      this.formGroup.setValue({
        searchControl: search,
        applicationTypeControl: applicationType,
        applicationStageControl: applicationStage,
        filterOpportunityOwnerControl: filterOpportunityOwner,
        filterCreditOfficerControl: filterCreditOfficer,
        filterSettlementOfficerControl: filterSettlementOfficer
      });
      if (forceReload) this.refresh(forceReload);
    });
  }

  refresh(forceReload: boolean = true) {
    this.resetPagination();
    this.reload(
      forceReload, this.offset, this.limit, this.sorts,
      this.searchControl.value ?? undefined,
      this.applicationTypeControl.value ?? undefined, 
      this.applicationStageControl.value ?? undefined, 
      this.filterOpportunityOwnerControl.value ?? undefined, 
      this.filterCreditOfficerControl.value ?? undefined, 
      this.filterSettlementOfficerControl.value ?? undefined
    );
  }

  private reloadPromos() {
    this.subscriptions.push(this.adminService.getPromotionByType('application').pipe(
      tap(r => {
        this.promo = r.payload
      })
    ).subscribe());
  }


  getApplicantName(app: SimplifiedApplication) {
    const entityLegalName = (app as any)['CompanyName'];
    if (entityLegalName) {
      return entityLegalName;
    } else if (app.ApplicationType === 'Consumer') {
      const givenName = (app as any)['IndividualGivenName'];
      const surName = (app as any)['IndividualSurName'];
      if (givenName || surName) {
        return `${givenName ?? ''} ${surName ?? ''}`.trim();
      }
    }
    return '';
  }

  getApplicationStatus(app: SimplifiedApplication) {
    const stage = (app as any).StageName;
    const salesforceId = (app as any).SalesforceId;
    return this.applicationService.getApplicationStatus2(stage, salesforceId);
  }

  getApplicationStage(app: SimplifiedApplication) {
    const stage = (app as any).StageName;
    return this.applicationService.getApplicationStage2(stage);
  }

  getApplicationCreateTime(app: SimplifiedApplication) {
    return displayApplicationDateTime(moment(app.CreateTime));
  }

  reload(
    forceReload: boolean = false,
    offset: number = DEFAULT_OFFSET,
    limit: number = DEFAULT_LIMIT,
    sorts?: ApplicationSort,
    search?: string,
    applicationType?: ApplicationTypes[],
    applicationStage?: StageNameType[],
    filterOpportunityOwner?: string[],
    filterCreditOfficer?: string[],
    filterSettlementOfficer?: string[]
  ) {
    if (forceReload) this.isProgressBarVisible = true
    this.store = createAsyncStore();
    this.subscriptions.push(this._applicationService().reload({
      forceReload,
      page: {offset, limit},
      filter: search,
      applicationStage,
      applicationType,
      sorts,
      filterOpportunityOwner,
      filterCreditOfficer,
      filterSettlementOfficer
    }).pipe(
      this.loader.tableLoading.track(),
      this.store.track(),
      this.portalHotToastService.topMenuLoadingObservable(),
      this.portalHotToastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle, errorMessage: this.errorMessage, retryFn: this.retry.bind(this),
      }),
      tap(r => {
        this.totalApplicationsUnderReview = r.totalApplicationsUnderReview;
        this.totalApplicationsInSettlement = r.totalApplicationsInSettlement;
        this.totalApplicationsClosedWon = r.totalApplicationsClosedWon;
        this.opportunityOwners = this.mapOfficerToSearchableSelectItems(r.opportunityOwners);
        this.creditOfficers = this.mapOfficerToSearchableSelectItems(r.creditOfficers);
        this.settlementOfficers = this.mapOfficerToSearchableSelectItems(r.settlementOfficers);
        this.limit = r.limit;
        this.offset = r.offset;
        this.total = r.total;
        this.dataSource.update(r.applications);
      }),
    ).subscribe());
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.reload(
      false, this.offset, this.limit, this.sorts,
      this.searchControl.value ?? undefined,
      this.applicationTypeControl.value ?? undefined, 
      this.applicationStageControl.value ?? undefined, 
      this.filterOpportunityOwnerControl.value ?? undefined, 
      this.filterCreditOfficerControl.value ?? undefined, 
      this.filterSettlementOfficerControl.value ?? undefined
    );
  }

  toClass(applicationStatus: string | null) {
    return applicationStatus ? applicationStatus.replace(/\s/g, '') : '';
  }

  getLastUpdatedMessage() {
    const lastUpdatedTime = localStorage.getItem('lastUpdatedTime');
    if (lastUpdatedTime) {
      const lastUpdatedDate = new Date(lastUpdatedTime);
      const currentTime = new Date();
      
      const diffInMs = currentTime.getTime() - lastUpdatedDate.getTime(); // Difference in milliseconds
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // Convert to minutes

      if (diffInMinutes === 0) {
        this.lastUpdatedMessage = 'Last updated just now';
      } else if (diffInMinutes === 1) {
        this.lastUpdatedMessage = 'Last updated 1 minute ago';
      } else if (diffInMinutes < 60) {
        this.lastUpdatedMessage = `Last updated ${diffInMinutes} minutes ago`;
      } else {
        this.lastUpdatedMessage = `Last updated ${Math.floor(diffInMinutes / 60)} hours ago`;
      }
    } else {
      this.lastUpdatedMessage = 'List has not been updated yet';
    }
    setTimeout(() => {
      this.getLastUpdatedMessage();
    }, 60000);
  }

  onSort($event: Sort) {
    if ($event.direction) {
      const dir = $event.direction === 'asc' ? 'ASC' : 'DESC';
      switch ($event.active) {
        case 'appId':
          this.sorts = [{ prop: 'BrokerAppId', dir }];
          break;
        case 'appName':
          this.sorts = [{ prop: 'CompanyName', dir }];
          break;
        case 'status':
          this.sorts = [{ prop: 'Status', dir }];
          break;
        case 'stage':
          this.sorts = [{ prop: 'AppInfoStageName', dir }];
          break;
      }
    } else {
      this.sorts = undefined;
    }
    this.refresh(false);
  }

  clearFilters() {
    this.formGroup.setValue({
      searchControl: '',
      applicationTypeControl: [],
      applicationStageControl: [],
      filterOpportunityOwnerControl: [],
      filterCreditOfficerControl: [],
      filterSettlementOfficerControl: []
    });
  }

  async onApplicationClick($event: MouseEvent, application: SimplifiedApplication, newTab: boolean = false) {
    const status = this.getApplicationStatus(application);
    const applicationId = application.ApplicationId;
    const queryParams = {
      reload: false,
      limit: this.limit,
      offset: this.offset,
      search: this.searchControl.value ?? undefined,
      applicationType: this.applicationTypeControl.value ?? undefined,
      applicationStage: this.applicationStageControl.value ?? undefined,
      filterOpportunityOwner: this.filterOpportunityOwnerControl.value ?? undefined,
      filterCreditOfficer: this.filterCreditOfficerControl.value ?? undefined,
      filterSettlementOfficer: this.filterSettlementOfficerControl.value ?? undefined,
      sorts: this.sorts ?? undefined,
    };
    let urlTree: UrlTree;
    if (status === "Draft") {
      switch (application.ApplicationType) {
        default:
        case 'AssetFinance':
          urlTree = navigationUrlForAssetProductSelectorWithQueryParams(this.router, applicationId, queryParams);
          break;
        case 'BusinessLoans':
          urlTree = navigationUrlForBusinessFinanceWithQueryParams(this.router, applicationId, queryParams);
          break;
        case 'BusinessOverdraft':
          urlTree = navigationUrlForBusinessOverdraftWithQueryParams(this.router, applicationId, queryParams);
          break;
        case 'Commercial':
          urlTree = navigationUrlForCommercialFinanceWithQueryParams(this.router, applicationId, queryParams);
          break;
        case 'Consumer':
          if (getUser()!.priviledges.includes('lg' as any)) {
            urlTree = navigationUrlForConsumerFinanceWithQueryParams(this.router, applicationId);
          } else {
            urlTree = navigationUrlForConsumerAssetFinanceWithQueryParams(this.router, applicationId, queryParams);
          }
          break;
        case 'InsurancePremium':
          urlTree = navigationUrlForInsurancePremiumWithQueryParams(this.router, applicationId, queryParams);
          break;
        case 'CorporateLoans':
          urlTree = navigationUrlForCorporateLoanWithQueryParams(this.router, applicationId, queryParams);
          break;
      }
    } else {
      urlTree = navigationUrlForApplicationWithQueryParams(
        this.router,
        String(application.ApplicationId), 'app',
        queryParams,
      )
    }
    if (newTab) {
      window.open(this.router.serializeUrl(urlTree), '_blank');
    } else {
      this.router.navigateByUrl(urlTree);
    }
  }

  onApplicationStageFilter(applicationStages: StageNameType[]) {
    let newAppStageList: StageNameType[] = [...this.applicationStageControl.value ?? []];
    for (const applicationStage of applicationStages) {
      if (newAppStageList.includes(applicationStage)) {
        newAppStageList = newAppStageList.filter(stage => stage !== applicationStage);
      } else {
        newAppStageList.push(applicationStage);
      }
    }
    this.applicationStageControl.setValue(newAppStageList);
    this.refresh(false);
  }

  showBrokerName(): boolean {
    return isInternalUser(this.user) || isAssociatedWithAggregator(this.user);
  }

  closePromo($event: MessageBoxComponentEvent) {
    $event.close();
  }

  _applicationService(): LocalApplicationsDbService {
    return this.localApplicationDbService;
  }

  onDeleteByAppId(applicationId: number) {
    this.subscriptions.push(this.applicationService.deleteApplicationByAppId(applicationId).pipe(
      this.portalHotToastService.spinnerObservable(),
      this.portalHotToastService.snackBarObservable('Application deleted'),
      tap(r=>{
        if (r.status) { // deleted successfully
          this._applicationService().removeApplicationLocally(applicationId);
          this.refresh();
        }
      }),
    ).subscribe());
  }

  onDelete(applicationId: number) {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Please confirm",
        subMessage: "Do you want to delete this application?"
      }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          if (applicationId) {
            this.onDeleteByAppId(applicationId)
          }
        }
      })
    )
  }

  onCopyApplication(ApplicationId: number) {
    this.subscriptions.push(this.applicationService.getApplicationById(ApplicationId).pipe(
      tap(r => {
        this.application=r.payload
      },
      catchError((err) => {
        console.log(`error resolving application with id ${ApplicationId}`, err);
        return of(null);
      })),
    ).subscribe());
    this.subscriptions.push(
      this.applicationDialogService.openCopyApplicationDialog({
        application: this.application
      }).afterClosed().subscribe(async (r: CopyApplicationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          const applicationType = r.applicationType as ApplicationTypes;
          let geoLocation = {};
          try {
            geoLocation = await this.getGeoLocationFn();
          } catch (error) {
            console.log(error)
          }
          this.copyApplicationFn({
            newApplicationType: applicationType,
            application: this.application,
            user: this.loggedInUser as User,
            ip: this.ip,
            geoLocation: geoLocation
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((r: SaveApplicationResult) => {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: `Application ${r.BrokerApplicationId} has been created for you.`
            }).afterClosed().subscribe(() => {
              this.resetPagination();
              this.clearFilters();
              this.refresh(false);
            })
          })
        }
      })
    )
  }

  async onClickAllApplication($event: Event){
    await this.router.navigate(navigationUrlForAllApplication());
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  mapOfficerToSearchableSelectItems(items: OpportunityOwner[] | CreditOfficer[] | SettlementOfficer[] ) {
    const me = this.loggedInUser?.Email;
    return items.map(item => ({
      id: item.email ?? '',
      value: item.email ?? '',
      label: item.email === me ? `Me (${item.email})` : `${item.firstName} ${item.lastName} (${item.email})`,
      searchTerm: item.email === me ? `${item.firstName} ${item.lastName} (${item.email})` : undefined,
      priority: item.email === me
    }));
  }
}
