import {ApplicationRef, DestroyRef, inject, Injectable} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {environment} from "../../environments/environment";
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';

@Injectable()
export class UpdateService {

      swUpdate: SwUpdate = inject(SwUpdate);
      applicationRef: ApplicationRef = inject(ApplicationRef);
      destroyDef: DestroyRef = inject(DestroyRef);
      portalHotToastService: PortalHotToastService = inject(PortalHotToastService);

      // NOTE: angular service do not have init lifecycle, we trigger this from APP_INITIALIZER
      //       in main.ts
      init() {
        if (environment.enableServiceWorker && this.swUpdate && this.swUpdate.isEnabled) {
          console.log(`Update Service (Service Worker) is Enabled`);
          const interval$ = interval(5 * 60 * 1000);
          const applicationStable$ = this.applicationRef.isStable.pipe(first((isStable) => isStable == true));

          // start listening when app is stable AND within intervals
          concat(applicationStable$, interval$).pipe(
            takeUntilDestroyed(this.destroyDef),
          ).subscribe(async (i) => {
            if (i === 0) {
              console.log(`Update Service (Service Worker) initialized`);
            }
            await this.triggerCheck();
            this.listenForUpdates();
          });
        } else {
          console.log(`Update Service (Service Worker) is Disabled`);
        }
      }

      async triggerCheck() {
        if(environment.enableServiceWorker && this.swUpdate && this.swUpdate.isEnabled) {
            try {
                await this.swUpdate.checkForUpdate();
            } catch (error) {
                console.error(`Error checking for sw update`, error);
            }
        } else {
            console.log(`Unable to trigger service worker update service worker is not enabled`);
        }
      }

      listenForUpdates() {
            this.swUpdate.unrecoverable.pipe(
                takeUntilDestroyed(this.destroyDef),
            ).subscribe((event) => {
                console.log(`service worker unrecoverable state`, event);
                document.location.reload();
            });
            this.swUpdate.versionUpdates.pipe(
                takeUntilDestroyed(this.destroyDef),
            ).subscribe(async (event) => {
                console.log(`service worker update event`, event);
                switch(event.type) {
                    case "NO_NEW_VERSION_DETECTED": {
                        break;
                    }
                    case "VERSION_DETECTED": {
                        console.log(`ngsw update service - version detected `, event);
                        break;
                    }
                    case "VERSION_INSTALLATION_FAILED": {
                        console.log(`ngsw update service - version installation failed `, event);
                        break;
                    }
                    case "VERSION_READY": {
                        console.log(`ngsw update service - version ready `, event);
                        this.portalHotToastService.snackbar('A new version of the app is detected.');
                        const activateUpdateResult = await this.swUpdate.activateUpdate();
                        console.log(`ngsw update service - activate update result `, activateUpdateResult);
                        break;
                    }
                } 
            });
      }
}
