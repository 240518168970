import {Injectable} from '@angular/core';
import escapeStringRegexp from 'escape-string-regexp';
import {
  ApplicationSort,
  ApplicationsReport,
  ApplicationTypes,
  DEFAULT_FILTER,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DEFAULT_SORTS,
  DEFAULT_APPLICATION_TYPE_FILTER,
  LastRecentApplicationsBundle,
  PaginablePayloadApiResponse,
  PayloadApiResponse,
  SimplifiedApplication,
  AllApplicationTypes,
  isApplicationInSettlement,
  isApplicationClosedWon, isApplicationUnderReview,
  ApplicationState,
  StageNameType
} from '@portal-workspace/grow-shared-library';
import {Observable, of, Subscription,Subject} from 'rxjs';
import loki from 'lokijs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UntilDestroy} from '@ngneat/until-destroy';
import {map, switchMap, tap} from 'rxjs/operators';
import {httpOptions} from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import { NgxIndexedDBService } from 'ngx-indexed-db';
 

const URL_GET_LAST_RECENT_APPLICATIONS_BUNDLE = ()=>`${environment.api2Host}/api2/applications-last-recent-bundle`;

export type SettlementOfficer = { firstName?: string | null, lastName?: string | null, email?: string | null }
export type OpportunityOwner = { firstName?: string | null, lastName?: string | null, email?: string | null }
export type CreditOfficer = { firstName?: string | null, lastName?: string | null, email?: string | null }
type ReloadProps = {
  page: {offset: number, limit: number},
  forceReload: boolean,
  sorts?: ApplicationSort,
  filter?: string,
  applicationStage?: StageNameType[],
  applicationType?: ApplicationTypes[],
  filterOpportunityOwner?: string[],
  filterCreditOfficer?: string[],
  filterSettlementOfficer?: string[],
}

@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class LocalApplicationsDbService {

  CACHE_EXPIRY_IN_MINUTES = 60;
  lastUpdate: moment.Moment = moment();  // last time it was updated
  loaded: boolean = false;  // loaded applications for the first time ?

  subscriptions: Subscription[] = [];

  private dataUpdateSubject = new Subject<any>();
  dataUpdates$ = this.dataUpdateSubject.asObservable();

  localDb = new loki('local-applications-db');

  allDbCollection: Collection<SimplifiedApplication>;
  inSettlementDbCollection: Collection<SimplifiedApplication>;
  underReviewDbCollection: Collection<SimplifiedApplication>;
  closedWonDbCollection: Collection<SimplifiedApplication>;

  constructor(private httpClient: HttpClient,private dbService: NgxIndexedDBService) {
    this.allDbCollection = this.localDb.addCollection('all');
    this.inSettlementDbCollection = this.localDb.addCollection('inSettlement');
    this.underReviewDbCollection = this.localDb.addCollection('underReview');
    this.closedWonDbCollection = this.localDb.addCollection('closedWon');
  }
  
  processReload(props: ReloadProps) {
    let r: {total: number, offset: number, limit: number, applications: SimplifiedApplication[]};
    r = this.getApplicationsByFilters(
      props.page,
      props.sorts,
      props.filter,
      props.applicationStage,
      props.applicationType,
      props.filterOpportunityOwner,
      props.filterCreditOfficer,
      props.filterSettlementOfficer
    );

    const opportunityOwnersEmails = new Set<string>();
    const creditOfficersEmails = new Set<string>();
    const settlementOfficersEmails = new Set<string>();
    const opportunityOwners: OpportunityOwner[] = [];
    const creditOfficers: CreditOfficer[] = [];
    const settlementOfficers: SettlementOfficer[] = [];

    const addUniqueEmail = (emailSet: Set<string>, data: OpportunityOwner | CreditOfficer | SettlementOfficer, list: any[]) => {
      if (data.email) {
        const length = emailSet.size;
        emailSet.add(data.email);
        if (length !== emailSet.size) {
          list.push(data);
        }
      }
    };

    for (const app of this.allDbCollection.data) {
      addUniqueEmail(opportunityOwnersEmails, {email: app.sfOwnerEmail, firstName: app.sfOwnerFirstName, lastName: app.sfOwnerLastname}, opportunityOwners);
      addUniqueEmail(creditOfficersEmails, {email: app.creditOfficerEmail, firstName: app.creditOfficerFirstName, lastName: app.creditOfficerLastName}, creditOfficers);
      addUniqueEmail(settlementOfficersEmails, {email: app.sfSettlmentUserEmail, firstName: app.sfSettlmentUserFirstName, lastName: app.sfSettlmentUserLastname}, settlementOfficers);
    }

    return {
      totalApplications: this.allDbCollection.data.length,
      totalApplicationsUnderReview: this.underReviewDbCollection.data.length,
      totalApplicationsInSettlement: this.inSettlementDbCollection.data.length,
      totalApplicationsClosedWon: this.closedWonDbCollection.data.length,
      opportunityOwners,
      creditOfficers,
      settlementOfficers,
      ...r,
    }
  }

  reload (props: ReloadProps) : Observable<{
    totalApplications: number,
    totalApplicationsUnderReview: number,
    totalApplicationsInSettlement: number,
    totalApplicationsClosedWon: number,
    opportunityOwners: OpportunityOwner[],
    creditOfficers: CreditOfficer[],
    settlementOfficers: SettlementOfficer[],
    total: number,   // total applications search / sorts / filter
    limit: number,   // limit of applications search / sorts / filter
    offset: number,  // offset of applications search / sorts / filter
    applications: SimplifiedApplication[],
  }> {
    return this.reloadFromIndexDB().pipe(
      map((res: any) => {
        return this.processReload(props);
      }),
      tap(() => {
        // Trigger `getNewDataFromApi()` as a side effect (background)
        if(props.forceReload){
          this.reloadFromRemoteSource().subscribe({
            next: (newData) => {
              this.dataUpdateSubject.next(this.processReload(props));
            },
            error: (err) => console.error('Background API call failed', err),
          });
          
        } else {
          this.getNewDataFromApi().subscribe({
            next: () => console.log('Background API call successful'),
            error: (err) => console.error('Background API call failed', err),
          });
        }
      })
    );
  }

  private reloadFromIndexDB(forceReload: boolean = false): Observable<{
    all: Collection<SimplifiedApplication>,
    inSettlement: Collection<SimplifiedApplication>,
    underReview: Collection<SimplifiedApplication>,
    closedWon: Collection<SimplifiedApplication>,
  }> {
    const t = moment().diff(this.lastUpdate, 'minutes');
      return new Observable((observer) => {
        // Attempt to read data from IndexedDB
        this.readFromIndexedDB().subscribe((data) => {
          if (!_.isEmpty(data) && !_.isEmpty(data.all.data)) {
            observer.next(data);
            observer.complete();
          } else {
            //return this.reloadFromRemoteSource();
            this.reloadFromRemoteSource().subscribe((remoteData) => {
              observer.next(remoteData);
              observer.complete();
            });
          }
        });
      });
     //}
    
  }

  private useCache(forceReload: boolean = false): boolean {
    // previously loaded
    const loadedPreviously =  this.loaded;
    // force reload
    const noForceReload = !forceReload;
    // has cache expired?
    const cacheNotExpired = (moment().diff(this.lastUpdate, 'minutes') < this.CACHE_EXPIRY_IN_MINUTES);

    return (loadedPreviously && noForceReload && cacheNotExpired);
  }

  removeApplicationLocally(applicationId: number) {
    this.allDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
    this.inSettlementDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
    this.underReviewDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
    this.closedWonDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
  }

  addOrUpdateApplicationLocally(app: SimplifiedApplication) {
    if (app && app.ApplicationId) {
      const appFound = this.allDbCollection.find({
        ApplicationId: app.ApplicationId
      });
      if ((appFound ?? []).length > 0) {
        this.removeApplicationLocally(app.ApplicationId);
      }
      this.allDbCollection.insert(app);
      if (isApplicationInSettlement(app)) {
        this.inSettlementDbCollection.insert(app);
      }
      if (isApplicationUnderReview(app)) {
        this.underReviewDbCollection.insert(app);
      }
      if (isApplicationClosedWon(app)) {
        this.closedWonDbCollection.insert(app);
      }
    }
  }

  private getNewDataFromApi(): Observable<any> {
 
   return this.httpClient.post<PayloadApiResponse<LastRecentApplicationsBundle>>(URL_GET_LAST_RECENT_APPLICATIONS_BUNDLE(), {}, httpOptions())
      .pipe(
        tap((rs: { payload: any; }) => {
          if (rs.payload) {
            this.loaded = true;
            this.lastUpdate = moment();

            this.allDbCollection.clear({removeIndices: true});
            this.inSettlementDbCollection.clear({removeIndices: true});
            this.underReviewDbCollection.clear({removeIndices: true});
            this.closedWonDbCollection.clear({removeIndices: true});

            const r = rs.payload;
            this.saveToIndexedDB(r.all.records,"all");
            this.saveToIndexedDB(r.inSettlement.records,"in_settlement");
            this.saveToIndexedDB(r.underReview.records,"underReview");
            this.saveToIndexedDB(r.closedWon.records,"closedWon");
          }
        })
      
      );
    
  }

  private reloadFromRemoteSource(): Observable<{
    all: Collection<SimplifiedApplication>,
    inSettlement: Collection<SimplifiedApplication>,
    underReview: Collection<SimplifiedApplication>,
    closedWon: Collection<SimplifiedApplication>,
  }> {

    const obs = this.httpClient.post<PayloadApiResponse<LastRecentApplicationsBundle>>(URL_GET_LAST_RECENT_APPLICATIONS_BUNDLE(), {}, httpOptions())
      .pipe(
        tap((rs: { payload: any; }) => {
          if (rs.payload) {
            this.loaded = true;
            this.lastUpdate = moment();
           
            this.allDbCollection.clear({removeIndices: true});
            this.inSettlementDbCollection.clear({removeIndices: true});
            this.underReviewDbCollection.clear({removeIndices: true});
            this.closedWonDbCollection.clear({removeIndices: true});

            const r = rs.payload;
            this.saveToIndexedDB(r.all.records,"all");
            this.saveToIndexedDB(r.inSettlement.records,"in_settlement");
            this.saveToIndexedDB(r.underReview.records,"underReview");
            this.saveToIndexedDB(r.closedWon.records,"closedWon");

            (r.all.records ?? []).forEach((rec: any) => {
              this.allDbCollection.insert(rec);
            });
            (r.inSettlement.records ?? []).forEach((rec: any) => {
              this.inSettlementDbCollection.insert(rec);
            });
            (r.underReview.records ?? []).forEach((rec: any) => {
              this.underReviewDbCollection.insert(rec);
            });
            (r.closedWon.records ?? []).forEach((rec: any) => {
              this.closedWonDbCollection.insert(rec);
            });

          }
        }),
        map((r: any) => {
          return {
            all: this.allDbCollection,
            underReview: this.underReviewDbCollection,
            inSettlement: this.inSettlementDbCollection,
            closedWon: this.closedWonDbCollection,
          }
        })
      );
    return obs;
  }
 
  private readFromIndexedDB():Observable<{
    all: Collection<SimplifiedApplication>,
    inSettlement: Collection<SimplifiedApplication>,
    underReview: Collection<SimplifiedApplication>,
    closedWon: Collection<SimplifiedApplication>,
  }> {
    return new Observable((observer) => {
      this.dbService.getAll('applications').subscribe((data: any[]) => {
        const all = data.filter((app) => app.status === 'all' || !app.status);
        const inSettlement = data.filter((app) => app.status === 'in_settlement');
        const underReview = data.filter((app) => app.status === 'underReview');
        const closedWon = data.filter((app) => app.status === 'closedWon');
        
        this.allDbCollection.clear({removeIndices: true});
        this.inSettlementDbCollection.clear({removeIndices: true});
        this.underReviewDbCollection.clear({removeIndices: true});
        this.closedWonDbCollection.clear({removeIndices: true});
         
        (all[0]?.application_data ?? []).forEach((rec: any) => {
          const sanitizedRecord = { ...rec };
          delete sanitizedRecord.$loki; // Ensure `$loki` is removed
          this.allDbCollection.insert(sanitizedRecord);
        });

        (inSettlement[0]?.application_data ?? []).forEach((rec: any) => {
          const sanitizedRecord = { ...rec };
          delete sanitizedRecord.$loki; // Ensure `$loki` is removed
          this.inSettlementDbCollection.insert(sanitizedRecord);
        });

        (underReview[0]?.application_data ?? []).forEach((rec: any) => {
          const sanitizedRecord = { ...rec };
          delete sanitizedRecord.$loki; // Ensure `$loki` is removed
          this.underReviewDbCollection.insert(sanitizedRecord);
        });

        (closedWon[0]?.application_data ?? []).forEach((rec: any) => {
          const sanitizedRecord = { ...rec };
          delete sanitizedRecord.$loki; // Ensure `$loki` is removed
          this.closedWonDbCollection.insert(sanitizedRecord);
        });
     
        observer.next({
          all: this.allDbCollection,
          underReview: this.underReviewDbCollection,
          inSettlement: this.inSettlementDbCollection,
          closedWon: this.closedWonDbCollection,
        });
        observer.complete();
       
      });
    });
  }
  
  private saveToIndexedDB(data: SimplifiedApplication[],status:string): void {
    const currentTime = new Date().toISOString();
    localStorage.setItem('lastUpdatedTime', currentTime);
    this.dbService.clear('applications').subscribe(() => {
      this.dbService.add('applications',   {
        status: status,
        application_data: data,
      },).subscribe();
      // data.forEach((application) => {
      
      //   //this.dbService.add('applications', application).subscribe();
      // });
    });
  }

 
  private doFiltering(chain: Resultset<SimplifiedApplication & LokiObj>, filter?: string) {
    if (!!filter) {
      const __filter = (filter ?? '').trim();
      const _filter = escapeStringRegexp(__filter);
      chain = chain.find({
        '$or': [
          {'BrokerAppId': {'$regex': [_filter, 'i']}},
          {'SalesforceId': {'$regex': [_filter, 'i']}},
          {'CompanyName': {'$regex': [_filter, 'i']}},
          {'BrokerName': {'$regex': [_filter, 'i']}},
          {'IndividualGivenName': {'$regex': [_filter, 'i']}},
          {'IndividualSurName': {'$regex': [_filter, 'i']}},
        ]
      });
    }
    return chain;
  }

  private doFilterOpportunityOwner(chain: Resultset<SimplifiedApplication & LokiObj>, filterOpportunityOwner?: string[]) {
    if (!!filterOpportunityOwner) {
      chain = chain.find({
        '$or': [
          ...(filterOpportunityOwner ?? []).map((email) => ({'sfOwnerEmail': {'$regex': [email, 'i']}})),
        ]
      });
    }
    return chain;
  }

  private doFilterCreditOfficer(chain: Resultset<SimplifiedApplication & LokiObj>, filterCreditOfficer?: string[]) {
    if (!!filterCreditOfficer) {
      chain = chain.find({
        '$or': [
          ...(filterCreditOfficer ?? []).map((email) => ({'creditOfficerEmail': {'$regex': [email, 'i']}})),
        ]
      });
    }
    return chain;
  }

  private doFilterSettlementOfficer(chain: Resultset<SimplifiedApplication & LokiObj>, filterSettlementOfficer?: string[]) {
    if (!!filterSettlementOfficer) {
      chain = chain.find({
        '$or': [
          ...(filterSettlementOfficer ?? []).map((email) => ({'sfSettlmentUserEmail': {'$regex': [email, 'i']}})),
        ]
      });
    }
    return chain;
  }

  private doFilterApplicationStage(chain: Resultset<SimplifiedApplication & LokiObj>, applicationStage?: StageNameType[]) {
    if (!!applicationStage) {
      chain = chain.find({
        '$or': [
          ...(applicationStage ?? []).map((stage) => ({'StageName': { '$eq': stage }})),
        ]
      });
    }
    return chain;
  }

  private doTypeFiltering(chain: Resultset<SimplifiedApplication & LokiObj>, typeFilter?: AllApplicationTypes[]) {
    if (!!typeFilter) {
      chain = chain.find({
        '$or': [
          ...(typeFilter ?? []).map((type) => ({'ApplicationType': { '$eq': type }})),
        ]
      });
    }
    return chain;
  }

  private doSorting(chain: Resultset<SimplifiedApplication & LokiObj>, sorts?: ApplicationSort) {
    const sortCriteria: [(keyof SimplifiedApplication | keyof LokiObj), boolean][] = [];
    if (sorts && sorts.length) {
      for (const sort of sorts) {
        switch(sort.prop) {
          case 'BrokerAppId': {
            sortCriteria.push(['BrokerAppId', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'BrokerName': {
            sortCriteria.push(['BrokerName', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'CompanyName': {
            sortCriteria.push(['CompanyName', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'CreateTime': {
            sortCriteria.push(['CreateTime', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'Status': {  //
            sortCriteria.push(['Status', (sort.dir == 'DESC' ? true : false)]);
            sortCriteria.push(['CreateTime', true]);
            break;
          }
          case 'AppInfoStageName': { //
            sortCriteria.push(['StageName', (sort.dir == 'DESC' ? true : false)]);
            sortCriteria.push(['CreateTime', true]);
            break;
          }
        }
      }
    }
    // sort by default by applicationId
    if (sortCriteria.length === 0) {
      sortCriteria.push(['ApplicationId', true]);
    }
    if (sortCriteria && sortCriteria.length) {
      chain = chain.compoundsort(sortCriteria);
    }
    return chain;
  }

  private doPagination(chain: Resultset<SimplifiedApplication & LokiObj>, page: {offset: number, limit: number}) {
    chain = chain
      .offset(page.offset * page.limit)
      .limit(page.limit)
    return chain;
  }

  private getApplicationsByFilters(
    page: {offset: number, limit: number},
    sorts?: ApplicationSort,
    filter: string = '',
    applicationStage?: StageNameType[],
    applicationType?: AllApplicationTypes[],
    filterOpportunityOwner?: string[],
    filterCreditOfficer?: string[],
    filterSettlementOfficer?: string[],
   ) {
       
    let chain: Resultset<SimplifiedApplication & LokiObj> = this.allDbCollection.chain();
    if (filter) chain = this.doFiltering(chain, filter);
    if (applicationStage?.length) chain = this.doFilterApplicationStage(chain, applicationStage);
    if (applicationType?.length) chain = this.doTypeFiltering(chain, applicationType);
    if (filterOpportunityOwner?.length) chain = this.doFilterOpportunityOwner(chain, filterOpportunityOwner);
    if (filterCreditOfficer?.length) chain = this.doFilterCreditOfficer(chain, filterCreditOfficer);
    if (filterSettlementOfficer?.length) chain = this.doFilterSettlementOfficer(chain, filterSettlementOfficer);

    chain = this.doSorting(chain, sorts);
    const total = chain.data().length;
    chain = this.doPagination(chain, page);

    const result = chain.data();
    return {
      total,
      limit: page.limit,
      offset: page.offset,
      applications: result,
    };
  }
}
