import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
 getApplicationStage, Accreditation, AccreditationSelectionType, GetAccreditationWebServicesFn, DownloadCreditorWatchReportFn, DeclineAccreditationFn, ApproveAccreditationFn, GetUserFn, GetAccreditationByIdFn, GetAccreditationCreditFlowResultFn, RunAccreditationCreditFlowFn
} from '@portal-workspace/grow-shared-library';
import {
  ApproveApplicationDocumentFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  getUser,
  UpdateApplicationDocumentTagsFn,
  UploadAccreditationDocumentFn, ListAccreditationDocumentFn,
  DownloadAccreditationDocumentUrlFn,
  DownloadAllAccreditationDocumentUrlFn, setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import {
  booleanToYesNo,
  filesToBase64Files,
  yesNoToBoolean,
  isInternalUser,
} from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent } from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '@portal-workspace/grow-shared-library';
import { AccreditationDrivingLicenceComponent } from './accreditation-driving-licence.component';
import { AccreditationDocumentsComponent } from './accreditation-documents.component';
import { AccreditationAccComponent } from './accreditation-acc.component';
import { AccreditationCreditComponent } from './accreditation-credit.component';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { BreadcrumbComponent } from '../breadcrumb-component/breadcrumb.component';
import { FlexModule } from '@angular/flex-layout/flex';
import {Subscription} from "rxjs";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'accreditation-details',
    templateUrl: './accreditation-details.component.html',
    styleUrls: ['./accreditation-details.component.scss'],
    standalone: true,
    imports: [FlexModule, BreadcrumbComponent, MatTabsModule, AccreditationAccComponent, AccreditationDocumentsComponent, AccreditationDrivingLicenceComponent, AccreditationCreditComponent]
})
export class AccreditationDetailsComponent implements OnInit{

  subscriptions: Subscription[] = [];

  getApplicationStage = getApplicationStage;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();
  currentSection: AccreditationSelectionType = 'acc';
  currentSelectionIndex: number = 0;
  isSupplier = false;
  breadcrumbTrails: string[] = ['Accreditations'];
  @Input({required: true}) accreditation!: Accreditation;
  @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent)=>void = (evt)=>{};
  @Input({required: true}) approveAccreditationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineAccreditationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteAccreditationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) uploadAccreditationDocumentFn!: UploadAccreditationDocumentFn;
  @Input({required: true}) listAccreditationDocumentFn!: ListAccreditationDocumentFn;
  @Input({required: true}) downloadAccreditationDocumentUrlFn!: DownloadAccreditationDocumentUrlFn;
  @Input({required: true}) updateAccreditationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) downloadAllAccreditationDocumentUrlFn!: DownloadAllAccreditationDocumentUrlFn;
  @Input({required: true}) getAccreditationWebServicesFn!: GetAccreditationWebServicesFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) approveAccreditationFn!: ApproveAccreditationFn;
  @Input({required: true}) declineAccreditationFn!: DeclineAccreditationFn;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getAccreditationByIdFn!: GetAccreditationByIdFn;
  @Input({required: true}) getAccreditationCreditFlowResultFn!: GetAccreditationCreditFlowResultFn;
  @Input({required: true}) runAccreditationCreditFlowFn!: RunAccreditationCreditFlowFn;
  @Output() creditStatusUpdate = new EventEmitter;
  // @Output() selectionEvent: EventEmitter<AccreditationSelectionType> = new EventEmitter<AccreditationSelectionType>();

  constructor(){

  }

  ngOnInit() {
    setupUntilDestroy(this);
    const user = this.loggedInUser;
    const companyName = this.accreditation.companyName;
    if (companyName) {
      this.breadcrumbTrails.push(companyName);
    }
    this.isSupplier = this._isSupplier(this.accreditation.natureofBusiness);
  }

  private _isSupplier(natureOfBusiness: string){
    if(natureOfBusiness == 'supplier') return true
    else return false
  }


  // getAccreditationTabIndex(tab: AccreditationSelectionType, isSupplier = false) {
  //   switch (tab) {
  //     case 'acc':
  //       return 0;
  //     case 'drivingLicence':
  //       return isSupplier ? 2 : 1;
  //     case 'documents':
  //       return 2;
  //     default:
  //       return 0;
  //   }
  // }

  async onSelectedTabChange(event: MatTabChangeEvent) {
    let selection: AccreditationSelectionType = 'acc';
    const tabIndex = event.index;
    switch (tabIndex) {
      case 0:
        selection = 'acc';
        break;
      case 1:
        selection = this.isSupplier ? 'documents' : 'drivingLicence';
        break;
      case 2:
        selection = this.isSupplier ? 'credit' : 'documents';
        break;
      case 3:
        selection = 'credit';
    }
    console.log('******* ======= selectedTabChanged', this.currentSelectionIndex, event);
    this.currentSection = selection;
    // this.selectionEvent.emit(this.currentSection);
  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }

  onHandleCreditStatusUpdate() {
    this.creditStatusUpdate.emit();
  }

}
