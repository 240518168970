import {Address2ComponentValue, } from './address2-component.domain';
import { GenderValue, } from './gender-component.domain';
import { TitleSelectionType, TitleSelectionValue, } from './title-selection-component.domain';
import { YesNoValue} from './yes-no-component.domain';
import {Moment} from 'moment';
import {IndividualWithResult, ThirdPartyEntity} from '../digital-id-service.domain';
import {Observable} from 'rxjs';
import {Application, Individual, SignerRoleTypes} from '../application-service.domain';
import {BusinessNumberSearchResult} from './business-number-search-component.domain';

export interface IndividualFormDialogData {
  existingIndividuals: IndividualWithResult[];
  businessSearchFn: (businessNumber: string) => Observable<BusinessNumberSearchResult>;
}

export interface IndividualFormDialogResult {
  cancelled: boolean;
  valid: boolean;
  individual?: {
    title: Exclude<TitleSelectionValue, null>,
    firstName: string,
    middleName: string,
    lastName: string,
    residentialAddress: Exclude<Address2ComponentValue, null>,
    dob: Moment,
    email: string,
    role: 'Partner' | 'Director' | 'Guarantor' | 'Shareholder' | null,
    signerRole: SignerRoleTypes,
    mobile: string,
    guarantor: Exclude<YesNoValue, null>,
    gender: Exclude<GenderValue, null>,
    thirdPartyEntity?: ThirdPartyEntity,
    contact : string
  }
}
