import {NatureOfBusinessValue} from './component/nature-of-business-component.domain';
import {Address, StageNameType} from '../domain/application-service.domain';
import {Base64File} from '../domain/types.domain';
import {DatepickerValue} from '../domain/component/datepicker-component.domain';
import {StateSelectionType} from '../domain/component/state-selection-component.domain';
import {TitleSelectionType} from '../domain/component/title-selection-component.domain';
import { BusinessSearchValue } from './component/business-search-component.domain';
import { SystemCreditStatus } from './credit.domain';


export type BusinessTypes = 'broker' | 'vendor' | 'supplier' | 'dealer'
export type AllBusinessTypes = 'All' | BusinessTypes
export type AccreditationSelectionType = 'acc' | 'drivingLicence' | 'documents' | 'credit';

export type Accreditation = BrokerAccreditation


export interface BrokerAccreditation {
  AccreditationId: number;
  companyName: string;
  natureofBusiness: 'supplier' | 'vendor' | 'financebroker' | 'broker',
  entityType: 'Limited' | 'PTNR' | 'PTY' | 'SLTR' | 'TRST',
  abn: string;
  entityName: string;
  acn: string;
  trusteeCompanyName: string;
  trusteeCompanyACN: string;
  businessAddress: string;
  residentialAddress: string;
  SalesforceId: string;
  Products: string;
  officeNumber: string;
  heldAccreditationBefore: string;
  growbizAccreditationId: string;
  ispartOfgroupOrAggregator: string;
  groupOrAggregatorName: string;
  title: string;
  givenName: string;
  surName: string;
  dob: string;
  email: string;
  mobileNumber: string;
  driverLicenceNumber: string;
  driverLicenceIssuingState: string;
  driverLicenceExpiryDate: string;
  hasAustralianCreditLicence: string;
  australianCreditLicenceNumber: string;
  dirORemployeeOfLicenceEntity: string;
  declaredBankrupt: string;
  refusedAccreditation: string;
  convictedOfFraud: string;
  convictedOfFraudDetails: string;
  business: BusinessSearchValue;
  CreditOfficerStatus: string;
  CreditOfficerStatusUpdatedBy: number;
  CreditApprovedDetails: string;
  CreditDeclineDetails: string;
  Stage: StageNameType;
  SystemCreditStatus: SystemCreditStatus;
}

export interface NextAccreditationNumber {
  accreditationNumber: number,
  applicantNumber: string,
}

export interface AggregatorSearchResult {
  ABN: string, SalesforceId: string, EntityName: string
}

export interface SubmitAccreditationInput {
  companyName: string,
  natureofBusiness: NatureOfBusinessValue,
  entityType: 'PTY' | 'PTNR' | 'SLTR' | 'TRST' | 'TRST' | 'P/L' | null ,
  abn: string,
  acn: string,
  entityName: string,
  trusteeCompanyName: string | null,
  trusteeCompanyACN: string | null,
  businessAddress: Address | null,
  heldAccreditationBefore: boolean,
  growbizAccreditationId: string | null,
  ispartOfgroupOrAggregator: boolean,
  groupOrAggregatorName: string | null,
  officeNumber: string,
  hasAustralianCreditLicence: boolean,
  australianCreditLicenceNumber?: string,
  privacyConfirmation: boolean,
  // declareBankrupt: boolean,
  refusedAccreditation: boolean,
  convictedOfFraud: boolean,
  convictedOfFraudDetails?: string,
  dirORemployeeOfLicenceEntity: boolean,
  declaredBankrupt: boolean,
  Products: {
    assetFinance: boolean,
 
    businessLoan: boolean,
    insurancePremium: boolean,
  },
  uploadedDocuments: Base64File[],
  // followings are only available for broker or vendor
  title?: TitleSelectionType | null,
  givenName?: string,
  middleName?: string | null,
  surName?: string,
  preferredName?: string,
  mobileNumber?: string,
  residentialAddress?: Address | null,
  driverLicence?: string | null,
  driverLicenceNumber?: string,
  driverLicenceExpiryDate?: DatepickerValue,
  driverLicenceIssuingState?: StateSelectionType,
  email?: string,
  dob?: string,
  hasAnotherName?: boolean,
  business: BusinessSearchValue,
  Type?: string;
}

export interface GrowApiAccreditationSubmissionResponse {
  Error?: string,
  Oppid: string,
  Accountid: string,
  Contactid: string,
  Decision: string,
  StageName: string,
}

export interface SubmitAccreditationResult extends GrowApiAccreditationSubmissionResponse {
  AccreditationId: number,
}

export type DriverLicenceDetails = DriverLicenceDetailsSuccess | DriverLicenceDetailsError;
export interface DriverLicenceDetailsSuccess {
  State: string,
  Expiry_Date: string,
  DOB: string,
  Firstname: string,
  Surname: string,
  Middlename: string,
  License_No: string,
  Card_No: string,
  Licence_Class: string,
  Postcode: string,
  Street: string,
  Suburb: string,
}
export interface DriverLicenceDetailsError { Error: string }

export interface FrontAndBackLicence {
  frontLicence: string | null;
  backLicence: string | null;
}

