import { Component,  OnInit} from '@angular/core';
import { Accreditation, ApproveAccreditationFn, DeclineAccreditationFn, DownloadCreditorWatchReportFn, GetAccreditationByIdFn, GetAccreditationCreditFlowResultFn, GetAccreditationWebServicesFn, GetUserFn, RunAccreditationCreditFlowFn } from '@portal-workspace/grow-shared-library';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbComponentEvent } from '@portal-workspace/grow-ui-library';
import { navigationUrlForAccreditationListing } from '../../service/navigation-urls';
import {
  ApproveApplicationDocumentFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  UploadAccreditationDocumentFn,
  ListAccreditationDocumentFn,
  DownloadAccreditationDocumentUrlFn,
  UpdateApplicationDocumentTagsFn,
  DownloadAllAccreditationDocumentUrlFn,
} from '@portal-workspace/grow-ui-library';
import {AccreditationService} from '../../service/accreditation.service';
import {ApplicationService} from '../../service/application.service'
import { AccreditationDetailsComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { environment } from 'apps/portal2/src/environments/environment';
import { AuthService } from '../../service/auth.service';


@Component({
    templateUrl: './accreditation-details.page.html',
    styleUrls: ['./accreditation-details.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent, AccreditationDetailsComponent]
})

export class AccreditationDetailsPage implements OnInit{
  accreditation!: Accreditation;
  onBreadcrumbEventFn = this.onBreadcrumbEvent.bind(this);
  approveAccreditationDocumentFn!: ApproveApplicationDocumentFn;
  declineAccreditationDocumentFn!: DeclineApplicationDocumentFn;
  deleteAccreditationDocumentFn!: DeleteApplicationDocumentFn;
  uploadAccreditationDocumentFn!: UploadAccreditationDocumentFn;
  listAccreditationDocumentFn!: ListAccreditationDocumentFn;
  downloadAccreditationDocumentUrlFn!: DownloadAccreditationDocumentUrlFn;
  updateAccreditationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  downloadAllAccreditationDocumentUrlFn!: DownloadAllAccreditationDocumentUrlFn;
  getAccreditationWebServicesFn!: GetAccreditationWebServicesFn;
  downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  approveAccreditationFn!: ApproveAccreditationFn;
  declineAccreditationFn!: DeclineAccreditationFn;
  getUserFn!: GetUserFn;
  getAccreditationByIdFn!: GetAccreditationByIdFn;
  getAccreditationCreditFlowResultFn!: GetAccreditationCreditFlowResultFn;
  runAccreditationCreditFlowFn!: RunAccreditationCreditFlowFn;
  apiUrl = `${environment.api2Host}/api2`;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accreditationService: AccreditationService,
    private applicationService: ApplicationService,
    private authService: AuthService,
  ){
    this.listAccreditationDocumentFn = this.accreditationService.getAccreditationDocumentsFn;
    this.uploadAccreditationDocumentFn = this.accreditationService.uploadAccreditationDocumentToAzureStorageFn;
    this.approveAccreditationDocumentFn = this.accreditationService.approveAccreditationDocumentFn;
    this.declineAccreditationDocumentFn = this.accreditationService.declineAccreditationDocumentFn;
    this.deleteAccreditationDocumentFn = this.accreditationService.deleteAccreditationDocumentFn;
    this.downloadAccreditationDocumentUrlFn = this.accreditationService.downloadAccreditationDocumentUrlFn;
    this.updateAccreditationDocumentTagsFn = this.accreditationService.updateAccreditationDocumentTagsFn;
    this.downloadAllAccreditationDocumentUrlFn = this.accreditationService.downloadAllAccreditationDocumentUrlFn;
    this.getAccreditationWebServicesFn = this.accreditationService.getAccreditationWebServicesFn;
    this.downloadCreditorWatchReportFn = this.applicationService.downloadCreditorWatchReportFn;
    this.approveAccreditationFn = this.accreditationService.approveAccreditationFn;
    this.declineAccreditationFn = this.accreditationService.declineAccreditationFn;
    this.getUserFn = this.authService.getUserFn;
    this.getAccreditationByIdFn = this.accreditationService.getAccreditationByIdFn;
    this.getAccreditationCreditFlowResultFn = this.accreditationService.getAccreditationCreditFlowResultFn;
    this.runAccreditationCreditFlowFn = this.accreditationService.runAccreditationCreditFlowFn;
  }

  ngOnInit(){
    this.accreditation = (this.route.snapshot.data as any).accreditation;
    console.log(this.accreditation,'acc');

  }

  async onBreadcrumbEvent(evt: BreadcrumbComponentEvent) {
    await this.router.navigate(navigationUrlForAccreditationListing());
  }

  async onHandleCreditStatusUpdate() {
    await this.router.navigate(navigationUrlForAccreditationListing());
  }
}
