import { Observable } from "rxjs";
import { AccessLevel } from "./types.domain";


export interface HeartbeatLog {
    date: string,
    message: string,
}

export type HeartbeatEvent = HeartbeatAccessLevelChangedEvent | HeartbeatUserDisabledEvent;

export interface HeartbeatAccessLevelChangedEvent {
    type: 'HeartbeatAccessLevelChangedEvent',
    userId: number,
    oldAccessLevel: AccessLevel,
    newAccessLevel: AccessLevel,
}

export interface HeartbeatUserDisabledEvent {
    type: 'HeartbeatUserDisabledEvent',
    userId: number,
    isDisabled: boolean,
}

export type GetHeartbeatLogsFn = () => Observable<HeartbeatLog[]>;

