<div class="sidemenu-layout" fxLayout="column">
  <div class="header">
    <div class="header-1">
      <div class="header-2">
        <topmenu
          [gitTagOrVersion]="gitTagOrVersion"
          [whitelabelServiceEventObservable]="whitelevelServiceEventObserver"
          (events)="onTopMenuEvent($event)">
        </topmenu>
      </div>
    </div>
  </div>
  <div class="body"
    fxLayout="row"
    fxLayoutAlign="center top">
    <div class="body-1">
      <div class="body-2">
        <mat-sidenav-container class="sidenav-container">
          <mat-sidenav [fixedInViewport]="true"
            [fixedTopGap]="sideNavGap"
            #sidenav="matSidenav"
            [mode]="mode === 'Mobile' ? 'over' : 'side'"
            opened>
            <div fxFlex="columns">
              <div class="sidenav hide-scrollbar">
                <sidemenu [user]="user"
                  [unreadNotificationsCountObservable]="unreadNotificationsCountObservable"
                  (menuTypeEvent)="onSidemenuEvents2($event)"
                  [portalThemeObservable]="portalThemeObservable"
                  [changePortalThemeFn]="changePortalThemeFn"
                  [heartbeatObservable]="heartbeatObservable"
                  (events)="onSidemenuEvents($event)">
                </sidemenu>
              </div>
            </div>
          </mat-sidenav>
          <mat-sidenav-content class="hide-scrollbar">
            <div>
              @if (mode === 'Mobile') {
                <div [matTooltip]="'Toggle sidemenu'">
                  <mat-icon class="expand-sidemenu-icon" (click)="matSidenav.toggle()">reorder</mat-icon>
                </div>
              }
              <error-notification2></error-notification2>
              <router-outlet></router-outlet>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
    </div>
  </div>
</div>

<!--
<div class="sidemenu-layout"
  gdAreas="header | body "
  gdColumns="auto"
  gdRows="auto auto">
  <div gdArea="header" class="header">
    <div class="header-1">
      <div class="header-2">
        <topmenu [gitTagOrVersion]="gitTagOrVersion"
          (events)="onTopMenuEvent($event)">
        </topmenu>
      </div>
    </div>
  </div>
  <div gdArea="body" class="body">
    <div class="body-1">
      <div class="body-2">
        <mat-sidenav-container class="sidenav-container">
          <mat-sidenav [fixedInViewport]="true"
            [fixedTopGap]="sideNavGap"
            mode="side" opened>
            <div fxFlex="columns">
              <div class="sidenav hide-scrollbar">
                <sidemenu [user]="user"
                  [unreadNotificationsCountObservable]="unreadNotificationsCountObservable"
                  (menuTypeEvent)="onSidemenuEvents2($event)"
                  (events)="onSidemenuEvents($event)">
                </sidemenu>
              </div>
            </div>
          </mat-sidenav>
          <mat-sidenav-content class="hide-scrollbar">
            <div>
              <error-notification2></error-notification2>
              <router-outlet></router-outlet>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
    </div>
  </div>
</div>
-->
